import './index.css';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StatScreen from '../StatScreen';
import BottomStatBar from '../BottomStatBar/index';
import InvisiBar from '../Invisibar';
import {shuffle, placementArray, fetchImage, fetchAudio} from '../nonUIFuncs';
import FloatingNumber from '../Components/floatingNumber';
import PopupWord from '../Components/popupWord';
import EndEndlessGame from '../Components/endEndlessMode';
import LevelBar from '../Components/endlessLevelBar';
import CloseEndless from "../Components/endlessCloseButton";
import { selectReduxSlice, setStats } from '../../Store/store';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

let difficultyVar = "easy";
let wordArray = [];
let displayArray = [];
let displayArrayDenom = [3,3];
let shortTimer = 0; // TODO:
let imageDOMArray = [];
let wordDOMArray = [];
let mouseClickX = 0;
let mouseClickY = 0;
let correctSoundEffect = null;
let difficultyData = [];
let roundTime = null;
let switchDisplayVar = "game";
let ready = false;
let timeout = false;
let testNum = 0;
let imageIndex = [];
let wordIndex = [];
let showPopup = false;
let audioFile = null;
let fullTime = 0;
let audioDOM;
let background = null;
let selectedColor = "rgba(150,200,255,1)";
let normalColor = "rgba(202,176,133,1)";

const SwitchDisplay = props => {
  const [showEndlessEnd, setShowEndlessEnd] = useState();
  const reduxState = useSelector(selectReduxSlice);
  if (switchDisplayVar === "stats") {
    if (props.endless === true) {
      ready = false;
      switchDisplayVar = "game";
      props.changeDisplay();
      return null;
    }
    if (shortTimer > 4) {
      props.stepDifficulty();
    }
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
      audioFile = null;
    }
    return <StatScreen scoreType={props.scoreType} time={roundTime} />;
  } else if (switchDisplayVar === "game") {
    const timeoutDiv = () => {
      if (timeout) {
        return <div className="timeoutDiv" />;
      } else {
        return null;
      } 
    };
    if (testNum !== reduxState.width * reduxState.height) {
      testNum = reduxState.width * reduxState.height;
      props.gameDifficultyDisplay();
    }
    if (imageDOMArray.length === 0 && ready === true) {
      shortTimer = 0;
      roundTime = props.displayedClock;
      fullTime = fullTime + props.displayedClock;
      switchDisplayVar = "stats";
    }
    let plusMinusDivStyle = {
      position: "absolute",
      top: mouseClickY - reduxState.height / 10,
      left: mouseClickX,
      zIndex: 99999,
      height: 2,
      width: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return (
      <div className="centerContentContainer">
        {audioDOM}
        <div style={plusMinusDivStyle}>
          <FloatingNumber correctness={props.correctness} />
        </div>
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        {imageDOMArray}
        {wordDOMArray}
        {timeoutDiv()}
        <CloseEndless enabled={props.endless} onClick={setShowEndlessEnd} />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={props.currentLevel}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={props.updateLevelData}
        />
        <InvisiBar
          enabled={props.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
      </div>
    );
  }
};

const WordToImageGame = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const firstSelectedRef = useRef();
  const firstIDRef = useRef();
  const displayedClockRef = useRef();
  const currentLevelRef = useRef();
  const currentLevelProgressRef = useRef();
  const reduxStateRef = useRef();
  const [ correctness, setCorrectness ] = useState(null);
  const [ firstID, setFirstID ] = useState(null);
  const [ firstSelected, setFirstSelected ] = useState(null);
  const [ chosenPopupWord, setChosenPopupWord ] = useState();
  const [ currentLevel, setCurrentLevel ] = useState(0);
  const [ displayedClock, setDisplayedClock] = useState(0);
  const [ currentLevelProgress, setCurrentLevelProgress ] = useState(0);
  firstSelectedRef.current = firstSelected;
  firstIDRef.current = firstID;
  displayedClockRef.current = displayedClock;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    let timer = setInterval(updateDisplayClock, 1000);
    setCurrentLevel(0);
    difficultyVar = reduxState.difficulty;
    fullTime = 0;
    switchDisplayVar = "game";
    createAudio();
    changeDisplay();
    correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
    ready = true;
    return () => {
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
      fullTime = 0;
      switchDisplayVar = "game";
      difficultyVar = "easy";
      clearInterval(timer);
      setDisplayedClock(0);
      imageDOMArray = [];
      wordDOMArray = [];
      resetStats();
      setCorrectness(null);
    };
  }, []);
  const updateDisplayClock = () => {
    setDisplayedClock(displayedClockRef.current + 1);
    shortTimer = shortTimer + 1;
  };
  const _selectCard = (e, key, audio, id) => {
    e.target.style.backgroundColor = selectedColor;
    if (
      key === firstSelectedRef.current &&
      e.target.id === firstIDRef.current
    ) {
      setFirstSelected(null);
      setFirstID(null);
      e.target.style.backgroundColor = normalColor;
      return;
    } else if (firstSelectedRef.current === null) {
      setFirstSelected(key);
      setFirstID(e.target.id);
    } else if (
      firstSelectedRef.current !== null &&
      firstIDRef.current !== e.target.id
    ) {
      let secondTarget = key;
      setTimeout(
        checkCorrectness,
        1000,
        e,
        e.target.id,
        secondTarget,
        audio
      );
    }
    mouseClickX = e.pageX;
    mouseClickY = e.pageY;
    if (isNaN(e.target.id)) {
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
      audioFile = document.getElementById(audio);
      audioFile.play();
    }
    if (
      firstIDRef.current !== null &&
      firstIDRef.current !== undefined &&
      firstIDRef.current !== id
    ) {
      if (firstIDRef.current === e.target.id) {
        return;
      } else {
        timeout = true;
      }
    }
  };
  const resetStats = () => {
    shortTimer = 0;
    timeout = false;
    switchDisplayVar = "game";
  };
  const changeDisplay = () => {
    imageIndex = [];
    wordIndex = [];
    timeout = false;
    let shuffled = shuffle(reduxState.sortedData);
    if (difficultyVar === "easy") {
      difficultyData = shuffled.slice(0, 3);
      wordArray = placementArray(1.3, 1, 2, 4, 1, 1);
      displayArray = placementArray(3.7, 1, 4, 4, 1, 1);
      if (reduxStateRef.current.height < reduxStateRef.current.width) {
        if (reduxStateRef.current.height < 550) {
          wordArray = placementArray(1.5, 1.5, 2, 4, 1, 1);
          displayArray = [
            { x: 3.5, y: 1.25 },
            { x: 4.5, y: 2.25 },
            { x: 3.5, y: 3.25 },
          ];
        }
      }
      displayArrayDenom = [5, 5];
    } else if (difficultyVar === "medium") {
      difficultyData = shuffled.slice(0, 6);
      wordArray = placementArray(1, 1, 2, 7, 1, 1);
      displayArray = placementArray(3, 1.5, 5, 6, 1, 2);
      if (reduxStateRef.current.height < 550) {
        let difference = (5.8 - 1.6) / 5;
        wordArray = placementArray(1, 1.6, 2, 6, 1, difference);
        displayArray = placementArray(3, 2, 5, 5, 0.75, 2.5);
      }
      displayArrayDenom = [5, 8];
    } else if (difficultyVar === "hard") {
      difficultyData = shuffled.slice(0, 8);
      displayArray = placementArray(3, 1.5, 5, 8, 1, 2);
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        wordArray = placementArray(1, 1.2, 2, 9, 1, 1);
      } else {
        wordArray = placementArray(1.1, 1.6, 2, 9, 1, 1);
        displayArray = placementArray(3, 1.6, 5, 8, 1, 2);
        if (reduxStateRef.current.height < 550) {
          wordArray = placementArray(1, 2.6, 3, 9, 1.1, 1.8);
          displayArray = placementArray(2.75, 3, 5, 7, 0.58, 3);
        }
      }
      displayArrayDenom = [5, 11];
    }
    wordArray = shuffle(wordArray);
    gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    setCurrentLevelProgress(0);

    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(
      () => number.classList.remove("levelNumber"),
      1200
    );
  };
  const checkCorrectness = (e, secondKey, secondTarget, audio) => {
    if (firstSelectedRef.current === secondTarget && firstIDRef.current !== secondKey) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
      audioFile = document.getElementById(audio);
      audioFile.play();
      correctSoundEffect.play();
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (
        currentLevelRef.current + 2 === progress &&
        routeLocation.state.endless === true
      ) {
        setTimeout(nextEndlessLevel, 700);
      }
      if (isNaN(firstIDRef.current)) {
        wordDOMArray.find((DOMElement) => {
          if (DOMElement.props.id === firstIDRef.current) {
            wordIndex.push(DOMElement.key);
          }
        });
        imageDOMArray.find((DOMElement) => {
          if (DOMElement.props.id === Number(secondKey)) {
            imageIndex.push(DOMElement.key);
          }
        });
        wordDOMArray = wordDOMArray.filter(
          (DOMElement) => DOMElement.props.id !== firstIDRef.current
        );
        imageDOMArray = imageDOMArray.filter(
          (DOMElement) => DOMElement.props.id !== Number(secondKey)
        );
      } else {
        wordDOMArray.find((DOMElement) => {
          if (DOMElement.props.id === secondKey) {
            wordIndex.push(DOMElement.key);
          }
        });
        imageDOMArray.find((DOMElement) => {
          if (DOMElement.props.id === Number(firstIDRef.current)) {
            imageIndex.push(DOMElement.key);
          }
        });
        wordDOMArray = wordDOMArray.filter(
          (DOMElement) => DOMElement.props.id !== secondKey
        );
        imageDOMArray = imageDOMArray.filter(
          (DOMElement) => DOMElement.props.id !== Number(firstIDRef.current)
        );
      }
      difficultyData = difficultyData.filter(
        (datum) => datum.english !== firstSelectedRef.current
      );
    } else if (firstSelectedRef.current !== secondTarget) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('wrong');
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      let firstObject = document.getElementById(firstIDRef.current);
      firstObject.style.backgroundColor = normalColor;
      let secondObject = document.getElementById(secondKey);
      secondObject.style.backgroundColor = normalColor;
    }
    setFirstID(null);
    setFirstSelected(null);
    timeout = false;
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    ready = false;
    switchDisplayVar = "game";
    setDisplayedClock(0);
    if (difficultyVar === "easy") {
      difficultyVar = "medium";
      changeDisplay();
    } else if (difficultyVar === "medium") {
      difficultyVar = "hard";
      changeDisplay();
    } else if (difficultyVar === "hard") {
      props.checkWorld(fullTime);
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    imageDOMArray = [];
    wordDOMArray = [];
    difficultyData.map((datum) => {
      let imgDimm = reduxStateRef.current.width / 6;
      let buffer = 10;
      if (reduxStateRef.current.width > reduxStateRef.current.height) {
        imgDimm = reduxStateRef.current.height / 6;
        if (difficultyVar === "hard") {
          imgDimm = reduxStateRef.current.height / 7;
        }
      }
      if (imgDimm < 70) {
        imgDimm = 70;
      } else if (imgDimm > 375) {
        imgDimm = 375;
      }
      let xyCoord = displayArray[key];
      let xyCoordWord = wordArray[key];
      let wordKey = key + "word";
      wordIndex.map((word) => {
        while (word === wordKey) {
          key = key + 1;
          xyCoord = displayArray[key];
          xyCoordWord = wordArray[key];
          wordKey = key + "word";
        }
      });
      key = key + 1;
      if (xyCoord !== undefined) {
        let wordStyle = {
          borderRadius: 10,
          backgroundColor: normalColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: (reduxStateRef.current.height * xyCoordWord.y) / displayArrayDenom[1] - 40,
          left:
            (reduxStateRef.current.width * (xyCoordWord.x - 0.75)) / displayArrayDenom[0],
          width: reduxStateRef.current.width * 0.45,
          height: reduxStateRef.current.height / (displayArray.length + 5),
          maxHeight: 95,
          borderColor: "black",
          borderWidth: 3,
          borderStyle: "solid",
        };
        wordStyle.fontSize = wordStyle.height / 2.5;
        if (reduxStateRef.current.width < reduxStateRef.current.height) {
          wordStyle.fontSize = reduxStateRef.current.width / 2 / datum.salish.length;
        }
        if (wordStyle.fontSize > 28) {
          wordStyle.fontSize = 28;
        }
        if (wordStyle.fontSize < 12) {
          wordStyle.fontSize = 12;
        }
        let divStyle = {
          borderRadius: 10,
          backgroundColor: normalColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
          width: imgDimm + buffer,
          height: imgDimm + buffer,
          borderColor: "black",
          borderWidth: 3,
          borderStyle: "solid",
          backgroundImage: `url(${fetchImage(datum.image1)})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "95% 95%",
        };
        if (difficultyVar === "hard") {
          if (reduxStateRef.current.height < 550) {
            wordStyle.width = reduxStateRef.current.width * 0.21;
          }
        }
        wordDOMArray.push(
          <div
            key={wordKey}
            id={wordKey}
            style={wordStyle}
            onClick={(e) => {
              _selectCard(e, datum.english, datum.audio1, wordKey);
            }}
            className="keyword noselect">
            {datum.salish}
          </div>
        );
        imageDOMArray.push(
          <div
            onClick={(e) => {
              _selectCard(e, datum.english, datum.audio1, key);
            }}
            key={key}
            id={key}
            style={divStyle}
          />
        );
        ready = true;
      }
    });
  };
  const createAudio = () => {
    audioDOM = reduxState.sortedData.map((datum) => {
      if (datum.audio1.length === 0) {
        return;
      }
      return (
        <audio
          id={datum.audio1}
          key={`${datum.audio1}${datum.subCat}`}
          src={`${fetchAudio(datum.audio1)}`}
          type="audio/mpeg"
        />
      );
    });
  };
  if (
    background === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    background = `url(${fetchImage(reduxState.specificGame.backgroundImg)})`;
  }
  return (
    <div style={{ backgroundImage: background }} className="fullScreen">
      <SwitchDisplay
        changeDisplay={changeDisplay}
        chosenPopupWord={chosenPopupWord}
        correctness={correctness}
        currentLevel={currentLevelRef.current}
        displayedClock={displayedClockRef.current}
        endless={routeLocation.state.endless}
        gameDifficultyDisplay={gameDifficultyDisplay}
        scoreType={props.scoreType}
        stepDifficulty={stepDifficulty}
        updateLevelData={props.updateLevelData}
      />
      {switchDisplayVar === "game" || routeLocation.state.endless === true ? (
        <BottomStatBar>
          {routeLocation.state.endless === true ? (
            <LevelBar
              difficulty={difficultyVar}
              currentLevel={currentLevelRef.current}
              currentLevelProgress={currentLevelProgressRef.current}
            />
          ) : (
            <div className="WTIG-bottomBar">
              <div>
                {difficultyVar.charAt(0).toUpperCase() + difficultyVar.slice(1)}
              </div>
              <div>
                {reduxState.uiWords[13].salish}:{" "}
                {reduxStateRef.current.stats.roundStats.score}
              </div>
              <div>
                {reduxState.uiWords[15].salish}: {displayedClockRef.current}
              </div>
            </div>
          )}
        </BottomStatBar>
      ) : null}
    </div>
  );
}

export default WordToImageGame;

WordToImageGame.propTypes = {
  checkWorld: PropTypes.func,
  scoreType: PropTypes.string,
  updateLevelData: PropTypes.func,
};

SwitchDisplay.propTypes = {
  changeDisplay: PropTypes.func,
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  currentLevel: PropTypes.number,
  displayedClock: PropTypes.number,
  endless: PropTypes.bool,
  gameDifficultyDisplay: PropTypes.func,
  scoreType: PropTypes.string,
  stepDifficulty: PropTypes.func,
  updateLevelData: PropTypes.func,
};

{/* Checked PropTypes */}