import "./index.css";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";

const TopTabBar = () => {
  const reduxState = useSelector(selectReduxSlice);
  const [expandSideBar, setExpandSideBar] = useState(false);
  const hideBar = () => {
    setExpandSideBar(false);
  }
  const clearStorage = () => {
    localStorage.clear();
    location.reload();
  };
  const LinkArray = [
    {
      styleColor: "homeColor",
      buttonTitle: "Home",
      to: { pathname: "/KidsZone/Home/", state: { display: "home" } },
    },
    {
      styleColor: "storyColor",
      buttonTitle: "Stories",
      to: { pathname: "/KidsZone/Stories/", state: { display: "stories" } },
    },
    {
      styleColor: "endlessColor",
      buttonTitle: "Endless",
      to: { pathname: "/KidsZone/EndlessMode/" },
    },
    {
      styleColor: "historyColor",
      buttonTitle: "History Game",
      to: { pathname: "/KidsZone/HistoryGame/" },
    },
    {
      styleColor: "dictionaryColor",
      buttonTitle: "Dictionary",
      to: { pathname: "/KidsZone/Dictionary/" },
    },
    {
      styleColor: "creditsColor",
      buttonTitle: "Credits",
      to: { pathname: "/KidsZone/Credits/" },
    },
  ];
  if (reduxState.width < 550) {
    if (!expandSideBar) {
      return (
        <div
          className="TB-cornerButton"
          onClick={() => setExpandSideBar(true)}>
          <Icon name='bars' size='big' className="TB-white" />
        </div>
      );
    } else {
      return (
        <div className="TB-sideBar">
          {LinkArray.map((link) => {
            // if (link.to !== undefined) {
              return (
                <Link
                  key={link.buttonTitle}
                  className={`TB-menuButton ${link.styleColor}`}
                  onClick={hideBar}
                  to={link.to}
                >
                  <div>{link.buttonTitle}</div>
                </Link>
              );
            // } else {
            //   return (
            //     <a
            //       key={link.buttonTitle}
            //       href={link.href}
            //       className={`TB-menuButton ${link.styleColor}`}
            //       onClick={hideBar}
            //     >
            //       <div>{link.buttonTitle}</div>
            //     </a>
            //   );
            // }
          })}
          {/* <Link
            className="creditsColor TB-menuButton"
            onClick={hideBar}
            to="/Credits/">
            <div>Credits</div>
          </Link> */}
          <div className="clearDataColor TB-menuButton" onClick={clearStorage}>
            Clear All Save Data
          </div>
          <div className="closeColor TB-menuButton" onClick={hideBar}>
            Close
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="topBar-fullBar">
        {LinkArray.map((link) => {
          if (link.to !== undefined) {
            return (
              <Link
                key={link.buttonTitle}
                className={`topBar-tab ${link.styleColor}`}
                to={link.to}>
                <div className="TB-topTabFont">{link.buttonTitle}</div>
              </Link>
            );
          } else {
            return (
              <a
                key={link.buttonTitle}
                href={link.href}
                className={`topBar-tab ${link.styleColor}`}>
                <div className="TB-topTabFont">{link.buttonTitle}</div>
              </a>
            );
          }
        })}
        <div className="topBar-tab clearDataColor">
          <div className="TB-clear TB-white" onClick={clearStorage}>
            Clear All Save Data
          </div>
        </div>
      </div>
    );
  }
}

export default TopTabBar;
