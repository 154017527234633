const AUDIO_PRE = 'https://storage.googleapis.com/dict2test.appspot.com/Test/public/Audio/';
const IMAGE_PRE = 'https://storage.googleapis.com/dict2test.appspot.com/Test/public/Images/';

const fetchImage = (fileName) => {
  return `${IMAGE_PRE}${fileName}`;
};
const fetchAudio = (fileName) => {
  return `${AUDIO_PRE}${fileName}`;
};
const getImageSize = (image) => {
  return {
    height: image.naturalHeight,
    width: image.naturalWidth,
    ratio: image.naturalHeight/image.naturalWidth,
    revRatio: image.naturalWidth/image.naturalHeight
  }
}
const shuffle = (array) => {
  let newArray = [...array];
  let j, x, i;
  for (i = newArray.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = x;
  }
  return newArray;
};
const placementArray = (xStart, yStart, xEnd, yEnd, xInc, yInc) => {
  let array = [];
  let x;
  let y;
  for (x = xStart; x < xEnd; x = x + xInc) {
    for (y = yStart; y < yEnd; y = y + yInc) {
      array.push({ x: x, y: y });
    }
  }
  return array;
};
const getLocation = (string) => {
  let locationArr = string.split("/").filter((str) => str.length > 0);
  locationArr = locationArr.map((str) => {
    let newStr = str.replace(/%20/g, " ");
    return newStr;
  });
  return locationArr;
};

const filterData = (
  routeLocation,
  data,
  gameData,
  storyVocab,
  gameType,
) => {
  let gameTypeCondensed = gameType.replace(/\s/g, '');
  let difficulty = null;
  let specificGame = {};
  let newFilteredData = [];
  let locationArr = getLocation(routeLocation.pathname);
  if (routeLocation.state.endless) {
    difficulty = routeLocation.state.difficulty.toLowerCase();
    document.title = `${gameType} - Endless - Kids Zone`;
    specificGame = [];
    data.map((datum) => {
      if (routeLocation.state.categories.includes(datum.category)) {
        if (datum.game === gameTypeCondensed) {
          specificGame.push(datum);
        }
      }
    });
    specificGame = shuffle(specificGame);
    specificGame = specificGame[0];
    let englishArray = [];
    newFilteredData = [];
    gameData.map((datum) => {
      if (routeLocation.state.categories.includes(datum.homeName)) {
        if (!englishArray.includes(datum.english)) {
          englishArray.push(datum.english);
          newFilteredData.push({
            id: datum.id,
            homeName: datum.homeName,
            subCat: datum.subCat,
            english: datum.english,
            salish: datum.salish,
            audio1: datum.audio1,
            image1: datum.image1,
          });
        }
      }
    });
    if (storyVocab !== undefined) {
      storyVocab.map((datum) => {
        if (routeLocation.state.categories.includes(datum.storyGame)) {
          if (!englishArray.includes(datum.english)) {
            englishArray.push(datum.english);
            newFilteredData.push({
              id: `${datum.id}story`,
              homeName: datum.storyGame,
              subCat: datum.group,
              english: datum.english,
              salish: datum.salish,
              audio1: datum.audio1,
              image1: datum.image1,
            });
          }
        }
      });
    }
  } else {
    difficulty = "easy";
    specificGame = data.find((game) => game.id == Number(locationArr[4]));

    let filteredData = [];
    if (isNaN(specificGame.target)) {
      filteredData = gameData.filter(
        (filteredDatum) => filteredDatum.subCat === specificGame.target
      );
    } else {
      filteredData = storyVocab.filter(
        (filteredDatum) => filteredDatum.group === specificGame.target
      );
    }

    newFilteredData = filteredData;
    document.title = `${gameType} - ${specificGame.category} - Kids Zone`;
  }
  return {
    difficulty: difficulty,
    specificGame: specificGame,
    newFilteredData: newFilteredData,
  };
};

export {
  getImageSize,
  shuffle,
  placementArray,
  fetchImage,
  fetchAudio,
  getLocation,
  filterData,
};