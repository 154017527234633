import { createSlice } from "@reduxjs/toolkit";
import { getLocation, shuffle } from "../Components/nonUIFuncs";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCG0rVz5Ltxq1U2TqLwzD-E_h8AOWTQYQY",
  authDomain: "dict2test.firebaseapp.com",
  databaseURL: "https://dict2test.firebaseio.com",
  projectId: "dict2test",
  storageBucket: "dict2test.appspot.com",
  messagingSenderId: "933070404930",
  appId: "1:933070404930:web:4c44e16149c7822cfa9cdb",
  measurementId: "G-G7RHF9ZZBL",
};

window.app = initializeApp(firebaseConfig);
const db = getFirestore(window.app);

export const reduxSlice = createSlice({
  name: "reduxState",
  initialState: {
    value: {
      width: window.innerWidth,
      height: window.innerHeight,
      stats: {
        hits: 0,
        misses: 0,
        score: 0,
        roundStats: {
          hits: 0,
          misses: 0,
          score: 0,
        },
      },
      firebaseSheetsPulled: [],
    },
  },
  reducers: {
    getDifficulty: (state, action) => {
      state.value = { ...state.value, difficulty: action.payload };
    },
    getSpecificGame: (state, action) => {
      state.value = { ...state.value, specificGame: action.payload };
    },
    getSortedData: (state, action) => {
      state.value = { ...state.value, sortedData: action.payload };
    },
    getEndlessCategories: (state, action) => {
      state.value = { ...state.value, endlessCategories: action.payload };
    },
    setScreenDisplay: (state, action) => {
      state.value = { ...state.value, screenDisplay: action.payload };
    },
    getStats: (state, action) => {
      state.value = {
        ...state.value,
        stats: {
          ...state.value.stats,
          ...action.payload,
          roundStats: {
            ...state.value.stats.roundStats,
            ...action.payload.roundStats,
          }
        },
      };
    },
    getWidth: (state, action) => {
      state.value = { ...state.value, width: action.payload };
    },
    getHeight: (state, action) => {
      state.value = { ...state.value, height: action.payload };
    },
    setFirestoreData: (state, action) => {
      let firebaseSheets = [...state.value.firebaseSheetsPulled];
      if (!firebaseSheets.includes(action.payload.nameToStore)) {
        firebaseSheets.push(action.payload.nameToStore);
      }
      state.value = {
        ...state.value,
        [action.payload.nameToStore]: action.payload.sortedData,
        firebaseSheetsPulled: firebaseSheets,
      };
    },
  },
});

export const {
  getDifficulty,
  getSpecificGame,
  getSortedData,
  getEndlessCategories,
  setScreenDisplay,
  getStats,
  getWidth,
  getHeight,
  setFirestoreData,
} = reduxSlice.actions;

export const setDifficulty = (difficulty) => async (dispatch) => {
  dispatch(getDifficulty(difficulty));
};

export const setInitialGameData = (
  loc,
  data,
  gameData,
  storyVocab,
  gameType
) => async (dispatch) => {
  let gameTypeCondensed = gameType.replace(/\s/g, "");
  let difficulty = null;
  let specificGame = {};
  let newFilteredData = [];
  let locationArr = getLocation(loc.pathname);
  if (loc.state.endless) {
    difficulty = loc.state.difficulty.toLowerCase();
    document.title = `${gameType} - Endless - Kids Zone`;
    specificGame = [];
    data.map((datum) => {
      if (loc.state.categories.includes(datum.category)) {
        if (datum.game === gameTypeCondensed) {
          specificGame.push(datum);
        }
      }
    });
    specificGame = shuffle(specificGame);
    specificGame = specificGame[0];
    let englishArray = [];
    newFilteredData = [];
    gameData.map((datum) => {
      if (loc.state.categories.includes(datum.homeName)) {
        if (!englishArray.includes(datum.english)) {
          englishArray.push(datum.english);
          newFilteredData.push({
            id: datum.id,
            homeName: datum.homeName,
            subCat: datum.subCat,
            english: datum.english,
            salish: datum.salish,
            audio1: datum.audio1,
            image1: datum.image1,
          });
        }
      }
    });
    if (storyVocab !== undefined) {
      storyVocab.map((datum) => {
        if (loc.state.categories.includes(datum.storyGame)) {
          if (!englishArray.includes(datum.english)) {
            englishArray.push(datum.english);
            newFilteredData.push({
              id: `${datum.id}story`,
              homeName: datum.storyGame,
              subCat: datum.group,
              english: datum.english,
              salish: datum.salish,
              audio1: datum.audio1,
              image1: datum.image1,
            });
          }
        }
      });
    }
  } else {
    difficulty = "easy";
    specificGame = data.find((game) => game.id == Number(locationArr[4]));
    let filteredData = [];
    if (isNaN(specificGame.target)) {
      filteredData = gameData.filter(
        (filteredDatum) => filteredDatum.subCat === specificGame.target
      );
    } else {
      filteredData = storyVocab.filter(
        (filteredDatum) => filteredDatum.group === specificGame.target
      );
    }
    newFilteredData = filteredData;
    document.title = `${gameType} - ${specificGame.category} - Kids Zone`;
  }
  dispatch(getDifficulty(difficulty));
  dispatch(getSpecificGame(specificGame));
  dispatch(getSortedData(newFilteredData));
  dispatch(setScreenDisplay('startScreen'));
};

export const setSpecificGame = (specificGame) => async (dispatch) => {
  dispatch(getSpecificGame(specificGame));
};
export const setSortedData = (sortedData) => async (dispatch) => {
  dispatch(getSortedData(sortedData));
};
export const setEndlessCategories = (categories) => async (dispatch) => {
  dispatch(getEndlessCategories(categories));
};
export const getScreen = (display) => async (dispatch) => {
  dispatch(setScreenDisplay(display));
};
export const setStats = (stats) => async (dispatch) => {
  // Expected data format: { hits: 4, misses: 1, score: 3, time: 15 }
  dispatch(getStats(stats));
};
export const clearStats = () => async (dispatch) => {
  dispatch(
    getStats({
      hits: 0,
      misses: 0,
      score: 0,
      roundStats: {
        hits: 0,
        misses: 0,
        score: 0,
      },
    })
  );
};
export const setWidth = (width) => async (dispatch) => {
  dispatch(getWidth(width));
};
export const setHeight = (height) => async (dispatch) => {
  dispatch(getHeight(height));
};
export const getData = (collectionName, nameToStore) => async (dispatch) => {
  const docSnap = await getDocs(collection(db, collectionName));
  const docs = docSnap.docs.map((doc) => {
    return { ...doc.data(), firebaseID: doc.id };
  });
  let fixedIDData = docs.map((doc) => {
    return { ...doc, id: Number(doc.id) };
  });
  let filteredData = [];
  let idArray = [];
  fixedIDData.map((datum) => {
    if (!idArray.includes(datum.id) && isNaN(datum.id) === false) {
      filteredData.push(datum);
      idArray.push(datum.id);
    }
  });
  let sortedData = filteredData.sort((a, b) => a.id - b.id);
  dispatch(
    setFirestoreData({ sortedData: sortedData, nameToStore: nameToStore })
  );
};

export default reduxSlice.reducer;

export const selectReduxSlice = (state) => state.store.value;