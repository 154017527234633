import "./index.css";
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import GameSelectButton from "../../GameSelectButton";
import ReadyToStart from '../ReadyToStart';
import { Icon } from "semantic-ui-react";
import {fetchImage} from "../../nonUIFuncs";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../../Store/store";

const DisplayMultiCategories = props => {
  let multiCategoryArray = props.levelData.filter(
    (datum) => datum.spreadGameType === props.game
  );
  let compressGame = props.game.replace(/ /g, "");
  multiCategoryArray = multiCategoryArray.filter(
    (datum) => datum.gameType !== compressGame
  );
  multiCategoryArray = multiCategoryArray.filter(
    (datum) => datum.gameType !== "WhackAMole"
  );
  let gameTypeArray = [];
  let finalMultiCategoryArray = multiCategoryArray.map(datum => {
    if (!gameTypeArray.includes(datum.gameType)) {
      gameTypeArray.push(datum.gameType);
      return(datum);
    }
  });
  finalMultiCategoryArray = finalMultiCategoryArray.filter((d) => d !== undefined);
  let multiCatDOM = finalMultiCategoryArray.map((datum) => {
    return (
      <div key={datum.gameType} className="EM-CS-content">
        <div className="EM-CS-title">{datum.gameType}</div>
        <div className="EM-CS-innerContainer">
          {datum.levelEasy === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-easyCirc">E</div>Lvl {datum.levelEasy}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-easyCircFull">
                <div className="EM-CS-categoryContainer">
                  {datum.categoryEasy.map((string) => (
                    <div key={string}>{string}</div>
                  ))}
                </div>
                <div>E: Lvl {datum.levelEasy}</div>
              </div>
            </div>
          )}
          {datum.levelMedium === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-mediumCirc">M</div>Lvl {datum.levelMedium}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-mediumCircFull">
                <div className="EM-CS-categoryContainer">
                  {datum.categoryMedium.map((string) => (
                    <div key={string}>{string}</div>
                  ))}
                </div>
                <div>M: Lvl {datum.levelMedium}</div>
              </div>
            </div>
          )}
          {datum.levelHard === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-hardCirc">H</div>Lvl {datum.levelHard}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-hardCircFull">
                <div className="EM-CS-categoryContainer">
                  {datum.categoryHard.map((string) => (
                    <div key={string}>{string}</div>
                  ))}
                </div>
                <div>H: Lvl {datum.levelHard}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });
  return <div className="EM-CS-container">{multiCatDOM}</div>;
};

const ButtonContainer = props => {
    let levelInfo = {};
    props.levelData.map(datum => {
      if (datum.spreadGameType === props.game) {
        let categories = datum.category;
        if (categories.length === 1) {
          categories = categories[0];
        }
        if (categories === props.category) {
          levelInfo = datum;
        }
      }
    });
    return (
      <div className="EM-CS-BC-container">
        {props.children}
        <div className="EM-CS-innerContainer">
          {levelInfo.levelEasy === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-easyCirc">E</div>
              Lvl {levelInfo.levelEasy}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-easyCirc">
                E: Lvl {levelInfo.levelEasy}
              </div>
            </div>
          )}
          {levelInfo.levelMedium === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-mediumCirc">M</div>
              Lvl {levelInfo.levelMedium}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-mediumCirc">
                M: Lvl {levelInfo.levelMedium}
              </div>
            </div>
          )}
          {levelInfo.levelHard === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-hardCirc">H</div>
              Lvl {levelInfo.levelHard}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-hardCirc">
                H: Lvl {levelInfo.levelHard}
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

const DisplayCategories = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  let displayCategories = props.categories.map((datum) => {
    let displayName = datum;
    let findDisplayName = reduxState.data.find((game) => game.category === datum);
    if (findDisplayName.categorySalish.length > 0) {
      displayName = findDisplayName.categorySalish;
    }
    return (
      <div key={datum}>
        {props.chosenCategories.includes(datum) ? (
          <div
            className="EM-CS-singleButton"
            onClick={() => props.updateCategories(datum)}
            id={datum}>
            <ButtonContainer
              levelData={props.levelData}
              game={props.game}
              category={datum}>
              <div className="EM-CS-checkmarkDiv">
                <div className="EM-CS-checkContainer">
                  <Icon
                    size={
                      reduxState.width > 1300 && reduxState.height > 700
                        ? "huge"
                        : reduxState.width < 700 && reduxState.height > 300
                        ? "large"
                        : "big"
                    }
                    className="EM-CS-icon"
                    name="check circle"
                  />
                </div>
                <GameSelectButton
                  buttonBackground={fetchImage(
                    reduxState.data.find((game) => game.category === datum)
                      .backgroundImg
                  )}
                  parent="gameButtons"
                  uniqueCategoryName={displayName}
                />
              </div>
            </ButtonContainer>
          </div>
        ) : (
          <div
            className="EM-CS-singleButton"
            onClick={() => props.updateCategories(datum)}
            id={datum}>
            <ButtonContainer
              levelData={props.levelData}
              game={props.game}
              category={datum}
            >
              <GameSelectButton
                buttonBackground={fetchImage(
                  reduxState.data.find((game) => game.category === datum)
                    .backgroundImg
                )}
                parent="gameButtons"
                uniqueCategoryName={displayName}
              />
            </ButtonContainer>
          </div>
        )}
      </div>
    );
  });
  return displayCategories;
};
let observer;
const CategorySelect = props => {
  const [shouldSetCategories, setShouldSetCategories] = useState(false);
  const [buttonDisplay, setButtonDisplay] = useState('inLine');
  useEffect(() => {
    observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) {
          setButtonDisplay('inLine');
        } else {
          setButtonDisplay("fixed");
        }
      },
      { threshold: [0], rootMargin: '-115px 0px 0px 0px' }
    );
    observer.observe(document.querySelector("#button-container"));
    return observer.unobserve(document.querySelector("#button-container"));
  }, []);
  const resetCategories = () => {
    props.resetCategories();
    setShouldSetCategories(false);
  };
  return (
    <div className="fullScreenColor">
      <div className="EM-topBanner">
        {props.game}
        <div className="EM-CS-topBannerSub">Choose up to 5 categories</div>
      </div>
      {buttonDisplay === "inLine" ? (
        <div id="button-container" className="EM-CS-buttonContainerInLine">
          <div
            className="EM-CS-button EMCS-hardGradientColor"
            onClick={() => props.setDisplay("games")}>
            Go Back
          </div>
          <div
            className="EM-CS-button EMCS-easyGradientColor"
            onClick={() => setShouldSetCategories(true)}>
            Continue
          </div>
        </div>
      ) : (
        <div id="button-container" className="EM-CS-buttonContainer">
          <div
            className="EMCS-hardGradientColor EM-CS-fixedButtonOne"
            onClick={() => props.setDisplay("games")}>
            Go Back
          </div>
          <div
            className="EMCS-easyGradientColor EM-CS-fixedButtonTwo"
            onClick={() => setShouldSetCategories(true)}>
            Continue
          </div>
        </div>
      )}
      {props.chosenCategories.length >= 5 || shouldSetCategories === true ? (
        <ReadyToStart
          categories={props.chosenCategories}
          resetCategories={resetCategories} >
          <div className="EM-CS-RTS-container">
            <div className="EM-CS-RTS-innerContainer">
              Do you want to choose new categories or continue?
              <div className="EM-CS-categoryDisplay">
                {props.chosenCategories.map((datum) => (
                  <div className="EM-CS-RTS-catStringContainer" key={datum}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
            <div className="EM-CS-RTS-buttonContainer">
              <div className="EM-CS-menuButton" onClick={resetCategories}>
                Reselect
              </div>
              <div
                className="EM-CS-menuButton"
                onClick={() => props.setDisplay("difficulty")}>
                Continue
              </div>
            </div>
          </div>
        </ReadyToStart>
      ) : null}
      <div className="EM-CS-mainContainer">
        <DisplayMultiCategories game={props.game} levelData={props.levelData} />
        <DisplayCategories
          categories={props.categories}
          chosenCategories={props.chosenCategories}
          game={props.game}
          levelData={props.levelData}
          updateCategories={props.updateCategories}
        />
      </div>
    </div>
  );
};

export default CategorySelect;

CategorySelect.propTypes = {
  categories: PropTypes.array,
  chosenCategories: PropTypes.array,
  game: PropTypes.string,
  levelData: PropTypes.array,
  resetCategories: PropTypes.func,
  setDisplay: PropTypes.func,
  updateCategories: PropTypes.func,
};

ButtonContainer.propTypes = {
  category: PropTypes.string,
  children: PropTypes.element,
  game: PropTypes.string,
  levelData: PropTypes.array,
};

DisplayMultiCategories.propTypes = {
  game: PropTypes.string,
  levelData: PropTypes.array,
};

DisplayCategories.propTypes = {
  categories: PropTypes.array,
  chosenCategories: PropTypes.array,
  data: PropTypes.array,
  game: PropTypes.string,
  levelData: PropTypes.array,
  updateCategories: PropTypes.func,
};

{/* Checked PropTypes */}