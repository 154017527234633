import React, { useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import ReactGA from 'react-ga4';

import Home from "../Home";
import StoryMenu from '../StoryMenu';
import RedirectPage from "../RedirectPage";
import Credits from "../CreditPage";
import SubMenu from "../SubMenu";
import EndlessModeMenu from "../EndlessModeMenu";
import SubSubMenu from "../SubSubMenu";

import WhackAMole from "../WhackAMole";
import Matching from "../Matching";
import ItemFall from "../ItemFall";
import GoodGuess from "../GoodGuess";
import Wordless from "../Wordless";
import WordToImage from "../WordToImage";
import BubblePop from "../BubblePop";
import SolveIt from "../SolveIt";
import FillInTheBlank from "../FillInTheBlank";
import Story from "../Stories";
import Crocadile from "../Crocadile";
import History from "../History";
import Counting from "../Counting";
import Dictionary from "../Dictionary";

const AppRouter = props => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: location.pathname});
  }, [location.pathname]);
  let routesArr = useRoutes([
    {
      path: "/KidsZone/Home/",
      element: <Home scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/",
      element: <Home scoreData={props.scoreData} />,
    },
    {
      path: "/Home/",
      element: <Home scoreData={props.scoreData} />,
    },
    {
      path: "/",
      element: <Home scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/Dictionary/",
      element: <Dictionary scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/Stories/",
      element: <StoryMenu scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/Home/:category/",
      element: <SubMenu scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/Stories/:category/",
      element: <SubMenu scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/Home/:category/:gameType/",
      element: <SubSubMenu scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/Home/:category/GoodGuess/:gameId/",
      element: (
        <GoodGuess
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/WhackAMole/:gameId/",
      element: (
        <WhackAMole
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/Counting/:gameId/",
      element: (
        <Counting
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/Matching/:gameId/",
      element: (
        <Matching
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/ItemFall/:gameId/",
      element: (
        <ItemFall
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/Wordless/:gameId/",
      element: (
        <Wordless
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/WordToImage/:gameId/",
      element: (
        <WordToImage
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/FillInTheBlank/:gameId/",
      element: (
        <FillInTheBlank
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/BubblePop/:gameId/",
      element: (
        <BubblePop
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/SolveIt2/:gameId/",
      element: (
        <SolveIt
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/Crocadile/:gameId/",
      element: (
        <Crocadile
          scoreData={props.scoreData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/Home/:category/StoryBook/:gameId/",
      element: <Story scoreData={props.scoreData} />,
    },
    {
      path: "/KidsZone/EndlessMode/",
      element: <EndlessModeMenu levelData={props.levelData} />,
    },
    {
      path: "/KidsZone/EndlessMode/WhackAMole/",
      element: (
        <WhackAMole
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/EndlessMode/Counting/",
      element: (
        <Counting
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/EndlessMode/Matching/",
      element: (
        <Matching
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/EndlessMode/ItemFall/",
      element: (
        <ItemFall
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/EndlessMode/GoodGuess/",
      element: (
        <GoodGuess
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/EndlessMode/Wordless/",
      element: (
        <Wordless
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/EndlessMode/WordToImage/",
      element: (
        <WordToImage
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/EndlessMode/BubblePop/",
      element: (
        <BubblePop
          scoreData={props.scoreData}
          updateLevelData={props.updateLevelData}
          updateScoreData={props.updateScoreData}
        />
      ),
    },
    {
      path: "/KidsZone/HistoryGame/",
      element: <History updateScoreData={props.updateScoreData} />,
    },
    { path: "/KidsZone/Credits/", element: <Credits /> },
    { path: "*", element: <RedirectPage /> },
  ]);
  return routesArr;
};


export default AppRouter;

AppRouter.propTypes = {
  levelData: PropTypes.array,
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}