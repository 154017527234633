import './index.css';
import React from 'react';
import PropTypes from "prop-types";

const BottomStatBar = props => {
  return (
    <div className="bottomStatBar BSB-slide-in">
      {props.children.length === undefined ? (
        <div className="keyword noselect BSB-text">{props.children}</div>
      ) : (
        props.children.map((element, idx) => (
          <div className="keyword noselect BSB-textChildren" key={idx}>
            {element}
          </div>
        ))
      )}
    </div>
  );
}

export default BottomStatBar;

BottomStatBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
}

{/* Checked PropTypes */}