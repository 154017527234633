import './index.css';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StatScreen from '../StatScreen';
import BottomStatBar from '../BottomStatBar/index';
import InvisiBar from '../Invisibar/index';
import {shuffle, fetchImage, fetchAudio} from '../nonUIFuncs';
import PopupWord from '../Components/popupWord';
import FloatingNumber from '../Components/floatingNumber';
import LevelBar from "../Components/endlessLevelBar";
import EndEndlessGame from '../Components/endEndlessMode';
import CloseEndless from "../Components/endlessCloseButton";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

let difficultyVar = "easy";
let displayedClock = 0;
let currentTarget = undefined;
let shortTimer = 0
let mouseClickX = 0;
let mouseClickY = 0;
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
let bodyElement = null;
let chosenNumber = null;
let firstNumber = null;
let secondNumber = null;
let chosenRoundData = [];
let chosenNumberSalish = null;
let firstNumberSalish = null;
let secondNumberSalish = null;
let addOrSubtract = 0;
let switchDisplayVar = "game";
let id = 0;
let showPopup = false;
let currentLevelVar = 0;
let gameBackground = null;
let keywordImage = null;
let audioFile = undefined;
let prepparing = false;
let displayElements;
let runNewArray;

const SwitchDisplay = props => {
  const [showEndlessEnd, setShowEndlessEnd] = useState();
  const reduxState = useSelector(selectReduxSlice);
  if (switchDisplayVar === "stats") {
    if (prepparing === false) {
      setTimeout(props.stepDifficulty, 6000);
      prepparing = true;
    }
    if (props.endless === true) {
      switchDisplayVar = "game";
      props.changeDisplay();
      return null;
    }
    return <StatScreen scoreType="hits" />;
  } else if (switchDisplayVar === "game") {
    if (props.roundStats.score >= 10 && props.endless !== true) {
      clearTimeout(runNewArray);
      clearTimeout(displayElements);
      switchDisplayVar = "stats";
    }
    if (
      keywordImage === null ||
      !keywordImage.includes(reduxState.specificGame.keyWordImg)
    ) {
      keywordImage = `url(${fetchImage(reduxState.specificGame.keyWordImg)})`;
    }
    let keywordImg = { backgroundImage: keywordImage };
    return (
      <div className="centerContentContainer">
        <div
          style={{
            position: "absolute",
            top: mouseClickY - reduxState.height / 10,
            left: mouseClickX,
            zIndex: 99999,
          }}
          className="BPG-plusMinusContainer"
        >
          <FloatingNumber correctness={props.correctness} />
        </div>
        {reduxState.specificGame.category === "Math" ? (
          props.addSubtract()
        ) : (
          <div
            className="bubblePopGame-keyWordBox BPG-keywordBox"
            style={keywordImg}
          >
            <div className="bubblePopGame-keyWord noselect BPG-keyword">
              {props.keyword}
            </div>
          </div>
        )}
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        <div className="BPG-gameContainer">{props.DOMArray}</div>
        <CloseEndless enabled={props.endless} onClick={setShowEndlessEnd} />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={props.currentLevel}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={props.updateLevelData}
        />
        <InvisiBar
          enabled={props.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
      </div>
    );
  } else {
    return null;
  }
};

const BubblePopGame = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const DOMArrayRef = useRef();
  const currentLevelRef = useRef();
  const currentLevelProgressRef = useRef();
  const cssArrayRef = useRef();
  const reduxStateRef = useRef();
  const [correctness, setCorrectness] = useState(null);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [DOMArray, setDOMArray] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [cssArray, setCssArray] = useState([]);
  cssArrayRef.current = cssArray;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  DOMArrayRef.current = DOMArray;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    let timer = setInterval(() => {
      displayedClock = displayedClock + 1;
      shortTimer = shortTimer + 1;
    }, 1000);
    setCurrentLevel(0);
    currentLevelVar = 0;
    setCurrentLevelProgress(0);
    difficultyVar = reduxState.difficulty;
    switchDisplayVar = "game";
    bodyElement = document.getElementsByTagName("body");
    bodyElement[0].style.overflow = "hidden";
    changeDisplay();
    return () => {
      if (bodyElement !== null) {
        bodyElement[0].style.overflow = "auto";
      }
      difficultyVar = "easy";
      switchDisplayVar = "game";
      clearInterval(timer);
      clearInterval(displayElements);
      clearTimeout(runNewArray);
      displayedClock = 0;
      resetStats();
    };
  }, []);
  const resetStats = () => {
    shortTimer = 0;
  };
  const changeDisplay = () => {
    let previousTarget = currentTarget;

    let spliceNumber = 2;
    if (difficultyVar === "easy") {
      spliceNumber = 2;
    } else if (difficultyVar === "medium") {
      spliceNumber = 3;
    } else if (difficultyVar === "hard") {
      spliceNumber = 5;
    }
    let mutatableData = [];
    reduxState.sortedData.map((datum) => {
      mutatableData.push(datum);
    });
    let sortedData = shuffle(mutatableData);
    chosenRoundData = [];
    if (reduxState.specificGame.category === "Math") {
      addOrSubtract = Math.floor(Math.random() * 2);
      if (addOrSubtract === 0) {
        chosenNumber = minMaxScreen(2, reduxState.sortedData.length);
        let fnRange = chosenNumber - 1;
        firstNumber = minMaxScreen(1, fnRange);
        secondNumber = chosenNumber - firstNumber;
        chosenNumberSalish = reduxState.sortedData[chosenNumber - 1].salish;
        firstNumberSalish = reduxState.sortedData[firstNumber - 1].salish;
        secondNumberSalish = reduxState.sortedData[secondNumber - 1].salish;
        currentTarget = reduxState.sortedData.find(
          (datum) => Number(datum.english) === secondNumber
        );
      } else {
        chosenNumber = minMaxScreen(2, reduxState.sortedData.length);
        let fnRange = chosenNumber - 1;
        firstNumber = minMaxScreen(1, fnRange);
        secondNumber = chosenNumber - firstNumber;
        chosenNumberSalish = reduxState.sortedData[chosenNumber - 1].salish;
        firstNumberSalish = reduxState.sortedData[firstNumber - 1].salish;
        secondNumberSalish = reduxState.sortedData[secondNumber - 1].salish;
        currentTarget = reduxState.sortedData.find(
          (datum) => Number(datum.english) === chosenNumber
        );
      }
    } else {
      let randomChosenTargetNumber = Math.floor(
        Math.random() * reduxState.sortedData.length
      );
      currentTarget = mutatableData[randomChosenTargetNumber];
      if (previousTarget !== undefined) {
        while (previousTarget.english === currentTarget.english) {
          randomChosenTargetNumber = Math.floor(
            Math.random() * reduxState.sortedData.length
          );
          currentTarget = mutatableData[randomChosenTargetNumber];
        }
      }
    }
    setKeyword(currentTarget.salish);
    chosenRoundData.push(currentTarget);
    let possibleDistractors = sortedData.filter(
      (datum) => datum.english !== currentTarget.english
    );
    let roundDistractors = possibleDistractors.splice(0, spliceNumber);
    roundDistractors.map((datum) => {
      chosenRoundData.push(datum);
    });
    newObjectArray();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(
      () => number.classList.remove("levelNumber"),
      1200
    );
  };
  const checkMath = (e) => {
    clearInterval(displayElements);
    clearInterval(runNewArray);
    let clickedObject = DOMArrayRef.current.filter((DOMElement) => {
      return DOMElement.props.id === Number(e.target.id);
    });
    mouseClickX = e.pageX;
    mouseClickY = e.pageY;
    let clickedObjectImg
    if (clickedObject[0].props.children.props.style !== undefined) {
      clickedObjectImg = clickedObject[0].props.children.props.style.backgroundImage;
    } else {
      clickedObjectImg =
        clickedObject[0].props.children.props.children.props.style.backgroundImage;
    }
    
    if (clickedObjectImg === undefined) {
      clickedObjectImg =
        clickedObject[0].props.children.props.children.props.style
          .backgroundImage;
    }
    let targetImgPath = `url(${fetchImage(currentTarget.image1)})`;
    if (targetImgPath === clickedObjectImg) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (currentLevelRef.current + 2 === progress && routeLocation.state.endless === true) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('wrong');
    }
    changeDisplay();
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    clearInterval(displayElements);
    setDOMArray([]);
    setCssArray([]);
    switchDisplayVar = "game";
    prepparing = false;
    displayedClock = 0;
    if (difficultyVar === "easy") {
      difficultyVar = "medium";
      changeDisplay();
    } else if (difficultyVar === "medium") {
      difficultyVar = "hard";
      changeDisplay();
    } else {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const newObjectArray = () => {
    playAudio(currentTarget.audio1);
    setCssArray([]);
    setDOMArray([]);
    shuffle(chosenRoundData);
    let newCssArray = [];
    let i = 0;
    for (i = 0; i < chosenRoundData.length; i++) {
      id = id + 1;
      let currentData = chosenRoundData[i];
      let backgroundImage = currentData.image1;
      let randomX = minMaxScreen(
        reduxStateRef.current.width * 0.25,
        reduxStateRef.current.width * 0.75
      );
      let randomR = Math.random() * 255;
      let randomG = Math.random() * 255;
      let randomB = Math.random() * 255;
      let containingDivStyle = {
        position: "absolute",
        left: randomX,
        backgroundColor: `rgba(${randomR},${randomG},${randomB},1)`,
      };
      let divStyle = {
        backgroundColor: `rgba(${randomR},${randomG},${randomB},1)`,
        backgroundImage: `url(${fetchImage(backgroundImage)})`,
      };
      let idThing = `${i}${difficultyVar}${randomX}`;
      let uniqueStringContainer = `container${i}${difficultyVar}${randomX}`;
      if (difficultyVar === "easy") {
        newCssArray.push(
          <div
            onClick={(e) => checkMath(e, i)}
            id={i}
            uniquestringcontainer={uniqueStringContainer}
            key={uniqueStringContainer}
            className="FallingAnimationEasy BPG-itemContainer"
            style={containingDivStyle}>
            <div
              id={i}
              idthing={idThing}
              uniquestringcontainer={uniqueStringContainer}
              key={uniqueStringContainer}
              english={currentData.english}
              className="BPG-hitBox">
              <div
                id={i}
                idthing={idThing}
                english={currentData.english}
                style={divStyle}
                className="BPG-hitBoxContents"
              />
            </div>
          </div>
        );
      } else if (difficultyVar === "medium") {
        newCssArray.push(
          <div
            className="FallingAnimationMed BPG-itemContainer"
            onClick={(e) => checkMath(e, i)}
            id={i}
            uniquestringcontainer={uniqueStringContainer}
            key={uniqueStringContainer}
            style={containingDivStyle}>
            <div
              id={i}
              idthing={idThing}
              uniquestringcontainer={uniqueStringContainer}
              key={uniqueStringContainer}
              english={currentData.english}
              className="BPG-hitBox">
              <div
                id={i}
                idthing={idThing}
                english={currentData.english}
                style={divStyle}
                className="BPG-hitBoxContents"
              />
            </div>
          </div>
        );
      } else {
        newCssArray.push(
          <div
            className="FallingAnimationHard BPG-itemContainer"
            onClick={(e) => checkMath(e, i)}
            id={i}
            uniquestringcontainer={uniqueStringContainer}
            key={uniqueStringContainer}
            style={containingDivStyle}>
            <div
              id={i}
              idthing={idThing}
              uniquestringcontainer={uniqueStringContainer}
              key={uniqueStringContainer}
              english={currentData.english}
              className="BPG-hitBox">
              <div
                id={i}
                idthing={idThing}
                english={currentData.english}
                style={divStyle}
                className="BPG-hitBoxContents"
              />
            </div>
          </div>
        );
      }
    }
    setCssArray(newCssArray);
    displayElements = setInterval(spawnObjects, 1000);
  };
  const spawnObjects = () => {
    clearInterval(runNewArray);
    let randomizedList = shuffle(cssArrayRef.current);
    let newObject = undefined;
    if (randomizedList.length > 0) {
      newObject = randomizedList[0];
      setCssArray(randomizedList.filter(d => d.key !== newObject.key));
      setDOMArray([
        ...DOMArrayRef.current,
        newObject,
      ]);
    }
    if (newObject === undefined) {
      clearInterval(displayElements);
      if (difficultyVar === "easy") {
        runNewArray = setTimeout(noAnswer, 13000);
      } else if (difficultyVar === "medium") {
        runNewArray = setTimeout(noAnswer, 11500);
      } else {
        runNewArray = setTimeout(noAnswer, 10000);
      }
    }
  };
  const noAnswer = () => {
    mouseClickX = reduxStateRef.current.width / 2;
    mouseClickY = reduxStateRef.current.height / 2;
    dispatch(
      setStats({
        misses: reduxStateRef.current.stats.misses + 1,
        score: reduxStateRef.current.stats.score - 1,
        roundStats: {
          misses: reduxStateRef.current.stats.roundStats.misses + 1,
          score: reduxStateRef.current.stats.roundStats.score - 1,
        },
      })
    );
    setCorrectness('wrong');
    changeDisplay();
    setTimeout(() => setCorrectness(null), 1000);
  };
  const playAudio = (audioName) => {
    if (audioFile !== undefined) {
      audioFile.pause();
      audioFile = undefined;
    }
    audioFile = new Audio(fetchAudio(audioName));
    audioFile.play();
  };
  const addSubtract = () => {
    if (!keywordImage.includes(reduxState.specificGame.keyWordImg)) {
      keywordImage = `url(${fetchImage(reduxState.specificGame.keyWordImg)})`;
    }
    let keywordBoxStyle = {backgroundImage: keywordImage};
    if (addOrSubtract <= 0) {
      return (
        <div
          className="bubblePopGame-keyWordBox BPG-keywordBox"
          style={keywordBoxStyle}>
          <div className="bubblePopGame-keyWord noselect BPG-keyword">
            {chosenNumberSalish} - {firstNumberSalish}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="bubblePopGame-keyWordBox BPG-keywordBox"
          style={keywordBoxStyle}>
          <div className="bubblePopGame-keyWord noselect BPG-keyword">
            {firstNumberSalish} + {secondNumberSalish}
          </div>
        </div>
      );
    }
  };
  const minMaxScreen = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  if (
    gameBackground === null ||
    !gameBackground.includes(reduxState.specificGame.backgroundImg)
  ) {
    gameBackground = `url(${fetchImage(
      reduxState.specificGame.backgroundImg
    )})`;
  }
  return (
    <div>
      <div
        id="fullScreen"
        style={{ backgroundImage: gameBackground }}
        className="fullScreen BPG-outerContainer overflow-y: hidden;"
      >
        <SwitchDisplay
          addSubtract={addSubtract}
          changeDisplay={changeDisplay}
          chosenPopupWord={chosenPopupWord}
          correctness={correctness}
          currentLevel={currentLevelRef.current}
          DOMArray={DOMArrayRef.current}
          endless={routeLocation.state.endless}
          keyword={keyword}
          roundStats={reduxStateRef.current.stats.roundStats}
          stepDifficulty={stepDifficulty}
          updateLevelData={props.updateLevelData}
        />
        {switchDisplayVar === "game" || routeLocation.state.endless === true ? (
          <BottomStatBar>
            {routeLocation.state.endless === true ? (
              <LevelBar
                difficulty={difficultyVar}
                currentLevel={currentLevelRef.current}
                currentLevelProgress={currentLevelProgressRef.current}
              />
            ) : (
              <div className="BPG-BSB-container">
                <div>
                  {difficultyVar.charAt(0).toUpperCase() +
                    difficultyVar.slice(1)}
                </div>
                <div className="BPG-BSB-score">
                  {reduxState.uiWords[13].salish}:{" "}
                  {reduxStateRef.current.stats.roundStats.score}
                </div>
              </div>
            )}
          </BottomStatBar>
        ) : null}
      </div>
    </div>
  );
}

export default BubblePopGame;

BubblePopGame.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
};
SwitchDisplay.propTypes = {
  addSubtract: PropTypes.func,
  changeDisplay: PropTypes.func,
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  currentLevel: PropTypes.number,
  DOMArray: PropTypes.array,
  endless: PropTypes.bool,
  keyword: PropTypes.string,
  roundStats: PropTypes.object,
  stepDifficulty: PropTypes.func,
  updateLevelData: PropTypes.func,
};

{/* Checked PropTypes */}