import './index.css';
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GameSelectButton from "../GameSelectButton";
import { Link, useLocation } from "react-router-dom";
import {Button} from "semantic-ui-react"
import RedirectPage from "../RedirectPage/index";
import StarDisplay from './StarDisplay/index';
import ScoreDisplay from './ScoreDisplay/index';
import {fetchImage, fetchAudio, getLocation} from '../nonUIFuncs';
import { useDispatch, useSelector } from "react-redux";
import { getScreen, selectReduxSlice } from "../../Store/store";

let audioFile = null;
let buttonBackground = null;
let badgeImg = null;
let previousHover = null;

const GameData = props => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const subSubHover = (e, data) => {
    if (data !== previousHover) {
      let findGame = reduxState.data.filter(
        (datum) => datum.game == props.locationArr[3]
      );
      findGame = findGame.filter((datum) => datum.displayGameName === data);
      findGame = findGame.find((datum) => datum.category === props.locationArr[2]);
      if (findGame === undefined) {
        return;
      }
      let target = findGame.target;
      if (findGame.game === "ItemFall") {
        target = findGame.items;
      }
      props.setCategoryData(
        reduxState.gameData.filter((datum) => datum.subCat === target)
      );
      previousHover = data;
    }
  };
  let games = reduxState.data.filter(
    (indivGame) => indivGame.category === props.locationArr[2]
  );
  games = games.filter((game) => game.game === props.locationArr[3]);
  if (badgeImg === null || !buttonBackground.includes(games[0].badge)) {
    if (games.length > 0) {
      badgeImg = `url(${fetchImage(games[0].badge)})`;
    } else if (props.redirect === false) {
      props.setRedirect(true);
    }
  }
  if (props.pageTitle.length === 0) {
    props.setPageTitle(games[0].displayGameType);
  }
  games = games.map((indivGame) => {
    if (
      buttonBackground === null ||
      !buttonBackground.includes(indivGame.backgroundImg)
    ) {
      buttonBackground = fetchImage(indivGame.backgroundImg);
    }
    let score = props.scoreData.find(
      (gameScore) => gameScore.id === indivGame.id
    );
    let nextLocation = {
      pathname: `${indivGame.id}`,
    };
    return (
      <div
        onMouseOver={(e) => subSubHover(e, indivGame.displayGameName)}
        className="SSB-buttonAStatContainer"
        key={indivGame.displayGameName}>
        <div className="SSB-buttonContainer">
          <Link
            to={nextLocation}
            state={{endless: false}}
            onClick={() => dispatch(getScreen('startScreen'))}>
            <div>
              <GameSelectButton
                buttonBackground={buttonBackground}
                parent="subSubMenu"
                uniqueCategoryName={indivGame.displayGameName}
              />
            </div>
          </Link>
        </div>
        {score !== undefined ? (
          score.score !== 0 || score.time !== 0 ? (
            <div>
              <ScoreDisplay
                goldStar={reduxState.goldStar}
                score={score}
                uiWords={reduxState.uiWords}
              />
              <StarDisplay
                goldStar={reduxState.goldStar}
                score={score}
                set="firstSet"
              />
              <StarDisplay
                goldStar={reduxState.goldStar}
                score={score}
                set="secondSet"
              />
            </div>
          ) : score.scoreType !== "story" ? (
            <div className="SSB-centerContent">
              <div className="SSB-placeholderContainer">
                Play this game to get a score!
              </div>
            </div>
          ) : null
        ) : null}
      </div>
    );
  });
  if (games.length === 0 || props.redirect === true) {
    return <RedirectPage />;
  } else {
    return games;
  }
};

const SubSubMenu = props => {
  let routeLocation = useLocation();
  const locationArr = getLocation(routeLocation.pathname);
  const [redirect, setRedirect] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  useEffect(() => {
    document.title = `${locationArr[3]} - ${locationArr[2]} - Kids Zone`;
  }, []);

  const playAudio = audio => {
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
      audioFile = null;
    }
    audioFile = new Audio(`${fetchAudio(audio)}`);
    audioFile.play();
  }
  let badgeImageStyle = {
    backgroundImage: badgeImg,
  };
  return (
    <div className="home-fullscreen">
      {redirect ? null : (
        <div
          className={`SSB-categoryTitle ${
            routeLocation.pathname.includes("Story")
              ? "storyBanner"
              : "gamesBanner"
          }`}
        >
          <div className="SSB-badge">
            <div className="SSB-badgeImage" style={badgeImageStyle} />
          </div>
          {pageTitle}
          <div className="SSB-badge">
            <div className="SSB-badgeImage" style={badgeImageStyle} />
          </div>
        </div>
      )}
      <div className="home-centerContent">
        <GameData
          scoreData={props.scoreData}
          redirect={redirect}
          setRedirect={setRedirect}
          pageTitle={pageTitle}
          setPageTitle={setPageTitle}
          setCategoryData={setCategoryData}
          locationArr={locationArr}
        />
      </div>
      {categoryData.length > 0 ? (
        <div className="SSB-vocabDisplayContainer">
          {categoryData.map((datum) => {
            return (
              <div
                onClick={() => playAudio(datum.audio1)}
                className="SSB-vocabDisplay"
                key={`${datum.english}`}
              >
                <div className="SSB-marginBTen">{datum.salish}</div>
                <div
                  style={{
                    backgroundImage: `url(${fetchImage(datum.image1)})`,
                  }}
                  className="SSB-vocabImageContainer"
                ></div>
              </div>
            );
          })}
        </div>
      ) : null}
      <Link to={{ pathname: `/${locationArr[0]}/${locationArr[1]}/${locationArr[2]}/` }}>
        <Button
          icon="angle left"
          className="backButton"
          circular
          size="large"
          color="linkedin"
        />
      </Link>
    </div>
  );
}

export default SubSubMenu;

SubSubMenu.propTypes = {
  scoreData: PropTypes.array,
};
GameData.propTypes = {
  locationArr: PropTypes.array,
  pageTitle: PropTypes.string,
  redirect: PropTypes.any,
  scoreData: PropTypes.array,
  setCategoryData: PropTypes.func,
  setPageTitle: PropTypes.func,
  setRedirect: PropTypes.func,
};

{/* Checked PropTypes */}