import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import SolveItGame from "../SolveItGame";
import {getLocation} from '../nonUIFuncs';
import DisplayScreen from '../Components/displayScreen';
import BackButton from "../Components/backButton";
import { useDispatch, useSelector } from 'react-redux';
import { setSpecificGame, getScreen, setSortedData, selectReduxSlice } from '../../Store/store';

let gameTime = 0;

const SolveIt = props => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  let routeLocation = useLocation();
  let locationArr = getLocation(routeLocation.pathname);
  const [update, setUpdate] = useState(0);
  const scoreType = 'normal';
  useEffect(() => {
    filterData();
    dispatch(getScreen('startScreen'));
  }, []);
  const filterData = () => {
    let specificGame = reduxState.data.find(
      (game) => game.id == Number(locationArr[4])
    );
    let filteredData = reduxState.gameData.filter(
      (filteredDatum) => filteredDatum.subCat === specificGame.target
    );
		document.title = `Solve It - ${specificGame.category} - Kids Zone`;
    dispatch(setSpecificGame(specificGame));
    dispatch(setSortedData(filteredData));
  };
  let gameComponent = (
    <SolveItGame checkWorld={() => dispatch(getScreen("playAgainScreen"))} />
  );
  return (
    <div className="fullscreenNoOverflow">
      <BackButton />
      <DisplayScreen
        clearGameStats={() => dispatch(getScreen("startScreen"))}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={() => dispatch(getScreen("gameScreen"))}
        time={gameTime}
        updateDisplay={() => setUpdate(update + 1)}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default SolveIt;

SolveIt.propTypes = {
  scoreData: PropTypes.array,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}