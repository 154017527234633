import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CardFlip from '../CardFlip';
import StatScreen from '../StatScreen';
import BottomStatBar from '../BottomStatBar';
import InvisiBar from '../Invisibar/index';
import LevelBar from "../Components/endlessLevelBar";
import CloseEndless from "../Components/endlessCloseButton";
import {
  shuffle,
  placementArray,
  fetchImage,
  fetchAudio,
} from "../nonUIFuncs.js";
import PopupWord from "../Components/popupWord";
import EndEndlessGame from "../Components/endEndlessMode";
import { useLocation } from "react-router-dom";
import { selectReduxSlice } from '../../Store/store';
import { useSelector } from 'react-redux';

const MATCH_ANIMATION_TIMER = 800;
let difficulty = "easy"
let targetBodyHeight = 45;
let targetBodyWidth = 45;
let positionArrayLength = null;
let cardFlipArray = []
let cardAudio = undefined;
let filtered = undefined;
let switchData = 0
let overAllTime = 0;
let finalTime = 0;
let shortTimer = 0;
// let matchedWord = undefined;
let switchDisplayVar = false;
let firstImg = null;
let firstId = null;
let removedCard = [];
let flippedCurrent = {};
let resetWordBox = null;
let testNum = 0;
let ratio = 1;
let showPopup = false;
// let chosenPopupWord = undefined;
let updatedTime = false;
let currentLevelVar = 0;
let background = null;
let keywordImage = null;
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);

const SwitchDisplay = props => {
  const [showEndlessEnd, setShowEndlessEnd] = useState();
  const reduxState = useSelector(selectReduxSlice);
  targetBodyHeight = reduxState.height / 3.25;
  targetBodyWidth = targetBodyHeight * 0.643;
  if (switchDisplayVar === true) {
    if (props.endless === true) {
      switchDisplayVar = false;
      props.changeDisplay();
      return;
    }
    if (shortTimer === 5) {
      updatedTime = false;
      props.stepDifficulty();
    }
    return <StatScreen scoreType="time" time={finalTime} />;
  } else if (switchDisplayVar === false) {
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      props.editCardFlipArray();
    }
    if (positionArrayLength === removedCard.length) {
      setTimeout(props.setStatDisplay, MATCH_ANIMATION_TIMER);
      finalTime = props.displayedClock;
      if (updatedTime === false) {
        overAllTime = overAllTime + props.displayedClock;
        updatedTime = true;
      }
    }
    if (keywordImage === null) {
      keywordImage = `url(${fetchImage(reduxState.specificGame.keyWordImg)})`;
    }
    let keywordImg = {
      backgroundImage: keywordImage,
    };
    return (
      <div className="MG-container">
        {cardFlipArray.map((cardData) => {
          return <CardFlip key={cardData.identifier} cardData={cardData} />;
        })}
        <div className="MG-rightBox">
          {props.correctMatchWord ? (
            <div className="MG-rightBoxInner">
              <div style={keywordImg} className="MG-keyWordBox noselect">
                <div className="keyword MG-keywordText">
                  {props.matchedWord}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        {props.timeoutState ? <div className="timeoutDiv" /> : null}
        <CloseEndless enabled={props.endless} onClick={setShowEndlessEnd} />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={props.currentLevel}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={props.updateLevelData}
        />
        <InvisiBar
          enabled={props.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
      </div>
    );
  } else {
    return null;
  }
};

const MatchingGame = props => {
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();

  const displayedClockRef = useRef();
  const currentLevelRef = useRef();
  const currentLevelProgressRef = useRef();

  const [correctMatchWord, setCorrectMatchWord] = useState(false);
  const [timeoutState, setTimeoutState] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [matchedWord, setMatchedWord] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [displayedClock, setDisplayedClock] = useState(0);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);

  displayedClockRef.current = displayedClock;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;

  useEffect(() => {
    let timer = setInterval(updateDisplayClock, 1000);
    currentLevelVar = 0;
    setCurrentLevel(0);
    setCurrentLevelProgress(0);
    targetBodyHeight = reduxState.height / 3.25;
    targetBodyWidth = targetBodyHeight * 0.643;
    switchDisplayVar = false;
    flippedCurrent = {};
    removedCard = [];
    difficulty = reduxState.difficulty;
    overAllTime = 0;
    updatedTime = false;
    changeDisplay();
    return () => {
      clearTimeout(resetWordBox);
      overAllTime = 0;
      updatedTime = false;
      positionArrayLength = null;
      difficulty = "easy";
      switchDisplayVar = false;
      switchData = 0;
      setDisplayedClock(0);
      clearInterval(timer);
      stopAudio();
      cardFlipArray = [];
      firstImg = null;
      firstId = null;
      setTimeoutState(false);
    };
  }, []);
  const updateDisplayClock = () => {
    setDisplayedClock(displayedClockRef.current + 1);
    shortTimer = shortTimer + 1;
  };
  const changeDisplay = () => {
    shortTimer = 0;
    switchData = switchData + 1;
    switchDisplayVar = false;
    positionArrayLength = null;
    setDisplayedClock(0);
    firstImg = null;
    firstId = null;
    removedCard = [];
    flippedCurrent = {};
    cardFlipArray = [];
    let spliceNumber = 0;
    let positionArray = [];
    let positionArrayDenom = [];
    ratio = reduxState.height / reduxState.width;
    if (difficulty === "easy") {
      spliceNumber = 4;
      positionArray = placementArray(1, 1, 5, 3, 1, 1);
      if (reduxState.height > reduxState.width) {
        targetBodyWidth = reduxState.width / 5.25;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
        positionArrayDenom = [5, 3];
      } else {
        targetBodyHeight = reduxState.height / 4;
        targetBodyWidth = targetBodyHeight * 0.643;
        positionArrayDenom = [5, 3];
      }
    } else if (difficulty === "medium") {
      spliceNumber = 6;
      if (reduxState.height > reduxState.width) {
        positionArray = placementArray(0.9, 0.75, 4, 4, 1.1, 1.05);
        if (ratio < 0.75) {
          targetBodyWidth = reduxState.width / 6;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        } else {
          targetBodyWidth = reduxState.width / 7.5;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        }
        positionArrayDenom = [4, 5];
      } else {
        positionArray = placementArray(1, 1, 5, 4, 1, 1);
        targetBodyHeight = reduxState.height / 4.5;
        targetBodyWidth = targetBodyHeight * 0.643;
        positionArrayDenom = [5, 4];
      }
    } else if (difficulty === "hard") {
      spliceNumber = 8;
      positionArray = placementArray(1, 0.75, 5, 4, 1, 1.05);
      if (reduxState.height > reduxState.width) {
        if (ratio < 0.75) {
          targetBodyWidth = reduxState.width / 6;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        } else {
          targetBodyWidth = reduxState.width / 7.5;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        }
        positionArrayDenom = [5, 5];
      } else {
        positionArray = placementArray(1, 1, 5, 5, 1, 1);
        targetBodyHeight = reduxState.height / 5.35;
        targetBodyWidth = targetBodyHeight * 0.643;
        positionArrayDenom = [5, 5];
      }
    }
    let selected = shuffle(reduxState.sortedData).slice(0, spliceNumber);
    let gameData = new Array();
    gameData.push(...selected);
    let copiedA = selected.map((obj) => {
      return { ...obj, id: obj.id + "copy" };
    });
    gameData.push(...copiedA);
    positionArrayLength = positionArray.length;
    if (background === null) {
      background = `url(${fetchImage(reduxState.specificGame.backgroundImg)})`;
    }
    while (gameData.length > 0) {
      let randomPositionNum = Math.floor(Math.random() * positionArray.length);
      let rpX = positionArray[randomPositionNum].x;
      let rpY = positionArray[randomPositionNum].y;
      positionArray.splice(randomPositionNum, 1);
      let randomObjectNum = Math.floor(Math.random() * gameData.length);
      let currentObject = gameData[randomObjectNum];
      gameData.splice(randomObjectNum, 1);
      let targetSprite = `url(${fetchImage(currentObject.image1)})`;
      let faceDownImg = background;
      cardFlipArray.push(
        (currentObject = {
          salish: currentObject.salish,
          audio: currentObject.audio1,
          identifier: currentObject.id,
          targetBodyWidth: targetBodyWidth,
          targetBodyHeight: targetBodyHeight,
          frontImage: faceDownImg,
          backImage: targetSprite,
          height: reduxState.height,
          width: reduxState.width,
          rpX: rpX,
          rpY: rpY,
          positionArrayDenom: positionArrayDenom,
          selectCard: _selectCard,
          flipped: () => flippedCurrent,
        })
      );
    }
  };
  const editCardFlipArray = () => {
    if (difficulty === "easy") {
      if (reduxState.height > reduxState.width) {
        targetBodyWidth = reduxState.width / 5.25;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
      } else {
        targetBodyHeight = reduxState.height / 4;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    } else if (difficulty === "medium") {
      if (reduxState.height > reduxState.width) {
        if (ratio < 0.75) {
          targetBodyWidth = reduxState.width / 6;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        } else {
          targetBodyWidth = reduxState.width / 7.5;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        }
      } else {
        targetBodyHeight = reduxState.height / 4.5;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    } else if (difficulty === "hard") {
      if (reduxState.height > reduxState.width) {
        if (ratio < 0.75) {
          targetBodyWidth = reduxState.width / 6;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        } else {
          targetBodyWidth = reduxState.width / 7.5;
          targetBodyHeight = targetBodyWidth * (1 / 0.643);
        }
      } else {
        targetBodyHeight = reduxState.height / 5.35;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    }
    cardFlipArray.map((datum) => {
      datum.height = reduxState.height;
      datum.width = reduxState.width;
      datum.targetBodyHeight = targetBodyHeight;
      datum.targetBodyWidth = targetBodyWidth;
    });
  };
  const removeCorrectCards = () => {
    cardFlipArray = filtered;
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(
      () => number.classList.remove("levelNumber"),
      1200
    );
  };
  const checkCorrectness = (e, id, image, flipped, salish) => {
    if (firstImg !== null && firstId !== id) {
      if (firstImg === image && firstId !== id) {
        let popUpWords = reduxState.popupWords.filter(
          (datum) => datum.type === "correct"
        );
        let randomPopupNum = Math.floor(Math.random() * popUpWords.length);
        // chosenPopupWord = popUpWords[randomPopupNum].salish;
        showPopup = true;
        setTimeout(() => (showPopup = false), 1300);
        let progress = currentLevelProgressRef.current + 1;
        setCurrentLevelProgress(progress);
        if (
          currentLevelRef.current + 2 === progress &&
          routeLocation.state.endless === true
        ) {
          setTimeout(nextEndlessLevel, 700);
        }
        correctSoundEffect.play();
        document.getElementById(firstId).className =
          "cardFlip-containerAnimate";
        document.getElementById(id).className = "cardFlip-containerAnimate";
        filtered = cardFlipArray.filter(
          (datum) => datum.backImage !== firstImg
        );
        flipped[id] = false;
        flipped[firstId] = false;
        setTimeout(
          removeCorrectCards,
          (removedCard = [...removedCard, id, firstId]),
          MATCH_ANIMATION_TIMER
        );
        setMatchedWord(salish);
        setChosenPopupWord(popUpWords[randomPopupNum].salish);
        setCorrectMatchWord(true);
        resetWordBox = setTimeout(() => setCorrectMatchWord(false), 3000);
      } else {
        let popUpWords = reduxState.popupWords.filter(
          (datum) => datum.type === "incorrect"
        );
        let randomPopupNum = Math.floor(Math.random() * popUpWords.length);
        // chosenPopupWord = popUpWords[randomPopupNum].salish;
        showPopup = true;
        setTimeout(() => (showPopup = false), 1300);
        flipped[id] = false;
        flipped[firstId] = false;
        setChosenPopupWord(popUpWords[randomPopupNum].salish);
      }
      if (removedCard.length !== positionArrayLength) {
        firstImg = null;
        firstId = null;
        setTimeoutState(false);
      }
    }
  };
  const _selectCard = (e, id, image, audio, salish) => {
    if (cardAudio !== undefined) {
      cardAudio.pause();
      cardAudio = undefined;
    }
    cardAudio = new Audio(`${fetchAudio(audio)}`);
    if (audio.length > 0) {
      cardAudio.play();
    }
    let flipped = { ...flippedCurrent };
    flipped[id] = true;
    if (!e.target.matches(".cardFlip-back")) {
      if (firstImg === null) {
        firstImg = image;
        firstId = id;
      } else if (firstId !== id) {
        setTimeoutState(true);
        setTimeout(checkCorrectness, 1000, e, id, image, flipped, salish);
      }
      flippedCurrent = flipped;
    }
  };
  const stopAudio = () => {
    if (cardAudio !== undefined) {
      cardAudio.pause();
      cardAudio = undefined;
    }
  };
  const setStatDisplay = () => {
    switchDisplayVar = true;
    shortTimer = 0;
    setTimeoutState(false);
  };
  const stepDifficulty = () => {
    switchDisplayVar = false;
    setDisplayedClock(0);
    if (switchData === 1) {
      difficulty = "medium";
      changeDisplay();
    } else if (switchData === 2) {
      difficulty = "hard";
      changeDisplay();
    } else if (switchData === 3) {
      props.checkWorld(overAllTime);
    }
  };
  if (
    background === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    background = `url(${fetchImage(reduxState.specificGame.backgroundImg)})`;
  }
  return (
    <div
      style={{ backgroundImage: background }}
      className="matchingGame-startScreen">
      <SwitchDisplay
        changeDisplay={changeDisplay}
        chosenPopupWord={chosenPopupWord}
        correctMatchWord={correctMatchWord}
        currentLevel={currentLevelRef.current}
        difficulty={reduxState.difficulty}
        displayedClock={displayedClockRef.current}
        editCardFlipArray={editCardFlipArray}
        endless={routeLocation.state.endless}
        matchedWord={matchedWord}
        setStatDisplay={setStatDisplay}
        stepDifficulty={stepDifficulty}
        timeoutState={timeoutState}
        updateLevelData={props.updateLevelData}
      />
      {switchDisplayVar === false || routeLocation.state.endless === true ? (
        <BottomStatBar>
          {routeLocation.state.endless === true ? (
            <LevelBar
              difficulty={difficulty}
              currentLevel={currentLevelRef.current}
              currentLevelProgress={currentLevelProgressRef.current}
            />
          ) : (
            <div className="MG-bottomBar">
              <div>
                {difficulty.charAt(0).toUpperCase() + difficulty.slice(1)}
              </div>
              <div>
                {reduxState.uiWords[15].salish}: {displayedClockRef.current}
              </div>
            </div>
          )}
        </BottomStatBar>
      ) : null}
    </div>
  );
}

export default MatchingGame;

MatchingGame.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
};
SwitchDisplay.propTypes = {
  changeDisplay: PropTypes.func,
  chosenPopupWord: PropTypes.string,
  correctMatchWord: PropTypes.bool,
  currentLevel: PropTypes.number,
  displayedClock: PropTypes.number,
  editCardFlipArray: PropTypes.func,
  endless: PropTypes.bool,
  matchedWord: PropTypes.string,
  setStatDisplay: PropTypes.func,
  stepDifficulty: PropTypes.func,
  timeoutState: PropTypes.bool,
  updateLevelData: PropTypes.func,
};

{/* Checked PropTypes */}