import "./index.css";
import React, {useRef} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";

const LevelBar = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  const reduxWidthRef = useRef();
  const timerWidthRef = useRef();
  reduxWidthRef.current = reduxState.width;
  timerWidthRef.current = reduxWidthRef.current / 3;
  let fillingBar = {
    width:
      (props.currentLevelProgress / (props.currentLevel + 2)) *
      timerWidthRef.current,
  };
  return (
    <div className="BottomStatBar-container">
      <div>
        {props.difficulty.charAt(0).toUpperCase() + props.difficulty.slice(1)}
      </div>
      <div className="endlessBarLevel">
        Level:
        <div id="levelNumber" className="endlessBarLevelNum">
          {props.currentLevel}
        </div>
      </div>
      <div
        className="levelBar-fullBar"
        style={{ width: timerWidthRef.current }}>
        <div className="levelBar-fillingBar" style={fillingBar} />
      </div>
    </div>
  );
};

export default LevelBar;

LevelBar.propTypes = {
  currentLevel: PropTypes.number,
  currentLevelProgress: PropTypes.number,
  difficulty: PropTypes.string,
};

{/* Checked PropTypes */}
