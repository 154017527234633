import React from "react";
// import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getLocation } from "../nonUIFuncs";

const UIButton = () => {
  return (
    <Button
      icon="angle left"
      className="backButton"
      circular
      size="large"
      color="linkedin"
    />
  );
}

const BackButton = () => {
  const routeLocation = useLocation();
  const ar = getLocation(routeLocation.pathname);
  return (
    routeLocation.state.endless ? (
      <Link to={{ pathname: `/KidsZone/EndlessMode/` }}>
        <UIButton />
      </Link>
    ) : (
      <Link to={{pathname: `/${ar[0]}/${ar[1]}/${ar[2]}/${ar[3]}/`}}>
        <UIButton />
      </Link>
    )
  );
};

export default BackButton;

// BackButton.propTypes = {};

{/* Checked PropTypes */}
