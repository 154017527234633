import React from "react";
import PropTypes from "prop-types";
import PlayAgain from "../PlayAgainScreen";
import NewStartScreen from "../NewStartScreen";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";

const DisplayScreen = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  if (reduxState.screenDisplay === 'startScreen') {
    return (
      <NewStartScreen
        historyGame={props.historyGame}
        setGame={props.startGame}
      />
    );
  } else if (reduxState.screenDisplay === 'playAgainScreen') {
    return (
      <PlayAgain
        clearStats={props.clearGameStats}
        scoreData={props.scoreData}
        scoreType={props.scoreType}
        time={props.time}
        updateScoreData={props.updateScoreData}
      />
    );
  } else if (reduxState.screenDisplay === 'gameScreen') {
    return props.gameComponent;
  }
};

export default DisplayScreen;

DisplayScreen.propTypes = {
  clearGameStats: PropTypes.func,
  gameComponent: PropTypes.element,
  historyGame: PropTypes.bool,
  scoreData: PropTypes.array,
  scoreType: PropTypes.string,
  startGame: PropTypes.func,
  time: PropTypes.number,
  updateScoreData: PropTypes.func,
};