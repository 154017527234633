import './index.css';
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import StatScreen from '../StatScreen';
import Timer from '../Timer';
import BottomStatBar from '../BottomStatBar/index';
import InvisiBar from '../Invisibar/index';
import { shuffle, placementArray, fetchAudio, fetchImage } from "../nonUIFuncs";
import PopupWord from '../Components/popupWord';
import FloatingNumber from '../Components/floatingNumber';
import LevelBar from "../Components/endlessLevelBar";
import EndEndlessGame from '../Components/endEndlessMode';
import CloseEndless from "../Components/endlessCloseButton";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

let displayArray = [];
let displayArrayDenom = [3, 3];
let currentTarget = {};
let imageArray = [];
let gameDOMArray = [];
let mouseClickX = 0;
let mouseClickY = 0;
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
let clickedAudio = null;
const fullTime = 40;
let testNum = 1;
let positionIndex = [];
let showPopup = false;
let currentLevelVar = 0;
let background = null;
let playButton = `url(${fetchImage("playButton.png")}`;
let playBackground = null;

const SwitchDisplay = props => {
  const [showEndlessEnd, setShowEndlessEnd] = useState();
  const reduxState = useSelector(selectReduxSlice);
  if (props.display === "stats") {
    if (props.currentTime === fullTime + 6) {
      props.stepDifficulty();
    }
    return (
      <StatScreen scoreType="hits" />
    );
  } else if (props.display === "game") {
    if (
      playBackground === null ||
      !playBackground.includes(reduxState.specificGame.playAudio)
    ) {
      playBackground = `url(${fetchImage(reduxState.specificGame.playAudio)})`;
    }
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      props.gameDifficultyDisplay();
    }
    let keywordImg = {
      backgroundImage: playBackground,
    };
    let innerImg = {
      backgroundImage: playButton,
    };
    let plusMinusContainer = {
      position: "absolute",
      top: mouseClickY - reduxState.height / 10,
      left: mouseClickX,
      zIndex: 99999,
      height: 2,
      width: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return (
      <div className="centerContentContainer">
        <div style={plusMinusContainer}>
          <FloatingNumber correctness={props.correctness} />
        </div>
        <div
          id="shakeBox"
          onClick={() => props.playAudio(currentTarget.audio1)}
          className="WG-keyWordBox shakeButton"
          style={keywordImg}>
          <div className="WG-innerImg" style={innerImg} />
        </div>
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        {gameDOMArray}
        {props.screenCover ? (
          <div
            className="timeoutDiv"
            onClick={() => props.setScreenCover(false)}
          />
        ) : null}
        <CloseEndless enabled={props.endless} onClick={setShowEndlessEnd} />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={props.currentLevel}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={props.updateLevelData}
        />
        <InvisiBar
          enabled={props.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
      </div>
    );
  } else {
    return null;
  }
};

const WordlessGame = props => {
  const dispatch = useDispatch();
  const routeLocation = useLocation();
  const reduxState = useSelector(selectReduxSlice);

  const currentTimeRef = useRef();
  const difficultyRef = useRef();
  const currentLevelRef = useRef();
  const currentLevelProgressRef = useRef();
  const reduxStateRef = useRef();

  const [correctness, setCorrectness] = useState(null);
  const [firstTime, setFirstTime] = useState(true);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [screenCover, setScreenCover] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [display, setDisplay] = useState('game');
  const [difficulty, setDifficulty] = useState('easy');
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);

  currentTimeRef.current = currentTime;
  difficultyRef.current = difficulty;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;

  useEffect(() => {
    let timer = setInterval(updateDisplayClock, 1000);
    setCurrentLevel(0);
    currentLevelVar = 0;
    setCurrentLevelProgress(0);
    setDisplay('game');
    setDifficulty(reduxState.difficulty);
    createAudio();
    return ()=> {
      setDisplay('game');
      setDifficulty('easy');
      clearInterval(timer);
      setCurrentTime(0);
      gameDOMArray = [];
    };
  }, []);
  useEffect(() => {
    if (reduxState.sortedData && firstTime === true) {
      changeDisplay(difficultyRef.current);
      setFirstTime(false);
    }
  }, [reduxState.sortedData, firstTime]);
  const updateDisplayClock = () => {
    if (!routeLocation.state.endless) {
      setCurrentTime(currentTimeRef.current + 1);
    }
  };
  const changeDisplay = (dif) => {
      positionIndex = [];
      let previousTarget = currentTarget;
      setScreenCover(false);
      imageArray = [];
      let shuffled = shuffle(reduxState.sortedData);
      currentTarget = shuffled[0];
      if (previousTarget !== undefined) {
        while (previousTarget.english === currentTarget.english) {
          shuffled = shuffle(reduxState.sortedData);
          currentTarget = shuffled[0];
        }
      }
      playAudio(currentTarget.audio1);
      imageArray.push(currentTarget);
      let distractorAmount = 1;
      let fullDistractorArray = shuffled.filter(
        (datum) => datum.english !== currentTarget.english
      );
      if (dif === "easy") {
        displayArray = placementArray(1, 2, 3, 4, 1, 1);
        displayArrayDenom = [3, 4];
      } else if (dif === "medium") {
        displayArray = placementArray(1, 2, 4, 4, 1, 1);
        displayArrayDenom = [4, 4];
      } else if (dif === "hard") {
        displayArray = placementArray(2, 1.75, 5, 4, 1, 1);
        displayArrayDenom = [6, 5];
      }
      distractorAmount = displayArray.length - 1;
      let slicedDistractorArray = fullDistractorArray.slice(
        0,
        distractorAmount
      );
      slicedDistractorArray.map((datum) => imageArray.push(datum));
      imageArray = shuffle(imageArray);
      gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(() => number.classList.remove("levelNumber"), 1200);
  };
  const checkCorrectness = (e) => {
    let clickedObject = gameDOMArray.filter(
      (DOMElement) => DOMElement.props.id === Number(e.target.id)
    );
    let audioFile = reduxState.sortedData.find(
      (datum) => datum.salish === clickedObject[0].props.salish
    ).audio1;
    mouseClickX = e.pageX;
    mouseClickY = e.pageY;
    playAudio(audioFile);
    let clickedObjectImg =
      clickedObject[0].props.children.props.style.backgroundImage;
    let targetImgPath = `url(${fetchImage(currentTarget.image1)})`;
    if (targetImgPath === clickedObjectImg) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setTimeout(() => changeDisplay(difficultyRef.current), 2000);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (
        currentLevelRef.current + 2 === progress &&
        routeLocation.state.endless === true
      ) {
        setTimeout(nextEndlessLevel, 700);
      }
      setScreenCover(true);
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
      setTimeout(() => setScreenCover(false), 2000);
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      clickedAudio.onended = () => playAudio(currentTarget.audio1);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current -1);
      }
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('wrong');
    }
    setTimeout(() => setCorrectness(null), 1000);
    let removedElement = gameDOMArray.find((DOMElement, index) => {
      if (DOMElement.props.id === Number(e.target.id)) {
        let newIndex = index;
        positionIndex.map((position) => {
          if (position === newIndex || positionIndex.length < newIndex) {
            newIndex = index + 1;
          }
        });
        positionIndex.push(newIndex);
      }
      return DOMElement.props.id === Number(e.target.id);
    });
    gameDOMArray = gameDOMArray.filter(
      (DOMElement) => DOMElement.props.id !== Number(e.target.id)
    );
    imageArray = imageArray.filter((datum) => {
      return datum.salish !== removedElement.props.salish;
    });
  };
  const stepDifficulty = () => {
    setDisplay('game');
    setCurrentTime(0);
    if (difficultyRef.current === "easy") {
      setDifficulty('medium');
      changeDisplay('medium');
    } else if (difficultyRef.current === "medium") {
      setDifficulty('hard');
      changeDisplay('hard');
    } else if (difficultyRef.current === "hard") {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    imageArray.map((datum) => {
      let imgDimm = reduxStateRef.current.width / 6;
      let buffer = 15;
      if (reduxStateRef.current.width > reduxStateRef.current.height) {
        imgDimm = reduxStateRef.current.height / 6;
      }
      if (imgDimm < 50) {
        imgDimm = 50;
      } else if (imgDimm > 375) {
        imgDimm = 375;
      }
      positionIndex.map((position) => {
        while (key === position) {
          key = position + 1;
        }
      });
      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let divStyle = {
          borderRadius: 10,
          backgroundColor: "rgba(49,181,255,1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
          width: imgDimm + buffer,
          height: imgDimm + buffer,
          borderColor: "black",
          borderWidth: 3,
          borderStyle: "solid",
        };
        let contentStyle = {
          backgroundImage: `url(${fetchImage(datum.image1)})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: imgDimm,
          height: imgDimm,
        };
        gameDOMArray.push(
          <div
            key={key}
            salish={datum.salish}
            displayarraydenom={displayArrayDenom}
            imgdimm={imgDimm}
            buffer={buffer}
            xycoord={xyCoord}
            id={key}
            style={divStyle}
          >
            <div
              id={key}
              style={contentStyle}
              audio={datum.audio1}
              onClick={checkCorrectness}
            />
          </div>
        );
      }
    });
  };
  const playAudio = (file) => {
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
      clickedAudio = null;
    }
    clickedAudio = new Audio(fetchAudio(file));
    clickedAudio.play();
  };
  const createAudio = () => {
    let distractorArrayAudio = reduxState.sortedData.map((distractor) => {
      if (distractor.audio1.length === 0) {
        return;
      }
      return (
        <audio
          id={distractor.audio1}
          key={distractor.audio1}
          src={`${fetchAudio(distractor.audio1)}`}
          type="audio/mpeg"
        />
      );
    });
    return distractorArrayAudio;
  };
  if (
    background === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    background = `url(${fetchImage(reduxState.specificGame.backgroundImg)})`;
  }
  console.log(background);
  return (
    <div style={{ backgroundImage: background }} className="fullScreen">
      <SwitchDisplay
        chosenPopupWord={chosenPopupWord}
        correctness={correctness}
        currentLevel={currentLevelRef.current}
        currentTime={currentTimeRef.current}
        display={display}
        endless={routeLocation.state.endless}
        gameDifficultyDisplay={gameDifficultyDisplay}
        playAudio={playAudio}
        screenCover={screenCover}
        setScreenCover={setScreenCover}
        specificGame={reduxState.specificGame}
        stepDifficulty={stepDifficulty}
        updateLevelData={props.updateLevelData}
      />
      {display === "game" || routeLocation.state.endless === true ? (
        <BottomStatBar>
          {routeLocation.state.endless === true ? (
            <LevelBar
              difficulty={difficultyRef.current}
              currentLevel={currentLevelRef.current}
              currentLevelProgress={currentLevelProgressRef.current}
            />
          ) : (
            <div className="WG-bottomBar">
              <div>
                {
                  difficultyRef.current.charAt(0).toUpperCase() +
                  difficultyRef.current.slice(1)
                }
              </div>
              <div className="WG-BSB-text">
                {reduxState.uiWords[13].salish}:{" "}
                {reduxStateRef.current.stats.roundStats.score}
              </div>
              {routeLocation.state.endless === true ? null : (
                <Timer
                  currentTime={currentTimeRef.current}
                  displayStats={() => setDisplay("stats")}
                  fullTime={fullTime}
                />
              )}
            </div>
          )}
        </BottomStatBar>
      ) : null}
    </div>
  );
}

export default WordlessGame;

WordlessGame.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
};

SwitchDisplay.propTypes = {
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  currentLevel: PropTypes.number,
  currentTime: PropTypes.number,
  display: PropTypes.string,
  endless: PropTypes.bool,
  gameDifficultyDisplay: PropTypes.func,
  playAudio: PropTypes.func,
  screenCover: PropTypes.bool,
  setScreenCover: PropTypes.func,
  stepDifficulty: PropTypes.func,
  updateLevelData: PropTypes.func,
};

{/* Checked PropTypes */}