import "./index.css";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";
import { fetchImage, fetchAudio } from "../nonUIFuncs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
// import PropTypes from "prop-types";

const Dictionary = () => {
  const reduxState = useSelector(selectReduxSlice);
  const categoriesRef = useRef([]);
  const reduxStateRef = useRef(reduxState);
  const [ categories, setCategories ] = useState([]);
  const [ expandedCat, setExpandedCat ] = useState(null);
  const [ expandedWord, setExpandedWord ] = useState(null);
  const [ displayCategory, setDisplayCategory ] = useState([]);
  const [ audio, setAudio ] = useState(null);
  categoriesRef.current = categories;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    initializeDisplay();
  }, [reduxState.gameData]);

  const initializeDisplay = () => {
    let tempCats = [];
    reduxState.gameData.forEach((d) => {
      if (!tempCats.includes(d.homeName)) {
        tempCats.push(d.homeName);
      }
    });
    setCategories(tempCats);
  };

  const setWordData = (category) => {
    setExpandedCat(category);
    let noRepeat = [];
    let wordsToDis = [];
    let firstFilter = reduxState.gameData.filter((d) => d.homeName === category);
    firstFilter.forEach((d) => {
      if (!noRepeat.includes(d.english)) {
        noRepeat.push(d.english);
        wordsToDis.push(d);
      }
    })
    setDisplayCategory(wordsToDis);
  };

  return (
    <div className="Dict-fullscreen-container">
      <div className="Dict-content-container">
        <div
          className={
            reduxState.width > 550
              ? "Dict-content-left"
              : "Dict-content-container"
          }
        >
          <div className="Dict-content-left-inner">
            {categoriesRef.current.map((d) => {
              return (
                <div
                  onClick={() => setWordData(d)}
                  className={
                    expandedCat === d
                      ? "Dict-category-selected"
                      : "Dict-category"
                  }
                  key={d}
                >
                  <div className="Dict-category-title">{d}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="Dict-content-right">
          <div className="Dict-content-right-inner">
            <div className="Dict-right-title">
              {expandedCat === null
                ? "Select a Category to the left"
                : expandedCat}
            </div>
            <div
              style={{
                columnCount: reduxState.width > 550 ? 2 : 1,
                breakInside: "avoid-column",
              }}
            >
              {displayCategory.map((d) => {
                return (
                  <div key={d.english} className="Dict-word-expanded-container">
                    <div
                      className="Dict-word-button"
                      onClick={() => {
                        setAudio(new Audio(`${fetchAudio(d.audio1)}`));
                        setExpandedWord(
                          expandedWord === d.english ? null : d.english
                        );
                      }}
                    >
                      <div className="Dict-word-button-title-container">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div className="Dict-word-button-title">
                            {d.salish}
                          </div>
                          <div className="Dict-word-button-subtitle">
                            {d.english}
                          </div>
                        </div>
                        <FontAwesomeIcon
                          icon={
                            expandedWord === d.english
                              ? faChevronDown
                              : faChevronRight
                          }
                          color="white"
                        />
                      </div>
                    </div>
                    {expandedWord === d.english ? (
                      <div className="Dict-word-button-expanded-content">
                        <img
                          style={{ width: 200, height: 200 }}
                          src={fetchImage(d.image1)}
                        />
                        <div
                          style={{
                            width: 250,
                            height: 50,
                            backgroundColor: "rgba(23, 118, 35, 1.0)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            borderRadius: 10,
                          }}
                          onClick={() => audio.play()}
                        >
                          <FontAwesomeIcon icon={faPlay} size="3x" />
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dictionary;

// Dictionary.propTypes = {
//   children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
// };
