import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import WordToImageGame from "../WordToImageGame";
import DisplayScreen from '../Components/displayScreen';
import BackButton from "../Components/backButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setInitialGameData,
  getScreen,
  selectReduxSlice,
} from "../../Store/store";

let gameTime = 0;

const WordToImage = props => {
  const routeLocation = useLocation();
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const scoreType = 'timeAndHits';
  useEffect(() => {
    dispatch(
      setInitialGameData(
        routeLocation,
        reduxState.data,
        reduxState.gameData,
        reduxState.storybookVocab,
        "Word To Image"
      )
    );
  }, []);
  const checkWorld = (time) => {
    gameTime = time;
    dispatch(getScreen('playAgainScreen'));
  };
  let gameComponent = (
    <WordToImageGame
      checkWorld={checkWorld}
      scoreType={scoreType}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={() => dispatch(getScreen("startScreen"))}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={() => dispatch(getScreen("gameScreen"))}
        time={gameTime}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default WordToImage;

WordToImage.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}