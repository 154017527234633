import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import FillInTheBlankGame from "../FillInTheBlankGame";
import DisplayScreen from "../Components/displayScreen";
import BackButton from "../Components/backButton";

import { getLocation } from '../nonUIFuncs';
import { useDispatch, useSelector } from 'react-redux';
import { getScreen, setSpecificGame, selectReduxSlice } from '../../Store/store';

let gameTime = 0;

const FillInTheBlank = props => {
  let routeLocation = useLocation();
  let locationArr = getLocation(routeLocation.pathname);
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const gameClockRef = useRef();
  const [gameClock, setGameClock] = useState(0);
  const scoreType = 'normal';
  const difficulty = 'easy';
  gameClockRef.current = gameClock;
  useEffect(() => {
    let gameTimer = setInterval(
      () => setGameClock(gameClockRef.current + 1),
      1000
    );
    let specificGame = reduxState.data.find(
      (game) => game.id == Number(locationArr[4])
    );
    document.title = `Fill in the Blank - ${specificGame.category} - Kids Zone`;
    dispatch(getScreen('startScreen'));
    dispatch(setSpecificGame(specificGame));
    return () => {
      clearInterval(gameTimer);
    };
  }, []);
  const startGame = () => {
    setGameClock(0);
    dispatch(getScreen('gameScreen'));
  };
  const clearGameStats = () => {
    setGameClock(0);
    dispatch(getScreen('startScreen'));
  };
  const checkWorld = () => {
    dispatch(getScreen('playAgainScreen'));
    gameTime = gameClock - 1;
  };
  let gameComponent = (
    <FillInTheBlankGame
      checkWorld={checkWorld}
      difficulty={difficulty}
      time={gameClockRef.current}
    />
  );
  return (
    <div className="fullscreenNoOverflow">
      <BackButton />
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={startGame}
        time={gameTime}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default FillInTheBlank;

FillInTheBlank.propTypes = {
  scoreData: PropTypes.array,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}