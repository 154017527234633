import "./index.css";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import GameSelectButton from "../GameSelectButton";
import { fetchImage } from "../nonUIFuncs";
import { selectReduxSlice } from "../../Store/store";
import { useSelector } from 'react-redux';

let catButtonDOM = [];

const StoryFilteredData = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  let storyData = reduxState.data.filter((datum) =>
    datum.category.includes("Story")
  );
  let categoryData = storyData.map((item) => item.category);
  let uniqueCategoryData = categoryData.filter(
    (item, index) => categoryData.indexOf(item) >= index
  );
  catButtonDOM = uniqueCategoryData.map((catButton) => {
    let currentData = storyData.filter((cat) => cat.category === catButton);
    let starsFound = 0;
    let allStars = 0;
    let filteredScoreData = props.scoreData.filter(
      (datum) => datum.category === catButton
    );
    let gameNameArr = [];
    filteredScoreData.map((datum) => {
      if (
        !gameNameArr.includes(datum.gameName) &&
        datum.scoreType !== "story"
      ) {
        starsFound = starsFound + datum.stars;
        allStars = allStars + 5;
        let test = reduxState.goldStar.find((game) => game.game === datum.gameType);
        if (test.secondType !== "none") {
          allStars = allStars + 5;
        }
        gameNameArr.push(datum.gameName);
      }
    });
    return (
      <div className="home-gameButtonContainer" key={catButton}>
        <Link to={{pathname: `/KidsZone/Stories/${catButton}`}}>
          <GameSelectButton
            allData={storyData}
            buttonBackground={fetchImage(currentData[0].backgroundImg)}
            parent="gameButtons"
            uniqueCategoryName={catButton}
          />
        </Link>
        <div className="home-starContainer">
          <img src={`${fetchImage("goldStar.png")}`} className="home-stars" />
          <div className="home-starCount">
            {" "}
            {starsFound}/{allStars}
          </div>
        </div>
      </div>
    );
  });
  return catButtonDOM;
};

const StoryMenu = (props) => {
  useEffect(() => {
    document.title = "Kids Zone - Stories";
  }, []);
  return (
    <div className="home-fullscreen">
      <div className="home-centerContent">
        <StoryFilteredData scoreData={props.scoreData} />
      </div>
    </div>
  );
};

export default StoryMenu;

StoryMenu.propTypes = {
  scoreData: PropTypes.array,
};

StoryFilteredData.propTypes = {
  scoreData: PropTypes.array,
};

{/* Checked PropTypes */}
