import 'semantic-ui-css/semantic.min.css';
import './App.css';
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ReactGA from 'react-ga4';

import TopTabBar from './Components/TopTabBar';
import AppRouter from './Components/Navigation';
import { setWidth, setHeight } from "./Store/store";

ReactGA.initialize("G-ZJYGHY6NP6");
let scoreDataLocal = [];
let levelDataLocal = [];
const App = props => {
  const dispatch = useDispatch();
  const [scoreData, setScoreData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  
  useEffect(() => {
    const handleResize = () => {
      dispatch(setWidth(window.innerWidth));
      dispatch(setHeight(window.innerHeight));
    };
    window.addEventListener("resize", handleResize);
    if (localStorage.ScoreData.length <= 2) {
      setScoreData(filterScoreData(props.scoreData));
    }
    if (localStorage.LevelData.length <= 2) {
      setLevelData(props.levelData)
    }
    return () => {
      window.removeEventListener("resize", handleResize)
    };
  }, []);
  const filterScoreData = data => {
    let ids = [];
    let filtered = [];
    data.map((d) => {
      if (!ids.includes(d.id)) {
        ids.push(d.id);
        filtered.push(d);
      }
    });
    return filtered;
  };
  const updateScoreData = (newData) => {
    scoreDataLocal = scoreData;
    let newScoreData = scoreDataLocal.map((game) =>
      newData.id === game.id
        ? {
            ...game,
            score: newData.score,
            time: newData.time,
            correct: newData.correct,
            incorrect: newData.incorrect,
            firstStars: newData.firstStars,
            secondStars: newData.secondStars,
            stars: newData.stars,
            firstNextGoals: newData.firstNextGoals,
            secondNextGoals: newData.secondNextGoals,
          }
        : game
    );
    setScoreData(newScoreData);
  };
  const updateLevelData = (data) => {
    levelDataLocal = levelData;
    let categoryLength = data.category.length;
    let newLevelData = levelDataLocal.map((game) => {
      let gameCategory = game.category;
      if (typeof gameCategory !== 'string') {
        gameCategory = game.category[0];
      }
      if (
        (game.gameType === `${categoryLength} Categories` &&
          data.spreadGameType === game.spreadGameType) ||
        (data.gameType === game.gameType && data.category[0] === gameCategory)
      ) {
        return {
          ...game,
          category: data.category,
          levelEasy:
            data.difficulty === "easy" && data.newLevel > game.levelEasy
              ? data.newLevel
              : game.levelEasy,
          categoryEasy:
            data.difficulty === "easy" && data.newLevel > game.levelEasy
              ? data.category
              : game.categoryEasy,
          levelMedium:
            data.difficulty === "medium" && data.newLevel > game.levelMedium
              ? data.newLevel
              : game.levelMedium,
          categoryMedium:
            data.difficulty === "medium" && data.newLevel > game.levelMedium
              ? data.category
              : game.categoryMedium,
          levelHard:
            data.difficulty === "hard" && data.newLevel > game.levelHard
              ? data.newLevel
              : game.levelHard,
          categoryHard:
            data.difficulty === "hard" && data.newLevel > game.levelHard
              ? data.category
              : game.categoryHard,
        };
      } else {
        return game;
      }
    });
    setLevelData(newLevelData);
  };
  window.onbeforeunload = props.saveData(scoreData, levelData);
  return (
    <Router className="center">
      <TopTabBar />
      <div className='centerContentContainer'>
        <AppRouter
          levelData={levelData}
          updateLevelData={updateLevelData}
          scoreData={scoreData}
          updateScoreData={updateScoreData}
          className="noselect"
        />
      </div>
    </Router>
  );
}

export default App

App.propTypes = {
  GoldStar: PropTypes.array,
  GameData: PropTypes.array,
  FilteredData: PropTypes.array,
  StorySentences: PropTypes.array,
  StoryVocab: PropTypes.array,
  PopupWords: PropTypes.array,
  UIWords: PropTypes.array,
  HistoryData: PropTypes.array,
  scoreData: PropTypes.array,
  levelData: PropTypes.array,
  saveData: PropTypes.func,
};
