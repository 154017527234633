import './index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from "react-router-dom";
import CompareScores from './CompareScores';
import {fetchImage, getLocation} from '../nonUIFuncs';
import { useSelector } from 'react-redux';
import { selectReduxSlice } from '../../Store/store';

let bannerImage = null;
let buttonImage = null;
let background = null;

const HistoryDisplayScore = props => {
  const reduxState = useSelector(selectReduxSlice);
  const stats = reduxState.stats;
  return (
    <div className="PA-historyFullScreen">
      <div className="noselect PA-scoreContainer">
        <div className="PA-hitsMissesContainer">
          <div className="PA-scoreText PA-scoreFont">
            {reduxState.uiWords[5].salish}: {stats.hits}
          </div>
          <div className="PA-scoreText PA-scoreFont">
            {reduxState.uiWords[6].salish}: {stats.misses}
          </div>
        </div>
        <div className="PA-scoreText PA-scoreFont">
          {reduxState.uiWords[13].salish}: {stats.score}
        </div>
        <div className="PA-timeFont PA-scoreText">
          {reduxState.uiWords[15].salish}: {props.time}
        </div>
      </div>
      <div className="PA-buttonContainer">
        <Link
          onClick={props.clearStats}
          to={{ pathname: "/KidsZone/HistoryGame/" }}>
          <div className="PA-scoreText hoverCursor PA-homeFont">
            {reduxState.uiWords[12].salish}
          </div>
        </Link>
      </div>
    </div>
  );
};

const DisplayScore = props => {
  const reduxState = useSelector(selectReduxSlice);
  if (
    bannerImage === null ||
    !bannerImage.includes(reduxState.specificGame.finalScoreImg)
  ) {
    bannerImage = `${fetchImage(reduxState.specificGame.finalScoreImg)}`;
  }
  let bannerStyle = {
    backgroundImage: `url(${bannerImage})`,
  };
  if (props.scoreType === "time") {
    return (
      <div className="playAgain-score noselect" style={bannerStyle}>
        <div className="PA-timeText">
          {reduxState.uiWords[15].salish}: {props.time}
        </div>
      </div>
    );
  } else if (props.scoreType === "timeAndHits") {
    return (
      <div className="playAgain-score noselect" style={bannerStyle}>
        <div className="PA-timeText">
          {reduxState.uiWords[15].salish}: {props.time}
        </div>
        <div className="playAgain-scoreInnerWrapper">
          <div className="PA-textStyleTop">
            {reduxState.uiWords[7].salish}: {reduxState.stats.hits}
          </div>
          <div className="PA-textStyleTop">
            {reduxState.uiWords[8].salish}: {reduxState.stats.misses}
          </div>
        </div>
        <div className="noselect PA-textStyleBottom">
          {reduxState.uiWords[9].salish}: {reduxState.stats.score}
        </div>
      </div>
    );
  } else if (props.scoreType === "story") {
    return null;
  } else {
    return (
      <div className="playAgain-score noselect" style={bannerStyle}>
        <div className="playAgain-scoreInnerWrapper">
          <div className="PA-textStyleTop">
            {reduxState.uiWords[7].salish}: {reduxState.stats.hits}
          </div>
          <div className="PA-textStyleTop">
            {reduxState.uiWords[8].salish}: {reduxState.stats.misses}
          </div>
        </div>
        <div className="noselect PA-textStyleBottom">
          {reduxState.uiWords[9].salish}: {reduxState.stats.score}
        </div>
      </div>
    );
  }
};

const BottomButtons = props => {
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  let locationArr = getLocation(routeLocation.pathname);
  const gameID = Number(locationArr[4]);
  let game = props.scoreData.find((datum) => datum.id === gameID);
  if (
    buttonImage === null ||
    !buttonImage.includes(reduxState.specificGame.playButton)
  ) {
    buttonImage = `${fetchImage(reduxState.specificGame.playButton)}`;
  }
  if (
    background === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    background = `${fetchImage(reduxState.specificGame.backgroundImg)}`;
  }
  let buttonStyle = {
    backgroundImage: `url(${buttonImage})`,
  };
  let languageCategory = reduxState.data.find(
    (datum) => datum.category === reduxState.specificGame.category
  ).categorySalish;
  if (languageCategory.length === 0) {
    languageCategory = reduxState.data.find(
      (datum) => datum.category === reduxState.specificGame.category
    ).category;
  }
  let fullScreen = {
    backgroundImage: `url(${background})`,
  };

  if (reduxState.width > reduxState.height) {
    return (
      <div className="playAgain-fullScreen" style={fullScreen}>
        {props.compareScores &&
        props.scoreData !== undefined &&
        game.scoreType !== "story" ? (
          <CompareScores
            gameID={gameID}
            hideCompare={props.hideCompareScores}
            scoreData={props.scoreData}
            time={props.time}
            updateScoreData={props.updateScoreData}
          />
        ) : null}
        <div className="PA-scoreDisplayContainer">
          <DisplayScore scoreType={props.scoreType} time={props.time} />
        </div>
        <div className="PA-buttonContainer">
          <Link to={{ pathname: "/KidsZone/Home/" }}>
            <div
              className="PA-button"
              onClick={props.clearStats}
              style={buttonStyle}>
              <div className="noselect PA-buttonText">
                {reduxState.uiWords[10].salish}
              </div>
            </div>
          </Link>
          {/* <Link to={{ pathname: `${routeLocation.pathname}` }}> */}
          {/* TODO: Might have to put this back in and add the state prop */}
            <div
              className="PA-button"
              onClick={props.clearStats}
              style={buttonStyle}>
              <div className="noselect PA-buttonText">
                {reduxState.uiWords[12].salish}
              </div>
            </div>
          {/* </Link> */}
          <Link
            to={{
              pathname: `/${locationArr[0]}/${locationArr[1]}/${locationArr[2]}/`,
            }}>
            <div
              className="PA-button"
              onClick={props.clearStats}
              style={buttonStyle}>
              <div className="noselect PA-buttonText">{languageCategory}</div>
            </div>
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="playAgain-fullScreen" style={fullScreen}>
        {props.compareScores &&
        props.scoreData !== undefined &&
        game.scoreType !== "story" ? (
          <CompareScores
            gameID={gameID}
            hideCompare={props.hideCompareScores}
            scoreData={props.scoreData}
            time={props.time}
            updateScoreData={props.updateScoreData}
          />
        ) : null}
        <div className="PA-scoreDisplayContainerTall">
          <DisplayScore scoreType={props.scoreType} time={props.time} />
        </div>
        <div>
          <Link to={{ pathname: `${routeLocation.pathname}` }}>
            <div
              className="PA-button"
              onClick={props.clearStats}
              style={buttonStyle}>
              <div className="noselect PA-buttonText">
                {reduxState.uiWords[12].salish}
              </div>
            </div>
          </Link>
        </div>
        <div className="PA-buttonContainerTall">
          <Link to={{ pathname: "/KidsZone/Home/" }}>
            <div
              className="PA-button"
              onClick={props.clearStats}
              style={buttonStyle}>
              <div className="noselect PA-buttonText">
                {reduxState.uiWords[10].salish}
              </div>
            </div>
          </Link>
          <Link
            to={{
              pathname: `/${locationArr[0]}/${locationArr[1]}/${locationArr[2]}/`,
            }}>
            <div
              className="PA-button"
              onClick={props.clearStats}
              style={buttonStyle}>
              <div className="noselect PA-buttonText">{languageCategory}</div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
};

const PlayAgain = props => {
  const reduxState = useSelector(selectReduxSlice);
  const [compareScores, setCompareScores] = useState(true);
  useEffect(() => {
    setCompareScores(true);
  }, []);
  return (
    <div>
      {reduxState.specificGame.category === "History" ? (
        <div className="PA-historyFullScreen">
          <HistoryDisplayScore
            clearStats={props.clearStats}
            time={props.time}
          />
        </div>
      ) : (
        <BottomButtons
          clearStats={props.clearStats}
          compareScores={compareScores}
          hideCompareScores={() => setCompareScores(false)}
          scoreData={props.scoreData}
          scoreType={props.scoreType}
          time={props.time}
          updateScoreData={props.updateScoreData}
        />
      )}
    </div>
  );
}

export default PlayAgain;

PlayAgain.propTypes = {
  clearStats: PropTypes.func,
  scoreData: PropTypes.array,
  scoreType: PropTypes.string,
  time: PropTypes.number,
  updateScoreData: PropTypes.func,
};

HistoryDisplayScore.propTypes = {
  clearStats: PropTypes.func,
  time: PropTypes.number,
};

BottomButtons.propTypes = {
  clearStats: PropTypes.func,
  compareScores: PropTypes.bool,
  hideCompareScores: PropTypes.func,
  scoreData: PropTypes.array,
  scoreType: PropTypes.string,
  time: PropTypes.number,
  updateScoreData: PropTypes.func,
};

DisplayScore.propTypes = {
  scoreType: PropTypes.string,
  time: PropTypes.number,
};

{/* Checked PropTypes */}