import './index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {fetchImage} from '../nonUIFuncs';
import { useSelector, useDispatch } from 'react-redux';
import { selectReduxSlice, clearStats } from '../../Store/store';

const NewStartScreen = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const [background, setBackground] = useState(null);
  const [playButtonImg, setplayButtonImg] = useState(null);
  useEffect(() => {
    if (reduxState.specificGame !== undefined) {
      collectImageData();
    }
  } ,[reduxState.specificGame]);
  const collectImageData = () => {
    if (!props.historyGame) {
      setBackground(`${fetchImage(reduxState.specificGame.backgroundImg)}`);
      setplayButtonImg(`${fetchImage(reduxState.specificGame.playButton)}`);
    }
  };
  if (props.historyGame) {
    return (
      <div className="NSS-history-fullscreen">
        <div className="NSSH-center">
          <div
            className="newStartScreen-button noselect NSS-playButton"
            onClick={() => {
              props.setGame();
              dispatch(clearStats());
            }}>
            <div className="NSS-buttonText">{reduxState.uiWords[11].salish}</div>
          </div>
          <div className="NSSH-credits">
            history data compiled by Séliš-Ql̓ispé Culture Committee
          </div>
        </div>
      </div>
    );
  } else {
    let fullScreenBackground = {
      backgroundImage: `url(${background})`,
    };
    let playButton = {
      backgroundImage: `url(${playButtonImg})`,
    };
      return (
        <div
          style={fullScreenBackground}
          onLoad={collectImageData}
          className="noselect NSS-fullScreen">
          <div
            style={playButton}
            className="newStartScreen-button noselect NSS-playButton"
            onClick={() => {
              props.setGame();
              dispatch(clearStats());
            }}>
            <div className="NSS-buttonText">{reduxState.uiWords[11].salish}</div>
          </div>
        </div>
      );
  }
}

export default NewStartScreen;

NewStartScreen.propTypes = {
  historyGame: PropTypes.bool,
  setGame: PropTypes.func,
  uiWords: PropTypes.array,
};

{/* Checked PropTypes */}