import "./index.css";
import React from "react";
import { PropTypes } from "prop-types";
import { Icon } from "semantic-ui-react";

const CloseEndless = (props) => {
  if (props.enabled) {
    return (
      <div
        onClick={() => props.onClick(true)}
        className="endlessModeClose-button">
        <Icon fitted className="endlessModeClose-icon" name="close" />
      </div>
    );
  } else {
    return null;
  }
};

export default CloseEndless;

CloseEndless.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
};
