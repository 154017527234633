import "./index.css";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";

const DisplayTime = props => {
  if (props.currentTime > 0) {
    return <div className="timer-text">{props.currentTime}</div>;
  } else {
    return null;
  }
};

const Timer = props => {
  const reduxState = useSelector(selectReduxSlice);
  let timerWidth = reduxState.width/4;
  let fillingBar = {
    width: (props.currentTime / props.fullTime) * (timerWidth) || 1
  };
  if(props.currentTime > props.fullTime && props.displayStats !== undefined) {
    if(props.drag === undefined || props.drag === false) {
      props.displayStats();
    }
  }
  return (
    <div className="timer-fullBar" style={{width: timerWidth}}>
      <div className="timer-fillingBar" style={fillingBar}>
        <DisplayTime currentTime={props.currentTime} />
      </div>
    </div>
  );
}

export default Timer;

Timer.propTypes = {
  currentTime: PropTypes.number,
  displayStats: PropTypes.func,
  drag: PropTypes.bool,
  fullTime: PropTypes.number.isRequired,
};
DisplayTime.propTypes = {
  currentTime: PropTypes.number,
};

{/* Checked PropTypes */}