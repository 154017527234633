import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import DisplayScreen from "../Components/displayScreen";
import MatchingGame from "../MatchingGame";
import BackButton from "../Components/backButton";
import { getScreen, setInitialGameData, selectReduxSlice } from '../../Store/store';
import { useDispatch, useSelector } from 'react-redux';

let score = 0;
let endTime = 0;
let gameTime = 0;

const Matching = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const scoreType = 'time';
  useEffect(() => {
    dispatch(setInitialGameData(
      routeLocation,
      reduxState.data,
      reduxState.gameData,
      reduxState.storybookVocab,
      "Matching"
    ));
  }, []);
  const clearGameStats = () => {
    gameTime = 0;
    dispatch(getScreen('startScreen'));
  };
  const checkWorld = endTime => {
    gameTime = endTime;
    dispatch(getScreen('playAgainScreen'));
  };
  let gameComponent = (
    <MatchingGame
      checkWorld={checkWorld.bind(endTime)}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        score={score}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={() => dispatch(getScreen("gameScreen"))}
        time={gameTime}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default Matching;

Matching.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}