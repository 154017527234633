import "./index.css";
import React from 'react';
import PropTypes from 'prop-types';
import {fetchImage} from '../../nonUIFuncs';
let grey = `${fetchImage("grayStar.png")}`;
let gold = `${fetchImage("goldStar.png")}`;

const GoldStar = () => <img src={gold} className="SD-star" />;
const GrayStar = () => <img src={grey} className="SD-star" />;

const FiveStar = () => {
  return(
    <div className="SD-subContainer">
      <GoldStar />
      <GoldStar />
      <GoldStar />
      <GoldStar />
      <GoldStar />
    </div>
  );
}
const FourStar = () => {
  return (
    <div className="SD-subContainer">
      <GoldStar />
      <GoldStar />
      <GoldStar />
      <GoldStar />
      <GrayStar />
    </div>
  );
}
const ThreeStar = () => {
  return (
    <div className="SD-subContainer">
      <GoldStar />
      <GoldStar />
      <GoldStar />
      <GrayStar />
      <GrayStar />
    </div>
  );
}
const TwoStar = () => {
  return (
    <div className="SD-subContainer">
      <GoldStar />
      <GoldStar />
      <GrayStar />
      <GrayStar />
      <GrayStar />
    </div>
  );
}
const OneStar = () => {
    return (
      <div className="SD-subContainer">
        <GoldStar />
        <GrayStar />
        <GrayStar />
        <GrayStar />
        <GrayStar />
      </div>
    );
}
const NoStar = () => {
  return (
    <div className="SD-subContainer">
      <GrayStar />
      <GrayStar />
      <GrayStar />
      <GrayStar />
      <GrayStar />
    </div>
  );
}
const StarDisplay = props => {
  let gameInfo = props.goldStar.find(game => game.game === props.score.gameType);
  let starComponent;
  if (props.set === 'firstSet') {
    let textOne;
    let firstGoals;
    let stars = 'stars!'
    if (props.score.firstStars === 0) {
      // If the player still needs to earn their first star make 'stars' singular.
      stars = "star!";
    }
    if (gameInfo.type === "correct") {
      firstGoals = `Score ${
        props.score.firstNextGoals
      } or more to earn ${props.score.firstStars + 1} ${stars}`;
      textOne = `Score: ${props.score.score}`;
    } else if (gameInfo.type === "time") {
      firstGoals = `Win in ${
        props.score.firstNextGoals
      } seconds or less to earn ${props.score.firstStars + 1} ${stars}`;
      textOne = `Time: ${props.score.time}`;
    } else if (gameInfo.type === "accuracy") {
      firstGoals = `Get ${props.score.firstNextGoals * 100}% correct to earn ${props.score.firstStars + 1} ${stars}`;
      textOne = `Accuracy: ${Math.round((props.score.correct / (props.score.correct + props.score.incorrect)) * 100)}%`;
    }
    if (props.score.firstStars === 5) {
      firstGoals = 'Great Job! You got all of the stars!'
      starComponent = <FiveStar />;
    } else if (props.score.firstStars === 4) {
      starComponent = <FourStar />;
    } else if (props.score.firstStars === 3) {
      starComponent = <ThreeStar />;
    } else if (props.score.firstStars === 2) {
      starComponent = <TwoStar />;
    } else if (props.score.firstStars === 1) {
      starComponent = <OneStar />;
    } else {
      starComponent = <NoStar />;
    }
    return (
      <div className="SD-allContainer">
        <div className="SD-starContainer">
          {textOne}
          {starComponent}
        </div>
        <div className="SD-goalFont">{firstGoals}</div>
      </div>
    );
  } else if (props.set === 'secondSet') {
    let textTwo;
    let secondGoals;
    let stars = 'stars!'
    if (props.score.secondStars === 0) {
      // If the player still needs to earn their first star make 'stars' singular.
      stars = 'star!'
    }
    if (gameInfo.secondType === "correct") {
      secondGoals = `Score ${props.score.secondNextGoals} or more to earn ${props.score.secondStars + 1} ${stars}`;
      textTwo = `Score: ${props.score.score}`;
    } else if (gameInfo.secondType === "time") {
      secondGoals = `Win in ${props.score.secondNextGoals} seconds or less to earn ${props.score.secondStars + 1} ${stars}`;
      textTwo = `Time: ${props.score.time}`;
    } else if (gameInfo.secondType === "accuracy") {
      secondGoals = `Get ${props.score.secondNextGoals*100}% correct to earn ${props.score.secondStars + 1} ${stars}`;
      textTwo = `Accuracy: ${Math.round((props.score.correct/(props.score.correct+props.score.incorrect))*100)}%`;
    } else {
      return null;
    }
    if (props.score.secondStars === 5) {
      secondGoals = "Great Job! You got all of the stars!";
      starComponent = <FiveStar />
    } else if (props.score.secondStars === 4) {
      starComponent = <FourStar />
    } else if (props.score.secondStars === 3) {
      starComponent = <ThreeStar />
    } else if (props.score.secondStars === 2) {
      starComponent = <TwoStar />
    } else if (props.score.secondStars === 1) {
      starComponent = <OneStar />
    } else {
      starComponent = <NoStar />
    }
    return (
      <div className="SD-allContainer">
        <div className="SD-starContainer">
          {textTwo}
          {starComponent}
        </div>
        <div className="SD-goalFont">{secondGoals}</div>
      </div>
    );
  } else {
    return null;
  }
}

export default StarDisplay;

StarDisplay.propTypes = {
  goldStar: PropTypes.array,
  score: PropTypes.object,
  set: PropTypes.string,
};

{/* Checked PropTypes */}