
import './index.css';
import React from "react";
import { fetchImage } from "../nonUIFuncs";
import PropTypes from "prop-types";

let plusOne = fetchImage("+1.png");
let minusOne = fetchImage("-1.png");

const FloatingNumber = props => {
  if (props.correctness !== null) {
    return (
      <img
        src={props.correctness === "correct" ? plusOne : minusOne}
        className="floatingNumber-plusMinusImage"
      />
    );
  } else {
    return null;
  }
};

export default FloatingNumber;

FloatingNumber.propTypes = {
  correctness: PropTypes.string,
};

{/* Checked PropTypes */}