import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import BackButton from '../Components/backButton';

import StoriesGame from "../StoriesGame";
import { getLocation } from '../nonUIFuncs';
import DisplayScreen from '../Components/displayScreen';
import { useDispatch, useSelector } from 'react-redux';
import { setSpecificGame, setSortedData, getScreen, selectReduxSlice } from '../../Store/store';

let storySpecificVocab = [];

const Stories = props => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  let routeLocation = useLocation();
  let locationArr = getLocation(routeLocation.pathname);
  const scoreType = 'story';
  useEffect(() => {
    let specificGame = reduxState.data.find(
      (game) => game.id == Number(locationArr[4])
    );
    document.title = `Story - ${specificGame.category} - Kids Zone`;
    storySpecificVocab = reduxState.storybookVocab.filter(
      (vocab) => vocab.storyGame === specificGame.category
    );
    dispatch(setSortedData(reduxState.storybookSentence.filter(
      (sentence) => sentence.story === specificGame.category
    )));
    dispatch(setSpecificGame(specificGame));
    dispatch(getScreen('startScreen'));
  }, []);
  const clearGameStats = () => {
    dispatch(getScreen('startScreen'))
  };
  const gameComponent = (
    <StoriesGame
      backToStart={clearGameStats}
      checkWorld={() => dispatch(getScreen("playAgainScreen"))}
      storySpecificVocab={storySpecificVocab}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={() => dispatch(getScreen("gameScreen"))}
      />
    </div>
  );
}

export default Stories;

Stories.propTypes = {
  scoreData: PropTypes.array,
};

{/* Checked PropTypes */}
