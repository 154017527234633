import './index.css';
import React from "react";
import PropTypes from "prop-types";

const PopupWord = (props) => {
  if (props.enabled) {
    return (
      <div className="popUpWord-Container popupWord-shakeBox popupWord-popupContainer">
        <div className="popupWord-keyword popupWord-text">{props.popupWord}</div>
      </div>
    );
  }
};

export default PopupWord;

PopupWord.propTypes = {
  popupWord: PropTypes.string,
  enabled: PropTypes.bool,
};

{/* Checked PropTypes */}