import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import WordlessGame from "../WordlessGame";
import DisplayScreen from '../Components/displayScreen';
import BackButton from "../Components/backButton";
import { useDispatch, useSelector } from 'react-redux';
import { setInitialGameData, getScreen, selectReduxSlice } from '../../Store/store';

let gameTime = 0;

const Wordless = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const [gameClock, setGameClock] = useState(0);
  const scoreType = 'normal';
  useEffect(() => {
    let gameTimer = setInterval(() => setGameClock(gameClock + 1), 1000);
    dispatch(
      setInitialGameData(
        routeLocation,
        reduxState.data,
        reduxState.gameData,
        reduxState.storybookVocab,
        "Wordless"
      )
    );
    return () => clearInterval(gameTimer);
  }, []);
  const startGame = () => {
    setGameClock(0);
    dispatch(getScreen('gameScreen'));
  };
  const clearGameStats = () => {
    setGameClock(0);
    dispatch(getScreen('startScreen'))
  };
  const checkWorld = () => {
    gameTime = gameClock - 1;
    dispatch(getScreen('playAgainScreen'));
  };
  let gameComponent = (
    <WordlessGame
      checkWorld={checkWorld}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={startGame}
        time={gameTime}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default Wordless;

Wordless.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}