import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Draggable} from 'react-beautiful-dnd';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../../Store/store";

let displayDate = {};
let cardWidth = 40;
let cardHeight = 40;

const DisapearingContainer = styled.div`
  background-color: white;
  width: 100%;
  position: absolute;
  top: 0px;
  border-radius: 9px 9px 0px 0px;
`;
const YearText = styled.div`
  display: flex;
  width: 100%;
  color: black;
  justify-content: center;
`;
const CardDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: ${props => 
    props.isDragging
      ? `5px 5px 5px black`
      : `1px 1px 5px black`
  }
  transition: all 0.5s ease;
  transition-property: {box-shadow, transform}
  transform: ${props =>
    props.isDragging
      ? `rotate(7deg)`
      : `rotate(0deg)`
  };
  background: ${props =>
    props.isDragging
      ? `linear-gradient(180deg, rgba(157,214,255,1) 0%, rgba(97,144,214,1) 100%)`
      : `linear-gradient(180deg, rgba(97,214,144,1) 0%, rgba(70,162,128,1) 100%)`};
`;
const CardText = styled.div`
  color: black;
  line-height: 1.3;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 5px;
`;

const Card = props => {
  const reduxState = useSelector(selectReduxSlice);
  useEffect(() => {
    displayDate[props.content.id] = false;
    return () => {
      displayDate[props.content.id] = false;
    };
  }, []);
  cardWidth = reduxState.width / 8;
  cardHeight = cardWidth * 1.35;

  if (reduxState.height/4 < reduxState.width/8) {
    cardHeight = reduxState.height/4;
    cardWidth = cardHeight* .74;
  }

  if (displayDate[props.content.id] !== true) {
    if (props.displayDate === "table" && props.drag === false) {
      displayDate[props.content.id] = true;
    } else {
      displayDate[props.content.id] = false;
    }
  }
  
  let textFontSize = {
    fontSize: cardWidth/12
  }
  let cardTextLength = props.content.content.length
  if (cardTextLength > 190) {
    textFontSize.fontSize = cardWidth/14
  }
  let yearTextFontSize = {
    fontSize: cardWidth/7
  }
  let yearTextLength = props.content.date.length
  if(yearTextLength > 11) {
    yearTextFontSize.fontSize = cardWidth/9;
  }
  yearTextFontSize.padding = yearTextFontSize.fontSize/2;
  textFontSize.height = cardHeight - (yearTextFontSize.fontSize + (yearTextFontSize.padding*2))
  return (
    <Draggable
      draggableId={props.content.id}
      index={props.index}
      isDragDisabled={props.isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          id={props.content.id}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <CardDiv style={{width:cardWidth, height:cardHeight}} isDragging={snapshot.isDragging}>
            <DisapearingContainer
              isDragging={snapshot.isDragging}
              className="noselect"
            >
              {displayDate[props.content.id] ? (
                <YearText style={yearTextFontSize}>
                  {props.content.date}
                </YearText>
              ) : null}
            </DisapearingContainer>
            <CardText
              style={textFontSize}
              className="aboriginalSans noselect"
            >
              {props.content.content}
            </CardText>
          </CardDiv>
        </div>
      )}
    </Draggable>
  );
}
export default Card;

Card.propTypes = {
  content: PropTypes.object,
  displayDate: PropTypes.string,
  drag: PropTypes.bool,
  index: PropTypes.number,
  isDragDisabled: PropTypes.bool,
};

{/* Checked PropTypes */}