import './index.css';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GameSelectButton from '../GameSelectButton';
import { Link, useLocation} from "react-router-dom";
import RedirectPage from '../RedirectPage/index';
import {fetchImage, getLocation} from '../nonUIFuncs';
import { selectReduxSlice } from "../../Store/store";
import { useSelector } from "react-redux";



let filteredCategoryStars = [];
let badgeImg = null;
let goldStar = fetchImage('goldStar.png');
const SubMenu = props => {
  const reduxState = useSelector(selectReduxSlice);
  let routeLocation = useLocation();
  let locationArr = getLocation(routeLocation.pathname);
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    document.title = `Kids Zone - ${locationArr[2]}`;
    gameData();
  }, []);
  const gameData = () => {
    filteredCategoryStars = props.scoreData.filter(
      (datum) => datum.category === locationArr[2]
    );
    let games = reduxState.data.filter(
      (indivGame) => indivGame.category === locationArr[2]
    );
    let gameTypes = [];
    games.map(game => {
      if (!gameTypes.includes(game.game)) {
        gameTypes.push(game.game);
      }
    });
    if (games.length > 0) {
      badgeImg = `url(${fetchImage(games[0].badge)})`;
    } else if (redirect === false) {
      setRedirect(true);
    }
    let displayImage = null;
    let gameDOM = gameTypes.map(indivGame => {

      let displayName = games.find(game => game.game === indivGame).displayGameType;
      if (displayImage === null) {
        displayImage = fetchImage(
          games.find((game) => game.game === indivGame).backgroundImg
        );
      }
      // Remove these lines when developing Bingo Game
      if (displayName === "Bingo") {
        return;
      }
      let furtherFilteredCatStars = filteredCategoryStars.filter(
        (datum) => datum.gameType === indivGame
      );
      let allStars = 0;
      if (furtherFilteredCatStars.length !== 0) {
        let test = reduxState.goldStar.find(
          (datum) => datum.game === furtherFilteredCatStars[0].gameType
        );
        allStars = allStars + (5*furtherFilteredCatStars.length);
        if (test.secondType !== 'none') {
          allStars = allStars + (5*furtherFilteredCatStars.length);
        }
      }
      let starsEarned = 0;
      furtherFilteredCatStars.map(datum => {
        starsEarned = starsEarned + datum.stars;
      });
      return (
        <div className="subMenu-gameDataContainer" key={indivGame}>
          <div className="subMenu-gameButtonContainer">
            <Link
              to={{
                pathname: `/${locationArr[0]}/Home/${locationArr[2]}/${indivGame}/`,
                // pathname: `/${locationArr[0]}/${locationArr[1]}/${locationArr[2]}/${indivGame}/`,
                state: {
                  gameType: indivGame,
                  displayName: displayName,
                  category: locationArr[2],
                },
              }}
            >
              <GameSelectButton
                buttonBackground={displayImage}
                uniqueCategoryName={displayName}
              />
            </Link>
          </div>
          {displayName === "Story Book" ? (
            <div className="subMenu-lowerMsgContainer">Read the Story!</div>
          ) : (
            <div className="subMenu-lowerMsgContainer">
              <img src={goldStar} className="subMenu-starStyle" />
              {/* This should be fixed, but it is a good fallback if something goes wrong and can't find the data */}
              {allStars === 0 ? (
                <div className="SM-reloadMessage">
                  Play the game to earn stars!
                </div>
              ) : (
                <div className="SM-gameStarData">
                  {starsEarned}/{allStars}
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
    if (gameDOM.length === 0 || redirect === true) {
      return <RedirectPage />;
    } else {
      return gameDOM;
    }
  };
  let badgeImageLocal = {
    backgroundImage: badgeImg,
  };
  let languageCategory = reduxState.data.find(
    (datum) =>
      datum.category ===
      locationArr[2]
  ).categorySalish;
  if (languageCategory.length === 0) {
    languageCategory = reduxState.data.find(
      (datum) =>
        datum.category ===
        locationArr[2]
    ).category;
  }
  return (
    <div className="home-fullscreen">
      {redirect ? null : (
        <div
          className={`SM-categoryTitle ${
            routeLocation.pathname.includes("Story")
              ? "storyBanner"
              : "gamesBanner"
          }`}>
          <div className="SM-badge">
            <div style={badgeImageLocal} className="SM-badgeImage" />
          </div>
          {languageCategory}
          <div className="SM-badge">
            <div style={badgeImageLocal} className="SM-badgeImage" />
          </div>
        </div>
      )}
      <div className="home-centerContent">{gameData()}</div>
    </div>
  );
}

export default SubMenu;

SubMenu.propTypes = {
  scoreData: PropTypes.array,
};

{/* Checked PropTypes */}