import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import CrocadileGame from "../CrocadileGame";
import DisplayScreen from "../Components/displayScreen";
import {getLocation} from '../nonUIFuncs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getScreen,
  setSortedData,
  setSpecificGame,
  selectReduxSlice,
} from "../../Store/store";
import BackButton from '../Components/backButton';

let gameTime = 0;

const Crocadile = props => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  let routeLocation = useLocation();
  let locationArr = getLocation(routeLocation.pathname);
  const [gameClock, setGameClock] = useState(0);
  const scoreType = 'timeAndHits';
  useEffect(() => {
    let gameTimer = setInterval(() => setGameClock(gameClock + 1), 1000);
    filterData();
    dispatch(getScreen('startScreen'));
    return () => {
      clearInterval(gameTimer);
    };
  }, []);
  const filterData = () => {
    let specificGame = reduxState.data.find(
      (game) => game.id == Number(locationArr[4])
    );
    let filteredData = reduxState.gameData.filter(
      (filteredDatum) => filteredDatum.subCat === specificGame.target
    );
    document.title = `Crocodile - ${specificGame.category} - Kids Zone`;
    dispatch(setSortedData(filteredData));
    dispatch(setSpecificGame(specificGame));
  };
  const startGame = () => {
    setGameClock(0);
    dispatch(getScreen('gameScreen'));
  };
  const clearGameStats = () => {
    setGameClock(0);
    dispatch(getScreen('startScreen'));
  };
  const checkWorld = (time) => {
    gameTime = time;
    dispatch(getScreen('playAgainScreen'));
  };
  let gameComponent = <CrocadileGame checkWorld={checkWorld} />;
  return (
    <div className="fullscreenNoOverflow">
      <BackButton />
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={startGame}
        time={gameTime}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default Crocadile;

Crocadile.propTypes = {
  scoreData: PropTypes.array,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}