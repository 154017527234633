import './index.css';
import React, { useEffect } from "react";
import {fetchImage} from '../nonUIFuncs';


const Credits = () => {
  useEffect(() => {
    document.title = "Kids Zone - Credits";
  }, []);
  let ntaLogo = `${fetchImage('ntalogo.png')}`;
  let nkwLogo = `${fetchImage('nkwusmLogoFull.png')}`;
  return (
    <div className="fullScreenColor">
      <div className="credits-contentContainer">
        <div className="credits-creditContainer">
          <div className="credits-headerText">
            Design Concept by Chaney Bell
          </div>
          <div className="credits-headerText">Translated by Pat Pierre</div>
          <div className="credits-headerText">
            Language Edited By Tahini Pete, Melanie Sandoval, Jesse Nenemay,
            and Rosie Matt
          </div>
          <div className="credits-headerText">
            Website created and designed through Native Teaching Aids LLC by
            Thomas Koefod and Spencer Kittle
          </div>
        </div>
        <div className="credits-logosContainer">
          <a href="https://www.nkwusm.com">
            <div className="credits-logoAndSite">
              <img src={`${nkwLogo}`} className="credits-logo" />
              <div className="credits-link">https://www.nkwusm.com</div>
            </div>
          </a>
          <a href="https://www.nativeteachingaids.com">
            <div className="credits-logoAndSite">
              <img src={`${ntaLogo}`} className="credits-logo" />
              <div className="credits-link">
                https://www.nativeteachingaids.com
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Credits;

{/* Checked PropTypes */}
