import "./index.css";
import React from 'react';
import PropTypes from 'prop-types';

const  CardFlip = props => {
  const setClickedF = (e, id, image, audio, salish) => {
    props.cardData.selectCard(e, id, image, audio, salish)
  }
  let container = {
    height: props.cardData.targetBodyHeight,
    width: props.cardData.targetBodyWidth,
    position: "absolute",
    top:
      (props.cardData.height * props.cardData.rpY) /
      props.cardData.positionArrayDenom[1] -
      props.cardData.targetBodyHeight / 2 +
      props.cardData.targetBodyHeight / 4,
    left: ((props.cardData.width * props.cardData.rpX)/props.cardData.positionArrayDenom[0]) - props.cardData.targetBodyWidth/2
  };
  let flipper = {
    
  }
  let front = {
    height: props.cardData.targetBodyHeight,
    width: props.cardData.targetBodyWidth,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: props.cardData.frontImage,
  }
  let back = {
    height: props.cardData.targetBodyHeight,
    width: props.cardData.targetBodyWidth,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: props.cardData.backImage,
  }
  let isFlipped = props.cardData.flipped()[props.cardData.identifier] || false;
  return (
    <div
      id={props.cardData.identifier}
      style={container}
      onClick={ (e) => setClickedF(e, props.cardData.identifier, props.cardData.backImage, props.cardData.audio, props.cardData.salish)}
      className={`cardFlip-container ${(isFlipped) ? "active" : null}`}
    >
      <div style={flipper} className="cardFlip-flipper">
        <div style={front} className="cardFlip-front"/>
        <div style={back} className="cardFlip-back"/>
      </div>
    </div>
  );
};

export default CardFlip;


CardFlip.propTypes = {
  cardData: PropTypes.object
};

{/* Checked PropTypes */}