import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import BottomStatBar from "../BottomStatBar";
import InvisiBar from "../Invisibar";
import StatScreen from "../StatScreen";

import EndEndlessGame from "../Components/endEndlessMode";
import FloatingNumber from "../Components/floatingNumber";
import LevelBar from "../Components/endlessLevelBar";
import PopupWord from "../Components/popupWord";
import CloseEndless from "../Components/endlessCloseButton";

import { shuffle, placementArray, fetchImage, fetchAudio } from "../nonUIFuncs";

import PropTypes from "prop-types";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

let difficultyVar = "easy";
let displayedClock = 0;
let displayArray = [];
let displayArrayDenom = [3, 3];
let currentTarget = {};
let imageArray = [];
let shortTimer = 0;
let gameDOMArray = [];
let targetAudio = undefined;
let correctSoundEffect = null;
let subCatAudio = [];
let clickedAudio = null;
let switchDisplayVar = "game";
let testNum = 1;
let positionIndex = [];
let showPopup = false;
let currentLevelVar = 0;
let roundData = [];
let currentIndex = 0;
let currentTargetAudio = null;
let audioDOM = [];
let background = null;

const SwitchDisplay = props => {
  const [showEndlessEnd, setShowEndlessEnd] = useState();
  const reduxState = useSelector(selectReduxSlice);
  if (switchDisplayVar === "stats") {
    return (
      <StatScreen scoreType={props.scoreType} time={props.time} />
    );
  } else if (switchDisplayVar === "game") {
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      props.adjustElements();
    }
    return (
      <div className="centerContentContainer">
        {audioDOM}
        <div className="counting-plusMinusContainer">
          <FloatingNumber correctness={props.correctness} />
        </div>
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        {gameDOMArray}
        <CloseEndless enabled={props.endless} onClick={setShowEndlessEnd} />
        {props.endless ? (
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={props.currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={props.updateLevelData}
          />
        ) : null}
        <InvisiBar
          enabled={props.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
        {currentTarget !== undefined ? (
          <div
            className="topContainer countGame-centerContainer"
            onClick={() => currentTargetAudio.play()}>
            <div className="countGame-topContainerText">
              {props.currentTargetString}
            </div>
            <div
              onClick={(e) => props.checkCorrectness(e)}
              className="countingGame-checkButton">
              Check
            </div>
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

const CountingGame = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const clickedArrayRef = useRef();
  const currentLevelRef = useRef();
  const currentLevelProgressRef = useRef();
  const reduxStateRef = useRef();
  const [correctness, setCorrectness] = useState(null);
  const [firstTime, setFirstTime] = useState(true);
  const [clickedArray, setClickedArray] = useState([]);
  const [currentTargetString, setCurrentTargetString] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [chosenPopupWord, setChosenPopupWord] = useState();
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  clickedArrayRef.current = clickedArray;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    let timer = setInterval(updateDisplayClock, 1000);
    setCurrentLevel(0);
    currentLevelVar = 0;
    setCurrentLevelProgress(0);
    switchDisplayVar = "game";
    difficultyVar = reduxState.difficulty;
    reduxState.sortedData.map((datum) => {
      subCatAudio.push({
        playableAudio: new Audio(`${fetchAudio(datum.audio1)}`),
        image: datum.image1,
      });
    });
    correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
    return () => {
      switchDisplayVar = "game";
      difficultyVar = "easy";
      clearInterval(timer);
      displayedClock = 0;
      gameDOMArray = [];
      resetStats();
      if (targetAudio !== undefined && targetAudio[0] !== []) {
        targetAudio[0].playableAudio.pause();
        targetAudio = undefined;
      }
    };
  }, []);
  useEffect(() => {
    if (reduxState.sortedData && firstTime === true) {
      changeDisplay();
      setFirstTime(false);
    }
  }, [reduxState.sortedData, firstTime]);
  const updateDisplayClock = () => {
    if (routeLocation.state.endless !== true) {
      displayedClock = displayedClock + 1;
    }
    shortTimer = shortTimer + 1;
  };
  const resetStats = () => {
    shortTimer = 0;
  };
  const changeDisplay = () => {
    currentIndex = 0;
    let sliceNumber = 4;
    let iterations = 6;
    if (difficultyVar === "medium") {
      sliceNumber = 6;
      iterations = 8;
    } else if (difficultyVar === "hard") {
      sliceNumber = 8;
      iterations = 10;
    } else {
      positionIndex = [];
    }
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
    }
    let shuffled = shuffle(reduxState.sortedData);
    shuffled = shuffled.slice(0, sliceNumber);
    let i;
    imageArray = [];
    for (i = 0; i < iterations; i++) {
      imageArray.push(...shuffled);
    }
    if (difficultyVar === "easy") {
      displayArray = placementArray(1, 1, 5, 5, 1, 1);
      displayArrayDenom = [5, 5];
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        displayArray = placementArray(1, 2, 5, 6, 1, 1);
        displayArrayDenom = [5, 6];
      }
    } else if (difficultyVar === "medium") {
      displayArray = placementArray(1, 1, 7, 6, 1, 1);
      displayArrayDenom = [7, 6];
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        displayArray = placementArray(1, 2, 6, 8, 1, 1);
        displayArrayDenom = [6, 8];
      }
    } else if (difficultyVar === "hard") {
      displayArray = placementArray(1, 1, 9, 7, 1, 1);
      displayArrayDenom = [9, 7];
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        displayArray = placementArray(1, 2, 7, 10, 1, 1);
        displayArrayDenom = [7, 10];
      }
    }
    shuffleData();
  };
  const shuffleData = () => {
    imageArray = shuffle(imageArray);
    roundData = [];
    let englishArray = [];
    imageArray = imageArray.slice(0, displayArray.length);
    let uniqueKey = 0;
    imageArray = imageArray.map((datum) => {
      if (englishArray.includes(datum.english)) {
        roundData = roundData.map((key) => {
          if (key.english === datum.english) {
            let newKey = {
              ...key,
              amount: key.amount + 1,
              salishNumber: props.numberData[key.amount + 1].salish,
            };
            return newKey;
          } else {
            return key;
          }
        });
      } else {
        englishArray.push(datum.english);
        roundData.push({
          english: datum.english,
          amount: 1,
          salish: datum.salish,
          salishNumber: props.numberData[1].salish,
          audio: datum.audio1,
        });
      }
      uniqueKey = uniqueKey + 1;
      let newDatum = {
        ...datum,
        newKey: `${datum.english}${uniqueKey}`,
      };
      return newDatum;
    });
    currentTarget = roundData[currentIndex];
    setCurrentTargetString(
      `${currentTarget.salishNumber} ${currentTarget.salish}`
    );
    currentTargetAudio = new Audio(`${fetchAudio(currentTarget.audio)}`);
    currentTargetAudio.play();
    gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(() => number.classList.remove("levelNumber"), 1200);
  };
  const checkCorrectness = () => {
    let correct = "incorrect";
    let i = 0;
    if (clickedArrayRef.current.length === currentTarget.amount) {
      clickedArrayRef.current.map((string) => {
        if (string.includes(currentTarget.english)) {
          i = i + 1;
        }
      });
    }
    if (i === currentTarget.amount) {
      correct = "correct";
    }
    if (correct === "correct") {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      correctSoundEffect.play();
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress)
      if (currentLevelRef.current + 2 === progress && routeLocation.state.endless === true) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
      gameDOMArray = gameDOMArray.filter(
        (datum) => !datum.props.id.includes(currentTarget.english)
      );
      currentIndex = currentIndex + 1;
      currentTarget = roundData[currentIndex];
      if (currentTarget === undefined) {
        if (routeLocation.state.endless === true) {
          clickedArrayRef.current.map((string) => {
            let element = document.getElementById(string);
            element.classList.remove("countingGame-clicked");
            element.classList.add("countingGame-notClicked");
            setClickedArray([]);
          });
          changeDisplay();
        } else {
          displayStats();
        }
      } else {
        setCurrentTargetString(
          `${currentTarget.salishNumber} ${currentTarget.salish}`
        );
        currentTargetAudio = new Audio(`${fetchAudio(currentTarget.audio)}`);
        currentTargetAudio.play();
      }
      setClickedArray([]);
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('wrong');
      clickedArrayRef.current.map((string) => {
        let element = document.getElementById(string);
        element.classList.remove("countingGame-clicked");
        element.classList.add("countingGame-notClicked");
        setClickedArray([]);
      });
    }
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    switchDisplayVar = "game";
    displayedClock = 0;
    if (difficultyVar === "easy") {
      difficultyVar = "medium";
      changeDisplay();
    } else if (difficultyVar === "medium") {
      difficultyVar = "hard";
      changeDisplay();
    } else if (difficultyVar === "hard") {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const adjustElements = () => {
    let newArray = [...gameDOMArray];
    let imgDimm = reduxStateRef.current.width / 12;
    let buffer = 15;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      imgDimm = reduxStateRef.current.height / 12;
      if (difficultyVar === "hard") {
        imgDimm = reduxStateRef.current.height / 14;
      }
    }
    if (imgDimm < 50 && difficultyVar !== "hard") {
      imgDimm = 50;
    } else if (imgDimm > 375) {
      imgDimm = 375;
    }
    newArray = newArray.map((element) => {
      let coords = element.props.children.props.xycoord;
      let container = {
        position: "absolute",
        top:
          (reduxStateRef.current.height * coords.y) /
            displayArrayDenom[1] -
          (imgDimm + buffer) / 2,
        left:
          (reduxStateRef.current.width * coords.x) / displayArrayDenom[0] -
          (imgDimm + buffer) / 2,
      };
      return (
        <div
          key={element.key}
          id={element.props.id}
          style={container}
          className={element.props.className}>
          <div {...element.props.children.props} />
        </div>
      );
    });
    gameDOMArray = newArray;
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    let imgDimm = reduxStateRef.current.width / 12;
    let buffer = 15;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      imgDimm = reduxStateRef.current.height / 12;
      if (difficultyVar === "hard") {
        imgDimm = reduxStateRef.current.height / 14;
      }
    }
    if (imgDimm < 50 && difficultyVar !== "hard") {
      imgDimm = 50;
    } else if (imgDimm > 375) {
      imgDimm = 375;
    }
    imageArray = shuffle(imageArray);
    imageArray.map((datum) => {
      positionIndex.map((position) => {
        while (key === position) {
          key = position + 1;
        }
      });
      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let image = {backgroundImage: `url(${fetchImage(datum.image1)})`};
        let container = {
          position: "absolute",
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
        };
        gameDOMArray.push(
          <div
            id={datum.newKey}
            key={key}
            style={container}
            className="countingGame-notClicked countGame-itemContainer">
            <div
              salish={datum.salish}
              xycoord={xyCoord}
              key={key}
              className="countGame-item"
              style={image}
              audio={datum.audio1}
              onClick={() => editClicked(datum.newKey)}
            />
          </div>
        );
      }
    });
  };
  const editClicked = (clickedKey) => {
    let element = document.getElementById(clickedKey);
    if (clickedArrayRef.current.includes(clickedKey)) {
      element.classList.remove("countingGame-clicked");
      element.classList.add("countingGame-notClicked");
      setClickedArray(clickedArrayRef.current.filter((key) => key !== clickedKey));
    } else {
      element.classList.add("countingGame-clicked");
      element.classList.remove("countingGame-notClicked");
      setClickedArray([...clickedArrayRef.current, clickedKey]);
    }
  };
  const displayStats = () => {
    setTimeout(stepDifficulty, 4000);
    switchDisplayVar = "stats";
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
    }
    if (targetAudio !== undefined) {
      targetAudio[0].playableAudio.pause();
      targetAudio[0].playableAudio.currentTime = 0;
    }
  };
  if (
    background === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    background = `url(${fetchImage(reduxState.specificGame.backgroundImg)})`;
  }
  return (
    <div style={{ backgroundImage: background }} className="fullScreen">
      <SwitchDisplay
        checkCorrectness={checkCorrectness}
        chosenPopupWord={chosenPopupWord}
        correctness={correctness}
        currentLevel={currentLevelRef.current}
        currentTargetString={currentTargetString}
        endless={routeLocation.state.endless}
        adjustElements={adjustElements}
        scoreType={props.scoreType}
        time={props.time}
        updateLevelData={props.updateLevelData}
      />
      {switchDisplayVar === "game" || routeLocation.state.endless === true ? (
        <BottomStatBar>
          {routeLocation.state.endless === true ? (
            <LevelBar
              difficulty={difficultyVar}
              currentLevel={currentLevelRef.current}
              currentLevelProgress={currentLevelProgressRef.current}
            />
          ) : (
            <div className="countGame-BSB-container">
              <div>
                {difficultyVar.charAt(0).toUpperCase() + difficultyVar.slice(1)}
              </div>
              <div>
                {reduxState.uiWords[13].salish}:{" "}
                {reduxStateRef.current.stats.roundStats.score}
              </div>
              <div>
                {reduxState.uiWords[15].salish}: {props.time}
              </div>
            </div>
          )}
        </BottomStatBar>
      ) : null}
    </div>
  );
}

export default CountingGame;

CountingGame.propTypes = {
  checkWorld: PropTypes.func,
  numberData: PropTypes.array,
  scoreType: PropTypes.string,
  time: PropTypes.number,
  updateLevelData: PropTypes.func,
};

SwitchDisplay.propTypes = {
  adjustElements: PropTypes.func,
  checkCorrectness: PropTypes.func,
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  currentLevel: PropTypes.number,
  currentTargetString: PropTypes.string,
  endless: PropTypes.bool,
  scoreType: PropTypes.string,
  specificGame: PropTypes.object,
  time: PropTypes.number,
  updateLevelData: PropTypes.func,
};

{/* Checked PropTypes */}