import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useLocation } from "react-router-dom";

import ItemFallGame from '../ItemFallGame/index';
import { getImageSize, fetchImage } from "../nonUIFuncs";
import DisplayScreen from '../Components/displayScreen';
import BackButton from "../Components/backButton";
import { useDispatch, useSelector } from 'react-redux';
import {
  getScreen,
  selectReduxSlice,
  setInitialGameData,
} from "../../Store/store";

let backgroundRatio = null;
let backgroundRevRatio = null;
let target = {};
let items = [];
let targetArray = [];
let targetWidth = null;
let targetHeight = null;
let difficulty = 'easy';

const ItemFall = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const scoreType = 'normal';
  useEffect(() => {
    dispatch(setInitialGameData(
      routeLocation,
      reduxState.data,
      reduxState.gameData,
      undefined,
      'Item Fall',
    ));
  }, []);
  useEffect(() => {
    if (reduxState.specificGame !== undefined) {
      items = getItems(reduxState.specificGame);
      let chosenTarget = getTarget();
      target = chosenTarget;
      collectImageData(chosenTarget);
    }
  }, [reduxState.specificGame])
  const getTarget = () => {
    let targetArr = reduxState.gameData.filter(
      (datum) => datum.subCat === reduxState.specificGame.target
    );
    return targetArr[Math.floor(Math.random() * targetArray.length)];
  };
  const getItems = (game) => {
    let newItems = [];
    if (routeLocation.state.endless) {
      let englishArray = [];
      reduxState.gameData.map((datum) => {
        if (routeLocation.state.categories.includes(datum.homeName)) {
          if (!englishArray.includes(datum.english)) {
            englishArray.push(datum.english);
            newItems.push({
              id: datum.id,
              homeName: datum.homeName,
              subCat: datum.subCat,
              english: datum.english,
              salish: datum.salish,
              audio1: datum.audio1,
              image1: datum.image1,
            });
          }
        }
      });
    } else {
      newItems = reduxState.gameData.filter((datum) => datum.subCat === game.items);
    }
    return newItems;
  };
  const collectImageData = targ => {
    let backgroundImg = new Image();
    backgroundImg.src = `${fetchImage(reduxState.specificGame.backgroundImg)}`;
    let targetImg = new Image();
    targetImg.src = `${fetchImage(targ.image1)}`;
    backgroundImg.onload = () => {
      backgroundRatio = getImageSize(backgroundImg).ratio;
      backgroundRevRatio = getImageSize(backgroundImg).revRatio;
    };
    targetImg.onload = () => {
      targetHeight = getImageSize(targetImg).height;
      targetWidth = getImageSize(targetImg).width;
    };
  };
  const clearGameStats = () => {
    dispatch(getScreen('startScreen'));
  };
  let gameComponent = (
    <ItemFallGame
      backgroundRatio={backgroundRatio}
      backgroundRevRatio={backgroundRevRatio}
      checkWorld={() => dispatch(getScreen("playAgainScreen"))}
      difficulty={difficulty}
      items={items}
      target={target}
      targetH={targetHeight}
      targetW={targetWidth}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={() => dispatch(getScreen("gameScreen"))}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default ItemFall;

ItemFall.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}