import "./index.css";
import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import Matter from "matter-js";
import StatScreen from "../StatScreen";
import Timer from "../Timer";
import BottomStatBar from '../BottomStatBar/index';
import InvisiBar from '../Invisibar/index';
import {shuffle, fetchImage, fetchAudio} from '../nonUIFuncs';
import PopupWord from "../Components/popupWord";
import FloatingNumber from "../Components/floatingNumber";
import LevelBar from "../Components/endlessLevelBar";
import EndEndlessGame from "../Components/endEndlessMode";
import CloseEndless from "../Components/endlessCloseButton";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

const Engine = Matter.Engine;
const Render = Matter.Render;
const World = Matter.World;
const Bodies = Matter.Bodies;
const Mouse = Matter.Mouse;
const MouseConstraint = Matter.MouseConstraint;
const engine = Engine.create();
let renderVar;
// All images are sized to 216pxX216px
// scale needs to assume this is always the case;
let scale = 1;
let canvasToScreenDifHeight = 100;
let canvasToScreenDifWidth = 100;
let audioFile = undefined;
let correctSoundEffect = undefined;
let smallerDim = 4;
let chosenTarget = null;
let chosenDistractors = null;
let fullRoundData = [];
let canvasBackground = null;
let flipWord = false;
let fullTime = 30;
let showPopup = false;
let canvasWidth = 1000;
let keywordImg = null;

const SwitchDisplay = props => {
  if (props.displayedClock >= fullTime + 3) {
    engine.world.bodies = [];
    Matter.World.clear(engine.world, true);
  }
  props.pauseAllAudio();
  return <StatScreen scoreType="hits" />;
};

const WhackAMoleGame = props => {
  let updateClock;
  let timer;
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();

  const bodyXRef = useRef();
  const bodyYRef = useRef();
  const canvasRef = useRef();
  const displayedClockRef = useRef();
  const difficultyRef = useRef();
  const currentLevelRef = useRef();
  const currentLevelProgressRef = useRef();
  const reduxStateRef = useRef();

  const [keyword, setKeyword] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bodyX, setBodyX] = useState(0);
  const [bodyY, setBodyY] = useState(0);
  const [correctness, setCorrectness] = useState(null);
  const [mouseClickX, setMouseClickX] = useState(100);
  const [mouseClickY, setMouseClickY] = useState(100);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [display, setDisplay] = useState();
  const [displayedClock, setDisplayedClock] = useState(0);
  const [difficulty, setDifficulty] = useState('easy');
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);

  displayedClockRef.current = displayedClock;
  difficultyRef.current = difficulty;
  bodyYRef.current = bodyY;
  bodyXRef.current = bodyX;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;

  useEffect(() => {
    intervalEntity();
    smallerDim = reduxStateRef.current.height;
    if (reduxStateRef.current.height > reduxStateRef.current.width) {
      smallerDim = reduxStateRef.current.width;
    }
    scale = (smallerDim / 4.5) / 216;
    setCurrentLevel(0);
    setCurrentLevelProgress(0);
    setDifficulty(reduxState.difficulty);
    setDisplay('game');
    setDisplayedClock(0);
    correctSoundEffect = new Audio(fetchAudio("correctSound.mp3"));
    getRoundData(reduxState.difficulty);
    setLoaded();
    return () => {
      pauseAllAudio();
      clearInterval(updateClock);
      clearInterval(timer);
      timer = null;
      setDifficulty('easy');
    };
  }, []);
  useEffect(() => {
    if (loading) {
      setLoaded();
    }
  }, [loading]);
  const intervalEntity = () => {
    timer = setInterval(() => changeEntity(difficultyRef.current), 800);
    if (routeLocation.state.endless === true) {
      updateClock = setInterval(() => getRoundData(difficultyRef.current), 10000);
    } else {
      updateClock = setInterval(updateDisplayedClock, 1000);
    }
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(
      () => number.classList.remove("levelNumber"),
      1200
    );
  };


  const updateDisplayedClock = () => {
    if (displayedClockRef.current === 10 || displayedClockRef.current === 20) {
      pauseAllAudio();
      getRoundData(difficultyRef.current);
    } else if (displayedClockRef.current === 30) {
      setDisplay("stats");
    }
    setDisplayedClock(displayedClockRef.current + 1);
  };


  const changeEntity = (dif) => {
    if (displayedClockRef.current > fullTime + 5) {
      setDisplay('stats');
      stepDifficulty();
      return;
    }
    if (dif !== difficultyRef.current) {
      clearInterval(timer);
      timer = null;
      return;
    }
    let randomTarget = Math.floor(Math.random() * fullRoundData.length);
    let gameTarget = fullRoundData[randomTarget].image1;
    let bodyXVar = bodyXRef.current;
    let bodyYVar = bodyYRef.current;
    let randomX = Math.random();
    let randomY = Math.random();
    let xDifficulty = undefined;
    let yDifficulty = undefined;
    if (difficultyRef.current === "easy") {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        xDifficulty = 3;
        yDifficulty = 6.6;
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (bodyYVar === 5) {
          if (randomX <= 0.5) {
            bodyXVar = 1;
          } else {
            bodyXVar = 2;
          }
        } else {
          xDifficulty = 2;
          bodyXVar = 1;
        }
      }
    } else if (difficultyRef.current === "medium") {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        xDifficulty = 3;
        yDifficulty = 6.6;
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (randomX <= 0.5) {
          bodyXVar = 1;
        } else {
          bodyXVar = 2;
        }
      }
    } else if (difficultyRef.current === "hard") {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        xDifficulty = 4;
        yDifficulty = 6.6;
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (randomX <= 0.33) {
          bodyXVar = 1;
        } else if (randomX > 0.33 && randomX <= 0.66) {
          bodyXVar = 2;
        } else {
          bodyXVar = 3;
        }
      }
    }
    let moleSprite = `${fetchImage(gameTarget)}`;
    let mole = Bodies.rectangle(
      ((reduxStateRef.current.width - canvasToScreenDifWidth) * bodyXVar) /
        xDifficulty,
      ((reduxStateRef.current.height - canvasToScreenDifHeight) * bodyYVar) /
        yDifficulty,
      smallerDim / 4.5,
      smallerDim / 4.5,
      {
        coords: {x: bodyXVar, y: bodyYVar},
        isStatic: true,
        restitution: 0.7,
        render: {
          sprite: {
            texture: moleSprite,
            xScale: scale,
            yScale: scale,
          },
        },
      }
    );
    setBodyX(bodyXVar);
    setBodyY(bodyYVar);
    let filteredBodies = engine.world.bodies.filter(
      (oldBody) => oldBody.coords.x === mole.coords.x
    );
    let moreFilteredBodies = filteredBodies.filter(
      (oldBody) => oldBody.coords.y === mole.coords.y
    );
    Matter.World.remove(engine.world, moreFilteredBodies);
    Matter.World.add(engine.world, [mole]);
  };


  const getRoundData = dif => {
    if (engine) {
      engine.world.bodies = [];
      Matter.World.clear(engine.world, true);
    }
    let previousTarget = chosenTarget;
    let shuffled = shuffle(reduxState.sortedData);
    chosenTarget = shuffled[0];
    if (previousTarget !== null) {
      while (previousTarget.english === chosenTarget.english) {
        shuffled = shuffle(reduxState.sortedData);
        chosenTarget = shuffled[0];
      }
    }
    setKeyword(chosenTarget.salish);
    let findDistractors = shuffled.filter(
      (distractor) => distractor.english !== chosenTarget.english
    );
    let shuffledDistractors = shuffle(findDistractors);
    if (dif === "easy") {
      chosenDistractors = shuffledDistractors.slice(0, 1);
    } else if (dif === "medium") {
      chosenDistractors = shuffledDistractors.slice(0, 2);
    } else if (dif === "hard") {
      chosenDistractors = shuffledDistractors.slice(0, 3);
    }
    fullRoundData = new Array();
    if (dif === "easy") {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    } else if (dif === "medium") {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    } else if (dif === "hard") {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    }
    chosenDistractors.map((distractor) => {
      fullRoundData.push(distractor);
    });
    playAudio(chosenTarget.image1);
    flipWord = true;
    setTimeout(() => {flipWord = false}, 1000);
  };


  const setLoaded = () => {
    let bgRatio = props.backgroundHeight / props.backgroundWidth;
    let bgRevRatio = props.backgroundWidth / props.backgroundHeight;
    let canvasHeight = 1000;
    canvasWidth = canvasHeight * bgRatio;
    if (reduxStateRef.current.width * bgRatio < reduxStateRef.current.height) {
      canvasHeight = reduxStateRef.current.width * bgRatio;
      canvasWidth = reduxStateRef.current.width;
    } else {
      canvasHeight = reduxStateRef.current.height;
      canvasWidth = reduxStateRef.current.height * bgRevRatio;
    }
    canvasBackground = reduxState.specificGame.backgroundImg;
    renderVar = Render.create({
      canvas: canvasRef.current,
      engine: engine,
      options: {
        hasBounds: true,
        background: `url(${fetchImage(canvasBackground)})`,
        height: canvasHeight,
        width: canvasWidth,
        wireframes: false,
      },
    });
    canvasToScreenDifHeight =
      reduxStateRef.current.height - renderVar.options.height;
    canvasToScreenDifWidth =
      reduxStateRef.current.width - renderVar.options.width;
    let mouse = Mouse.create(renderVar.canvas);
    let mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });
    World.add(engine.world, [mouseConstraint]);




    Matter.Events.on(mouseConstraint, "mousedown", function () {
      setMouseClickX(
        mouseConstraint.mouse.absolute.x + canvasToScreenDifWidth / 2
      );
      setMouseClickY(
        mouseConstraint.mouse.absolute.y + canvasToScreenDifHeight / 2
      );
      if (mouseConstraint.body !== null) {
        pauseAllAudio();
        World.remove(engine.world, mouseConstraint.body);
        let clickedObject = mouseConstraint.body.render.sprite.texture.match(
          /(?:.+\/)(.+)/
        );
        playAudio(clickedObject[1]);
        if (clickedObject[1] === chosenTarget.image1) {
          let popupWords = reduxState.popupWords.filter(
            (datum) => datum.type === "correct"
          );
          let randomPopupNum = Math.floor(Math.random() * popupWords.length);
          showPopup = true;
          setTimeout(() => (showPopup = false), 1300);
          correctSoundEffect.play();
          dispatch(
            setStats({
              hits: reduxStateRef.current.stats.hits + 1,
              score: reduxStateRef.current.stats.score + 1,
              roundStats: {
                hits: reduxStateRef.current.stats.roundStats.hits + 1,
                score: reduxStateRef.current.stats.roundStats.score + 1,
              },
            })
          );
          setChosenPopupWord(popupWords[randomPopupNum].salish);
          setCorrectness('correct');
          let progress = currentLevelProgressRef.current + 1;
          setCurrentLevelProgress(progress);
          if (
            currentLevelRef.current + 2 === progress &&
            routeLocation.state.endless === true
          ) {
            setTimeout(nextEndlessLevel, 700);
          }
        } else {
          let popupWords = reduxState.popupWords.filter(
            (datum) => datum.type === "incorrect"
          );
          let randomPopupNum = Math.floor(Math.random() * popupWords.length);
          showPopup = true;
          setTimeout(() => (showPopup = false), 1300);
          dispatch(
            setStats({
              misses: reduxStateRef.current.stats.misses + 1,
              score: reduxStateRef.current.stats.score - 1,
              roundStats: {
                misses: reduxStateRef.current.stats.roundStats.misses + 1,
                score: reduxStateRef.current.stats.roundStats.score - 1,
              },
            })
          );
          setChosenPopupWord(popupWords[randomPopupNum].salish);
          setCorrectness('wrong');
          if (currentLevelProgressRef.current > 0) {
            setCurrentLevelProgress(currentLevelProgressRef.current - 1);
          }
        }
        setTimeout(() => setCorrectness(null), 1000);
      }
    });
    Engine.run(engine);
    Render.run(renderVar);
    setLoading(false);
  };


  const pauseAllAudio = () => {
    // let audio = document.getElementsByTagName("audio");
    // let fromArray = Array.from(audio);
    // fromArray.map((indivAudio) => {
    //   indivAudio.pause();
    //   indivAudio.currentTime = 0;
    // });
    correctSoundEffect.pause();
    audioFile.pause();
    audioFile.currentTime = 0;
  };


  const playAudio = fileName => {
    let foundFile = reduxState.sortedData.find(datum => datum.image1 === fileName);
    audioFile = new Audio(fetchAudio(foundFile.audio1));
    audioFile.play();
  }


  const stepDifficulty = () => {
    clearInterval(timer);
    timer = null;
    setDisplay('game');
    setDisplayedClock(0);
    if (difficultyRef.current === "easy") {
      setDifficulty('medium');
      timer = setInterval(() => changeEntity('medium', timer), 650);
      getRoundData('medium');
      engine.world.bodies = [];
      Matter.World.clear(engine.world, true);
    } else if (difficultyRef.current === "medium") {
      setDifficulty('hard');
      timer = setInterval(() => changeEntity('hard', timer), 500);
      getRoundData('hard');
      engine.world.bodies = [];
      Matter.World.clear(engine.world, true);
    } else if (difficultyRef.current === "hard") {
      clearInterval(timer);
      timer = null;
      props.checkWorld();
      engine.world.bodies = [];
      Matter.World.clear(engine.world, true);
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };


  if (keywordImg === null || !keywordImg.includes(reduxState.specificGame.keyWordImg)) {
    keywordImg = `url(${fetchImage(reduxState.specificGame.keyWordImg)})`;
  }


  let keywordImgStyle = {
    backgroundImage: keywordImg,
  };


  let flippedWord = flipWord;


  let plusMinusContainer = {
    position: "absolute",
    top: mouseClickY - reduxStateRef.current.height / 10,
    left: mouseClickX,
    zIndex: 999,
    height: 2,
    width: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };


  return (
    <div>
      <div className="WAMG-fullscreen noselect">
        <canvas ref={(r) => (canvasRef.current = r)} />
        <div
          onClick={() => playAudio(chosenTarget.image1)}
          className={`keyword ${
            flippedWord ? "flipped" : null
          } noselect WAMG-keyWordBox`}
          style={keywordImgStyle}
        >
          {chosenTarget !== null ? keyword : null}
        </div>
        <CloseEndless
          enabled={routeLocation.state.endless}
          onClick={() => setShowEndlessEnd(true)}
        />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={currentLevelRef.current}
          setShowEndlessEnd={setShowEndlessEnd}
          specificGame={reduxState.specificGame}
          updateLevelData={props.updateLevelData}
        />
        <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
        {display === "game" || routeLocation.state.endless === true ? (
          <BottomStatBar>
            {routeLocation.state.endless === true ? (
              <LevelBar
                difficulty={difficultyRef.current}
                currentLevel={currentLevelRef.current}
                currentLevelProgress={currentLevelProgressRef.current}
              />
            ) : (
              <div className="WAMG-bottomBar">
                <div>
                  {difficultyRef.current.charAt(0).toUpperCase() +
                    difficultyRef.current.slice(1)}
                </div>
                <div className="endlessBarLevel">
                  {reduxState.uiWords[13].salish}:{" "}
                  {reduxStateRef.current.stats.roundStats.score}
                </div>
                {routeLocation.state.endless === true ? null : (
                  <Timer
                    currentTime={displayedClockRef.current}
                    fullTime={fullTime}
                  />
                )}
              </div>
            )}
          </BottomStatBar>
        ) : null}
        <InvisiBar
          enabled={routeLocation.state.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
        <div style={plusMinusContainer}>
          <FloatingNumber correctness={correctness} />
        </div>
      </div>
      {display === "stats" && routeLocation.state.endless === false ? (
        <SwitchDisplay
          displayedClock={displayedClockRef.current}
          pauseAllAudio={pauseAllAudio}
          specificGame={reduxState.specificGame}
        />
      ) : null}
    </div>
  );
}

export default WhackAMoleGame;

WhackAMoleGame.propTypes = {
  backgroundHeight: PropTypes.number,
  backgroundWidth: PropTypes.number,
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
};

SwitchDisplay.propTypes = {
  displayedClock: PropTypes.number,
  pauseAllAudio: PropTypes.func,
};

{/* Checked PropTypes */}