import "./index.css";
import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import ReadyToStart from "../EndlessModeMenu/ReadyToStart/index";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";

const EndEndlessGame = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  let routeLocation = useLocation();
  let gameType = reduxState.specificGame.game;
  if (routeLocation.state.categories && routeLocation.state.categories.length > 1) {
    gameType = `${routeLocation.state.categories.length} Categories`;
  }
  let data = {
    gameType: gameType,
    category: routeLocation.state.categories,
    difficulty: reduxState.difficulty,
    newLevel: props.currentLevel,
    spreadGameType: reduxState.specificGame.displayGameType,
  };
  if (props.enabled) {
    return (
      <ReadyToStart>
        <div className="endEndlessGame-container">
          <div>Are you sure you want to end this game?</div>
          <div>
            If you end this game, your current level will be recorded.
          </div>
          <div className="endEndlessGame-buttonContainer">
            <div
              onClick={() => props.setShowEndlessEnd(false)}
              className="endEndlessGame-button"
            >
              No
            </div>
            <Link
              onClick={() => {
                props.endGame !== undefined ? props.endGame() : null;
                props.updateLevelData(data);
              }}
              to="/KidsZone/EndlessMode/"
            >
              <div className="endEndlessGame-button">Yes</div>
            </Link>
          </div>
        </div>
      </ReadyToStart>
    );
  }
};

export default EndEndlessGame;

EndEndlessGame.propTypes = {
  currentLevel: PropTypes.number,
  enabled: PropTypes.bool,
  endGame: PropTypes.func,
  setShowEndlessEnd: PropTypes.func,
  updateLevelData: PropTypes.func,
};

{/* Checked PropTypes */}
