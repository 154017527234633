import "./index.css";
import React, { useEffect } from "react";
import {Link} from 'react-router-dom';
import {fetchImage} from '../nonUIFuncs';
import { useSelector } from 'react-redux';
import { selectReduxSlice } from '../../Store/store';

let pnf = fetchImage("pageNotFound.png");
let ohNo = "Oh no!";
let errorText = "This page does not exist";
let clickHome = "Click to return Home";

const RedirectPage = () => {
  const reduxState = useSelector(selectReduxSlice);
  useEffect(() => {
    document.title = `404 Error - Kids Zone`;
  }, []);
  let errorImage = {
    backgroundImage: `url(${pnf})`,
  };
  return (
    <div className="redirect-fullScreenCenter">
      {reduxState.height >= reduxState.width ? (
        <div className="redirect-contentContainer">
          <div style={errorImage} className="redirect-errorImage" />
          <div className="redirect-textContainer">
            <div className="redirect-ohNoTall">{ohNo}</div>
            <div className="redirect-errorTextTall">{errorText}</div>
          </div>
          <Link to="/KidsZone/Home/">
            <div className="redirect-button">{clickHome}</div>
          </Link>
        </div>
      ) : (
        <div className="redirect-contentContainer">
          <div className="redirect-wideScreenContainer">
            <div style={errorImage} className="redirect-errorImage" />
            <div className="redirect-textContainer">
              <div className="redirect-ohNo">{ohNo}</div>
              <div className="redirect-errorText">{errorText}</div>
            </div>
          </div>
          <Link to="/KidsZone/Home/">
            <div className="redirect-button">{clickHome}</div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default RedirectPage;
