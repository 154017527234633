import './index.css';

import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import "firebase/auth";
import "firebase/firestore";
import store from "./Store";
import {getData, selectReduxSlice} from "./Store/store";
import { Provider, useDispatch, useSelector } from "react-redux";

import App from "./App";
import LoadingScreen from './Components/LoadingScreen/index';

let scoreData = [];
let checkDataArray = [];
let gameList = [];

const setInitialLevelData = (data, setInitialLevel, levelData, setLevelData) => {
  let newLevelData = levelData;
  if (localStorage.LevelData && localStorage.LevelData.length > 2) {
    newLevelData = JSON.parse(localStorage.LevelData);
    if (newLevelData.type === 'string') {
      newLevelData = JSON.parse(newLevelData);
    }
  } else {
    data.map((game) => {
      if (
        game.game !== "Bingo" &&
        game.game !== "FillInTheBlank" &&
        game.game !== "StoryBook" &&
        game.game !== "Crocodile" &&
        game.game !== "SolveIt2" &&
        game.category !== "Math"
      ) {
        if (!gameList.includes(game.game)) {
          gameList.push(game.game);
          newLevelData.push({
            gameType: "2 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
          newLevelData.push({
            gameType: "3 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
          newLevelData.push({
            gameType: "4 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
          newLevelData.push({
            gameType: "5 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
        }
        if (!checkDataArray.includes(`${game.game}${game.category}`)) {
          checkDataArray.push(`${game.game}${game.category}`);
          newLevelData.push({
            gameType: game.game,
            spreadGameType: game.displayGameType,
            category: game.category,
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
        }
      }
    });
  }
  setLevelData(newLevelData);
  setInitialLevel(true)
};
const setInitialScoreData = (data, GoldStar, setInitialScore) => {
  if (localStorage.ScoreData && localStorage.ScoreData.length > 2) {
    scoreData = JSON.parse(localStorage.ScoreData);
    if (scoreData.type === 'string') {
      scoreData = JSON.parse(scoreData);
    }
  } else {
    data.map((game) => {
      let scoreType = GoldStar.find((type) => {
        return type.game === game.game;
      });
      if (scoreType === undefined) {
        return
      }
      scoreData.push({
        id: game.id,
        scoreType: scoreType.scoreType,
        category: game.category,
        gameType: game.game,
        gameName: game.displayGameName,
        target: game.target,
        correct: 0,
        incorrect: 0,
        score: 0,
        time: 0,
        stars: 0,
        firstStars: 0,
        secondStars: 0,
        firstNextGoals: null,
        secondNextGoals: null,
      });
    });
  }
  setInitialScore(true);
};
const saveData = (score, level) => {
  localStorage.clear();
  localStorage.setItem("ScoreData", JSON.stringify(score));
  localStorage.setItem("LevelData", JSON.stringify(level));
}
const SendData = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const [initialScore, setInitialScore] = useState(false);
  const [initialLevel, setInitialLevel] = useState(false);
  const [levelData, setLevelData] = useState([]);
  useEffect(() => {
    let sheetArr = [
      { collection: "KZ_PopupWords", nameToSave: "popupWords" },
      { collection: "KZ_HistoryData", nameToSave: "historyData" },
      { collection: "KZ_AppData", nameToSave: "gameData" },
      { collection: "KZ_GameFilterData", nameToSave: "data" },
      { collection: "KZ_StorybookSentence", nameToSave: "storybookSentence" },
      { collection: "KZ_StorybookVocab", nameToSave: "storybookVocab" },
      { collection: "KZ_uiWords", nameToSave: "uiWords" },
      { collection: "KZ_GoldStar", nameToSave: "goldStar" },
    ];
    sheetArr.map((d) => {
      dispatch(getData(d.collection, d.nameToSave));
    });
  }, []);
  if (reduxState.firebaseSheetsPulled.length >= 8) {
    if (initialScore === false) {
      setInitialScoreData(
        reduxState.data,
        reduxState.goldStar,
        setInitialScore
      );
    }
    if (initialLevel === false) {
      setInitialLevelData(
        reduxState.data,
        setInitialLevel,
        levelData,
        setLevelData
      );
    }
    return (
      <App
        style={{width: '100%', height: '100%', backgroundColor: 'red'}}
        scoreData={scoreData}
        levelData={levelData}
        saveData={saveData}
      />
    );
  } else {
    return <LoadingScreen />;
  }
};

const root = ReactDOM.createRoot(document.querySelector("#app"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SendData />
    </Provider>
  </React.StrictMode>
);
