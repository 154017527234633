import "./index.css";
import React from "react";
import {Icon} from "semantic-ui-react";

const LoadingScreen = () => {
  return (
    <div className="LS-fullScreen">
      <div className="LS-content">
        <Icon fitted loading size="huge" name="spinner" />
      </div>
      <div className="LS-content">Loading...</div>
      <div className="LS-contentSmall">
        Please make sure you have a strong internet connection.
      </div>
      <div className="LS-contentSmall">
        If this screen lasts longer than 10 seconds, reload your browser.
      </div>
    </div>
  );
};

export default LoadingScreen;

{/* Checked PropTypes */}