import React from "react";
import PropTypes from "prop-types";

const ReadyToStart = props => {
  if (!props.categories || props.categories.length > 0) {
    return (
      <div className="EM-RTS-overlayScreen">
        <div className="EMRTS-box EM-RTS-popupWindow">
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <div className="EM-RTS-overlayScreen">
        <div className="EMRTS-box EM-RTS-popupWindow">
          <div>There are no selected categories.</div>
          <div>Please select at least one category.</div>
          <div className="EM-RTS-button" onClick={props.resetCategories}>
            Select Categories
          </div>
        </div>
      </div>
    );
  }
}
export default ReadyToStart;
ReadyToStart.propTypes = {
  categories: PropTypes.array,
  resetCategories: PropTypes.func,
  children: PropTypes.element,
};

{/* Checked PropTypes */}