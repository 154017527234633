import './index.css';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {fetchImage} from '../nonUIFuncs';
import { useSelector } from 'react-redux';
import { selectReduxSlice } from '../../Store/store';

let time = 0;
let timerImg = null;
let background = null;

const StatScreen = props => {
  const reduxState = useSelector(selectReduxSlice);
  useEffect(() => {
    time = props.time;
  }, []);
  if (
    timerImg === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    timerImg = fetchImage(reduxState.specificGame.timerImg);
  }
  if (
    background === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    background = fetchImage(reduxState.specificGame.backgroundImg);
  }
  let fullscreenBackground = {
    backgroundImage: `url(${background})`,
  };
  let timerImgStyle = {
    backgroundImage: `url(${timerImg})`,
  };
  // let arr = [
  //   {
  //     scoreType: 'hits',
  //   },
  //   {
  //     scoreType: 'timeAndHits',
  //   },
  //   {
  //     scoreType: 'correct',
  //   },
  //   {
  //     scoreType: 'time',
  //
  //   },
  // ];
  // let obj = arr.filter(d => d.scoreType === props.scoreType);

  if (props.scoreType === "hits") {
    return (
      <div className="statScreen-fullScreen" style={fullscreenBackground}>
        <div className="statScreen-contentContainer">
          <div
            className={`${
              reduxState.height > reduxState.width
                ? "SS-alignColumn" : "SS-alignRow"
            }`}>
            <div className="SS-statContainer" style={timerImgStyle}>
              <div className="SS-stat keyword noselect">
                {reduxState.uiWords[5].salish}: {reduxState.stats.roundStats.hits}
              </div>
            </div>
            <div className="SS-statContainer" style={timerImgStyle}>
              <div className="SS-stat keyword noselect">
                {reduxState.uiWords[6].salish}: {reduxState.stats.roundStats.misses}
              </div>
            </div>
          </div>
          <div className="SS-statContainer" style={timerImgStyle}>
            <div className="SS-stat keyword noselect">
              {reduxState.uiWords[13].salish}: {reduxState.stats.roundStats.score}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.scoreType === "timeAndHits") {
    return (
      <div className="statScreen-fullScreen" style={fullscreenBackground}>
        <div className="statScreen-contentContainer">
          <div className="SS-statContainer" style={timerImgStyle}>
            <div className="keyword noselect SS-timeStat">
              {reduxState.uiWords[15].salish}: {time}
            </div>
          </div>
          <div
            className={`${
              reduxState.height > reduxState.width
                ? "SS-alignColumn" : "SS-alignRow"
            }`}>
            <div className="SS-statContainer" style={timerImgStyle}>
              <div className="SS-stat keyword noselect">
                {reduxState.uiWords[7].salish}: {reduxState.stats.roundStats.hits}
              </div>
            </div>
            <div className="SS-statContainer" style={timerImgStyle}>
              <div className="SS-stat keyword noselect">
                {reduxState.uiWords[8].salish}: {reduxState.stats.roundStats.misses}
              </div>
            </div>
          </div>
          <div className="SS-statContainer" style={timerImgStyle}>
            <div className="SS-stat keyword noselect">
              {reduxState.uiWords[13].salish}: {reduxState.stats.roundStats.score}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.scoreType === "correct") {
    return (
      <div className="statScreen-fullScreen" style={fullscreenBackground}>
        <div className="SS-statContainer" style={timerImgStyle}>
          <div className="SS-stat keyword noselect">
            {reduxState.uiWords[7].salish}: {reduxState.stats.roundStats.hits}
          </div>
        </div>
        <div className="SS-statContainer" style={timerImgStyle}>
          <div className="SS-stat keyword noselect">
            {reduxState.uiWords[8].salish}: {reduxState.stats.roundStats.misses}
          </div>
        </div>
        <div className="SS-statContainer" style={timerImgStyle}>
          <div className="SS-stat keyword noselect">
            {reduxState.uiWords[9].salish}: {reduxState.stats.roundStats.score}
          </div>
        </div>
      </div>
    );
  } else if (props.scoreType === "time") {
    return (
      <div className="statScreen-fullScreen" style={fullscreenBackground}>
        <div className="SS-statContainer" style={timerImgStyle}>
          <div className="keyword noselect SS-timeStat">
            {reduxState.uiWords[15].salish}: {time}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default StatScreen;

StatScreen.propTypes = {
  scoreType: PropTypes.string,
  time: PropTypes.number,
};

{/* Checked PropTypes */}