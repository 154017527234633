import './index.css';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';

import StatScreen from '../StatScreen';
import Card from './Card';
import LoadingScreen from '../LoadingScreen/index';
import BottomStatBar from '../BottomStatBar/index';
import {shuffle, fetchImage, fetchAudio} from '../nonUIFuncs';
import PopupWord from '../Components/popupWord';
import FloatingNumber from '../Components/floatingNumber';
import { useSelector, useDispatch } from 'react-redux';
import { selectReduxSlice, setStats } from '../../Store/store';

let popNumber = 2;
let formattedData = {
	cards:{},
	cardLocations: {},
	cardLocationOrder: [],
};
let listening = false;
let switchDisplayVar = "loading";
let buttons = [">", "<", "="];
let plusOrMinusVar = "+";
let plusOrMinusTwo = "+";
let firstSegment = null;
let secondSegment = null;
let ready = false;
let resetClock = 0;
let showPopup = false;
let setFullTime = true;
let allTime = 0;
let background = null;
let correctSound = null;

const SwitchDisplay = props => {
  const reduxState = useSelector(selectReduxSlice);
  if (switchDisplayVar === "loading") {
    return <LoadingScreen />;
  } else if (switchDisplayVar === 'stats') {
    if (setFullTime === true) {
      allTime = allTime + props.displayedClock;
      setFullTime = false;
    }
    if (resetClock === 0) {
      resetClock = props.displayedClock;
    }
    if (resetClock + 6 <= props.displayedClock) {
      props.stepDifficulty();
    }
    return <StatScreen scoreType="correct" />;
  } else if (switchDisplayVar === "game") {
    return (
      <div className="centerContentContainer">
        <div className="CG-plusMinusContainer">
          <FloatingNumber correctness={props.correctness} />
        </div>
        {ready ? props.displayData() : null}
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        <BottomStatBar>
          <div className="CG-BSB-stat">
            {props.difficulty.charAt(0).toUpperCase() +
              props.difficulty.slice(1)}
          </div>
          <div className="CG-BSB-stat">
            {reduxState.uiWords[13].salish}: {props.roundStats.score}
          </div>
          <div className="CG-BSB-stat">
            {reduxState.uiWords[15].salish}: {props.displayedClock}
          </div>
        </BottomStatBar>
      </div>
    );
  } else {
    return null;
  }
};

const CrocadileGame = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const difficultyRef = useRef();
  const displayedClockRef = useRef();
  const chosenNumbersRef = useRef();
  const reduxStatsRef = useRef();
  const [correctness, setCorrectness] = useState(null);
  const [drag, setDrag] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [difficulty, setDifficulty] = useState('easy');
  const [displayedClock, setDisplayedClock] = useState(0);
  const [chosenNumbers, setChosenNumbers] = useState([]);
  difficultyRef.current = difficulty;
  displayedClockRef.current = displayedClock;
  chosenNumbersRef.current = chosenNumbers;
  reduxStatsRef.current = reduxState.stats;
  useEffect(() => {
    let timer = setInterval(
      () => setDisplayedClock(displayedClockRef.current + 1),
      1000
    );
    ready = false;
    setDifficulty('easy');
    listening = false;
    switchDisplayVar = "game";
    correctSound = new Audio(`${fetchAudio("correctSound.mp3")}`);
    gatherData('easy');
    return () => {
      ready = false;
      setDifficulty('easy');
      listening = false;
      if (switchDisplayVar === "stats") {
        switchDisplayVar = "game";
      }
      clearInterval(timer);
      setDisplayedClock(0);
      resetData();
    };
  }, []);
  const gatherData = (dif) => {
    if (dif === "easy") {
      popNumber = 2;
    } else if (dif === "medium") {
      popNumber = 3;
    } else {
      popNumber = 4;
    }
    resetData();
    plusOrMinus();
    let mutatableNumbers = [...reduxState.sortedData];
    setChosenNumbers(shuffle(mutatableNumbers).slice(0, popNumber));
    formatData();
  };
  const formatData = () => {
    formattedData.cardLocations = {
      ...formattedData.cardLocations,
      [`solveSpot${0}`]: {
        id: `solveSpot${0}`,
        contentId: [],
      },
    };
    formattedData.cardLocationOrder.push(`solveSpot${0}`);
    let i = 0;
    buttons.map((datum) => {
      formattedData.cards = {
        ...formattedData.cards,
        [`cardDraggable${i}`]: {
          id: `cardDraggable${i}`,
          content: datum,
        },
      };
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`location${i}`]: {
          id: `location${i}`,
          contentId: [`cardDraggable${i}`],
        },
      };
      formattedData.cardLocationOrder.push(`location${i}`);
      i = i + 1;
    });
    ready = true;
  };
  const plusOrMinus = () => {
    if (Math.random() > 0.5) {
      plusOrMinusVar = "+";
    } else {
      plusOrMinusVar = "-";
    }
    if (Math.random() > 0.5) {
      plusOrMinusTwo = "+";
    } else {
      plusOrMinusTwo = "-";
    }
  };
  const displayData = () => {
    let cardOptions = formattedData.cardLocationOrder.map(
      (cardLocationId, index) => {
        const cardLocation = formattedData.cardLocations[cardLocationId];
        const content = cardLocation.contentId.map(
          (cardId) => formattedData.cards[cardId]
        );
        return (
          <Card
            key={cardLocation.id}
            cardLocation={cardLocation}
            content={content}
            indexed={index}
          />
        );
      }
    );
    let solveSpot = cardOptions.filter((spot) => spot.key === "solveSpot0");
    let symbolOptions = cardOptions.slice(1);
    let displayDOM = {};
    let doMath = {
      "+": function (x, y) {
        return x + y;
      },
      "-": function (x, y) {
        return x - y;
      },
    };
    let testStyle = {
      fontSize: 15,
    };
    let longString = 0;
    chosenNumbersRef.current.map((obj) => {
      longString = longString + obj.salish.length;
    });
    testStyle.fontSize = 1000 / longString;
    if (testStyle.fontSize > 45) {
      testStyle.fontSize = 45;
    } else if (testStyle.fontSize < 25) {
      testStyle.fontSize = 25;
    }
    if (difficultyRef.current === "easy") {
      firstSegment = Number(chosenNumbersRef.current[0].english);
      secondSegment = Number(chosenNumbersRef.current[1].english);
      displayDOM = (
        <div className="CG-displayDOM">
          <div className="CG-numberDis">{chosenNumbersRef.current[0].salish}</div>
          {solveSpot}
          <div className="CG-numberDis">{chosenNumbersRef.current[1].salish}</div>
        </div>
      );
    } else if (difficultyRef.current === "medium") {
      firstSegment = doMath[plusOrMinusVar](
        Number(chosenNumbersRef.current[0].english),
        Number(chosenNumbersRef.current[1].english)
      );
      secondSegment = Number(chosenNumbersRef.current[2].english);
      displayDOM = (
        <div className="CG-displayDOM">
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[0].salish}
          </div>
          <div className="CG-symbolDis">{plusOrMinusVar}</div>
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[1].salish}
          </div>
          {solveSpot}
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[2].salish}
          </div>
        </div>
      );
    } else {
      firstSegment = doMath[plusOrMinusVar](
        Number(chosenNumbersRef.current[0].english),
        Number(chosenNumbersRef.current[1].english)
      );
      secondSegment = doMath[plusOrMinusTwo](
        Number(chosenNumbersRef.current[2].english),
        Number(chosenNumbersRef.current[3].english)
      );
      displayDOM = (
        <div className="CG-displayDOM">
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[0].salish}
          </div>
          <div className="CG-symbolDis">{plusOrMinusVar}</div>
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[1].salish}
          </div>
          {solveSpot}
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[2].salish}
          </div>
          <div className="CG-symbolDis">{plusOrMinusTwo}</div>
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[3].salish}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="CG-placemat">{displayDOM}</div>
        <div className="CG-DD-outerContainer">
          <div className="CG-DD-innerContainer">{symbolOptions}</div>
        </div>
      </div>
    );
  };
  const resetData = () => {
    setChosenNumbers([]);
    formattedData = {
      cards: {},
      cardLocations: {},
      cardLocationOrder: [],
    };
  };
  const stepDifficulty = () => {
    resetClock = 0;
    resetData();
    switchDisplayVar = "game";
    setDisplayedClock(0);
    setFullTime = true;
    if (difficultyRef.current === "easy") {
      setDifficulty('medium');
      gatherData('medium');
    } else if (difficultyRef.current === "medium") {
      setDifficulty('hard');
      gatherData('hard');
    } else if (difficultyRef.current === "hard") {
      props.checkWorld(allTime);
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const onDragEnd = (result) => {
    setDrag(false);
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let begin = formattedData.cardLocations[source.droppableId];
    let end = formattedData.cardLocations[destination.droppableId];
    let beginContent = begin.contentId;
    let endContent = end.contentId;
    const newBegin = {
      ...begin,
      contentId: endContent,
    };
    const newEnd = {
      ...end,
      contentId: beginContent,
    };
    const newFormattedData = {
      ...formattedData,
      cardLocations: {
        ...formattedData.cardLocations,
        [newBegin.id]: newBegin,
        [newEnd.id]: newEnd,
      },
    };
    formattedData = newFormattedData;
    if (formattedData.cardLocations.solveSpot0.contentId.length > 0) {
      checkMath();
    }
  };
  const checkMath = () => {
    listening = false;
    let filledId = formattedData.cardLocations.solveSpot0.contentId[0];
    let filledContent = formattedData.cards[filledId].content;
    let solution = undefined;
    if (filledContent === ">") {
      solution = firstSegment > secondSegment;
    } else if (filledContent === "<") {
      solution = firstSegment < secondSegment;
    } else {
      solution = firstSegment === secondSegment;
    }
    if (solution === true) {
      correctSound.play();
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          hits: reduxStatsRef.current.hits + 1,
          score: reduxStatsRef.current.score + 1,
          roundStats: {
            hits: reduxStatsRef.current.roundStats.hits + 1,
            score: reduxStatsRef.current.roundStats.score + 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
      if (reduxStatsRef.current.roundStats.score >= 9) {
        switchDisplayVar = "stats";
      }
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStatsRef.current.misses + 1,
          score: reduxStatsRef.current.score - 1,
          roundStats: {
            misses: reduxStatsRef.current.roundStats.misses + 1,
            score: reduxStatsRef.current.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('wrong');
    }
    gatherData(difficultyRef.current);
    setTimeout(() => setCorrectness(null), 1000);
  };
  if (background === null) {
    background = `url(${fetchImage(reduxState.specificGame.backgroundImg)})`;
  }
  return (
    <DragDropContext onDragStart={() => setDrag(true)} onDragEnd={onDragEnd}>
      <div
        id="fullScreen"
        style={{ backgroundImage: background }}
        className="fullScreen overflow-y: hidden;">
        <SwitchDisplay
          chosenPopupWord={chosenPopupWord}
          correctness={correctness}
          difficulty={difficultyRef.current}
          displayData={displayData}
          displayedClock={displayedClockRef.current}
          roundStats={reduxStatsRef.current.roundStats}
          stepDifficulty={stepDifficulty}
        />
      </div>
      {listening ? <div className="CG-fullScreen" /> : null}
    </DragDropContext>
  );
}

export default CrocadileGame;

CrocadileGame.propTypes = {
  checkWorld: PropTypes.func,
};

SwitchDisplay.propTypes = {
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  difficulty: PropTypes.string,
  displayData: PropTypes.func,
  displayedClock: PropTypes.number,
  roundStats: PropTypes.object,
  stepDifficulty: PropTypes.func,
};

{/* Checked PropTypes */}