import './index.css';
import '../../App.css';
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Matter from "matter-js";

import StatScreen from '../StatScreen';
import BottomStatBar from '../BottomStatBar/index';
import InvisiBar from '../Invisibar/index';
import {fetchAudio, fetchImage} from '../nonUIFuncs';
import PopupWord from '../Components/popupWord';
import LevelBar from "../Components/endlessLevelBar";
import EndEndlessGame from "../Components/endEndlessMode";
import CloseEndless from "../Components/endlessCloseButton";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

const Engine = Matter.Engine;
const Render = Matter.Render;
const Composite = Matter.Composite;
const Bodies = Matter.Bodies;
const Mouse = Matter.Mouse;
const MouseConstraint = Matter.MouseConstraint;
let engineT;
let render;

let difficultyTimeout = null;
let canvasToScreenDifWidth = 1;
let canvasToScreenDifHeight = 1;
let canvasHeight;
let canvasWidth;
let showPopup = false;
let correctSoundEffect = new Audio(fetchAudio('correctSound.mp3'));
let iLike;
let iDontLike;
let likedItem;
let unlikedItem;
let smallerDim = null;
let chosenItem = {english: null};
let fullRoundData = [];
let audioElement;
let targetBoxSize = 1;
let targetScale;
let itemScale;
let shortCounter = 0;
let interval;
let dragStart;
let currentLevelVar = 0;
let targetImage = null;
let talkCloud = `url(${fetchImage("talkCloud.png")})`;


const SwitchDisplay = props => {
  props.stopAudio();
  props.clearPopupTimeout();
  return (
    <div className="IFG-statScreenContainer">
      <StatScreen scoreType="hits" />
    </div>
  );
};

const ItemFallGame = props => {
  let popupTimeout;
  let items;
  const routeLocation = useLocation();
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const canvasRef = useRef();
  const difficultyRef = useRef();
  const currentTimerRef = useRef();
  const currentLevelRef = useRef();
  const reduxStateRef = useRef();
  const currentLevelProgressRef = useRef();
  const [talking, setTalking] = useState(true);
  const [body, setBody] = useState(false);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [difficulty, setDifficulty] = useState('easy');
  const [currentTimer, setCurrentTimer] = useState(0);
  const [display, setDisplay] = useState('game');
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  currentTimerRef.current = currentTimer;
  difficultyRef.current = difficulty;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    setDisplay('game');
    setCurrentLevel(0);
    currentLevelVar = 0;
    setCurrentLevelProgress(0);
    setDifficulty(reduxState.difficulty);
    iLike = new Audio(`${fetchAudio(reduxState.specificGame.positiveAudio)}`);
    iDontLike = new Audio(`${fetchAudio(reduxState.specificGame.negativeAudio)}`);
    shortCounter = 0;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      smallerDim = reduxStateRef.current.height;
    } else {
      smallerDim = reduxStateRef.current.width;
    }
    targetBoxSize = smallerDim / 4;
    if (props.targetH >= props.targetW) {
      itemScale = ((smallerDim / 6) * 1.1) / props.targetH;
      targetScale = ((smallerDim / 4) * 1.1) / props.targetH;
    } else {
      itemScale = ((smallerDim / 6) * 1.1) / props.targetW;
      targetScale = ((smallerDim / 4) * 1.1) / props.targetW;
    }
    createWorld();
    setRoundData(reduxState.difficulty || 'easy');
    return () => {
      setDifficulty('easy');
      stopAudio();
      engineT.world.bodies = [];
      engineT.world.bodies.map((body) => {
        if (body.isStatic === false) {
          Composite.remove(engineT.world, [body]);
        }
      });
      chosenItem = { english: null };
      clearTimeout(popupTimeout);
      clearTimeout(difficultyTimeout);
      clearTimeout(items);
    };
  }, []);
  const createWorld = () => {
    engineT = Engine.create();
    createCanvas();
    engineT.gravity = {
      x: 0,
      y: 0.08,
    };
  };
  const createCanvas = () => {
    // 1. Set the maximum size of the play area based on height and width of screen to game background
    if (
      reduxStateRef.current.width * props.backgroundRatio <
      reduxStateRef.current.height
    ) {
      canvasHeight = reduxStateRef.current.width * props.backgroundRatio;
      canvasWidth = reduxStateRef.current.width;
    } else {
      canvasHeight = reduxStateRef.current.height;
      canvasWidth = reduxStateRef.current.height * props.backgroundRevRatio;
    }
    canvasToScreenDifWidth = reduxStateRef.current.width - canvasWidth;
    canvasToScreenDifHeight = reduxStateRef.current.height - canvasHeight;
    // 2. Create the canvas
    render = Render.create({
      canvas: canvasRef.current,
      engine: engineT,
      options: {
        hasBounds: true,
        background: `url(${fetchImage(reduxState.specificGame.backgroundImg)})`,
        height: canvasHeight,
        width: canvasWidth,
        wireframes: false,
      },
    });
    // 3. Create the mouse and add it to the World
    let mouse = Mouse.create(render.canvas);
    let mouseConstraint = MouseConstraint.create(engineT, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });
    Composite.add(engineT.world, [mouseConstraint]);
    // 4. create the collisionStart event
    Matter.Events.on(engineT, "collisionStart", ({ pairs }) => {
      pairs.forEach(({ bodyA, bodyB }) => {
        if (
          bodyA.render.sprite.texture === targetImage
        ) {
          let image = bodyB.render.sprite.texture.match(/(?:.+\/)(.+)/);
          if (image[1] === chosenItem.image1) {
            upScore();
            Composite.remove(engineT.world, [bodyB]);
            if (chosenItem.audio1.length > 0) {
              audioWL();
            }
          } else {
            downScore();
            bodyB.position.x = bodyB.position.x - spawnWidth(10, 15);
            bodyB.position.y = bodyB.position.y - spawnWidth(10, 15);
            audioWDL(bodyB);
          }
        }
      });
    });
    // 5. create the mousedown event
    Matter.Events.on(mouseConstraint, "mousedown", function () {
      dragStart = Date.now();
      if (mouseConstraint.body !== null) {
        stopAudio();
        if (mouseConstraint.body.isStatic === true) {
          audioWL(mouseConstraint.body);
        }
        if (mouseConstraint.body.isStatic === false) {
          setBody(mouseConstraint.body);
          // mouseStartX = mouseConstraint.mouse.position.x;
          // mouseStartY = mouseConstraint.mouse.position.y;
          let image = mouseConstraint.body.render.sprite.texture.match(
            /(?:.+\/)(.+)/
          );
          let audioToPlay = props.items.find(
            (datum) => datum.image1 == image[1]
          );
          if (audioToPlay.audio1.length > 0) {
            audioElement = new Audio(`${fetchAudio(audioToPlay.audio1)}`);
            console.log('1st', audioElement, audioToPlay);
            audioElement.play();
          }
        }
      }
    });
    Matter.Events.on(mouseConstraint, "mouseup", function () {
      if (body !== false && Date.now() - dragStart < 250) {
        // let xDif = mouseConstraint.mouse.position.x - mouseStartX;
        // let yDif = mouseConstraint.mouse.position.y - mouseStartY;
        // let timeDif = Date.now() - dragStart;
        // console.log(xDif / timeDif, yDif / timeDif);

        // This statement should be more accurate to apply the correct force, but the 0s look a little less buggy.
        // Matter.Body.applyForce(body, mouseConstraint.mouse.position, {x: xDif/timeDif, y: yDif/timeDif});

        Matter.Body.applyForce(
          body,
          mouseConstraint.mouse.position,
          { x: 0, y: 0 }
        );
      }
      setBody(false);
    });
    // 6. Now that everything needed for the canvas has been created and added, run it
    Engine.run(engineT);
    Render.run(render);
    setTarget();
  };
  const setTarget = () => {
    targetImage = `${fetchImage(props.target.image1)}`;
    const gameTarget = Bodies.rectangle(
      canvasWidth - targetBoxSize / 2,
      canvasHeight - targetBoxSize / 2,
      targetBoxSize,
      targetBoxSize,
      {
        isStatic: true,
        collisionFilter: {
          group: 1,
        },
        render: {
          sprite: {
            texture: targetImage,
            xScale: targetScale,
            yScale: targetScale,
          },
        },
      }
    );
    Composite.add(engineT.world, [gameTarget]);
  };
  const spawnWidth = (min, max) => {
    return Math.floor(Math.random() * Math.floor(max - min + 1) + min);
  };
  const spawnItem = timerNumber => {
    if (currentTimerRef.current !== timerNumber) {
      clearInterval(timerNumber);
      return;
    }
    let sprite = `${fetchImage(
      fullRoundData[Math.floor(Math.random() * fullRoundData.length)].image1
    )}`;
    let itemBody = Bodies.rectangle(
      spawnWidth(targetBoxSize / 2, canvasWidth - targetBoxSize / 2),
      -targetBoxSize * 0.8,
      targetBoxSize * 0.8,
      targetBoxSize * 0.8,
      {
        isStatic: false,
        restitution: 0.7,
        render: {
          sprite: {
            texture: sprite,
            xScale: itemScale * 0.9,
            yScale: itemScale * 0.9,
          },
        },
      }
    );
    Composite.add(engineT.world, [itemBody]);
    engineT.world.bodies.map((body) => {
      if (body.isStatic === false) {
        if (
          body.position.y >
          reduxStateRef.current.height + targetBoxSize * 0.75
        ) {
          Composite.remove(engineT.world, [body]);
        } else if (body.position.x < -targetBoxSize) {
          Composite.remove(engineT.world, [body]);
        } else if (
          body.position.x >
          reduxStateRef.current.width + targetBoxSize
        ) {
          Composite.remove(engineT.world, [body]);
        }
      }
    });
  };
  const setRoundData = dif => {
    clearInterval(items);
    shortCounter = 0;
    fullRoundData = [];
    let optionsArr = props.items.filter(
      (item) => item.english !== chosenItem.english
    );
    chosenItem = optionsArr[Math.floor(Math.random() * optionsArr.length)];
    setTalking(true);
    // Push the first one here
    fullRoundData.push(chosenItem);
    let i = 0;
    let distNumber;
    if (dif === "easy") {
      interval = 4000;
      distNumber = 1;
      engineT.gravity = {
        x: 0,
        y: 0.06,
      };
    } else if (dif === "medium") {
      interval = 2750;
      distNumber = 2;
      engineT.gravity = {
        x: 0,
        y: 0.07,
      };
    } else if (dif === "hard") {
      interval = 2000;
      distNumber = 3;
      engineT.gravity = {
        x: 0,
        y: 0.08,
      };
    }
    let distractors = props.items.filter(
      (datum) => datum.english !== chosenItem.english
    );
    let randomDistractor;
    while (i < distNumber) {
      i++;
      randomDistractor =
        distractors[Math.floor(Math.random() * distractors.length)];
      distractors = distractors.filter(
        (datum) => datum.english !== randomDistractor.english
      );
      // In order to get more of the correct answer we are going to push the
      // chosenItem one more time than we have for the number of distractors.
      // Depending on dif, the chosenItem will have from a 57 - 66% chance to spawn.
      fullRoundData.push(chosenItem);
      fullRoundData.push(randomDistractor);
    }
    if (chosenItem.audio1.length > 0) {
      likedItem = new Audio(`${fetchAudio(chosenItem.audio1)}`);
      audioWL();
    }
    clearInterval(items);
    items = setInterval(() => spawnItem(items), interval);
    setCurrentTimer(items);
    setTimeout(() => {
      setTalking(false);
    }, 4000);
  };
  const stopAudio = () => {
    iLike.pause();
    iLike.currentTime = 0;
    if (likedItem !== undefined) {
      likedItem.pause();
      likedItem.currentTime = 0;
    }
    iDontLike.pause();
    iDontLike.currentTime = 0;
    if (unlikedItem !== undefined) {
      unlikedItem.pause();
      unlikedItem.currentTime = 0;
    }
    if (audioElement !== undefined) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };
  const audioWL = () => {
    stopAudio();
    console.log("2nd", iLike);
    iLike.play();
    iLike.onended = () => {
      console.log("3rd", likedItem);
      likedItem.play();
    };
  };
  const audioWDL = (e) => {
    let image = e.render.sprite.texture.match(/(?:.+\/)(.+)/);
    let dataEntry = props.items.find((datum) => datum.image1 === image[1]);
    if (dataEntry.audio1.length > 0) {
      unlikedItem = new Audio(`${fetchAudio(dataEntry.audio1)}`);
      stopAudio();
      console.log('4th', iDontLike);
      iDontLike.play();
      iDontLike.onended = () => {
        console.log('5th', unlikedItem);
        unlikedItem.play();
      };
    }
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(
      () => number.classList.remove("levelNumber"),
      1200
    );
  };
  const upScore = () => {
    let popupWords = reduxState.popupWords.filter(
      (datum) => datum.type === "correct"
    );
    showPopup = true;
    popupTimeout = setTimeout(() => (showPopup = false), 1300);

    console.log("6th", correctSoundEffect);
    correctSoundEffect.play();
    setChosenPopupWord(
      popupWords[Math.floor(Math.random() * popupWords.length)].salish
    );
    dispatch(
      setStats({
        hits: reduxStateRef.current.stats.hits + 1,
        score: reduxStateRef.current.stats.score + 1,
        roundStats: {
          hits: reduxStateRef.current.stats.roundStats.hits + 1,
          score: reduxStateRef.current.stats.roundStats.score + 1,
        },
      })
    );
    let progress = currentLevelProgressRef.current + 1;
    setCurrentLevelProgress(progress);
    if (
      currentLevelRef.current + 2 === progress &&
      routeLocation.state.endless === true
    ) {
      setTimeout(nextEndlessLevel, 700);
    }
    shortCounter = shortCounter + 1;
  };
  const downScore = () => {
    let popupWords = reduxState.popupWords.filter(
      (datum) => datum.type === "incorrect"
    );
    showPopup = true;
    popupTimeout = setTimeout(() => {
      showPopup = false;
      setChosenPopupWord(
        popupWords[Math.floor(Math.random() * popupWords.length)].salish
      );
    }, 1300);
    if (currentLevelProgressRef.current > 0) {
      setCurrentLevelProgress(currentLevelProgressRef.current - 1);
    }
    dispatch(
      setStats({
        misses: reduxStateRef.current.stats.misses + 1,
        score: reduxStateRef.current.stats.score - 1,
        roundStats: {
          misses: reduxStateRef.current.stats.roundStats.misses + 1,
          score: reduxStateRef.current.stats.roundStats.score - 1,
        },
      })
    );
    shortCounter = shortCounter - 1;
  };
  const stepDifficulty = () => {
    clearInterval(items);
    shortCounter = 0;
    difficultyTimeout = null;
    setDisplay('game');
    let bodies = engineT.world.bodies.filter((b) => b.isStatic !== true);
    Composite.remove(engineT.world, bodies, true);
    if (difficultyRef.current === "easy") {
      setDifficulty('medium');
      setRoundData('medium');
      audioWL();
      setCurrentTimer(items);
    } else if (difficultyRef.current === "medium") {
      setDifficulty('hard');
      setRoundData('hard');
      audioWL();
      setCurrentTimer(items);
    } else {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  if (
    reduxStateRef.current.stats.roundStats.score >= 20 &&
    routeLocation.state.endless !== true
  ) {
    clearInterval(items);
    clearInterval(currentTimerRef.current);
    let bodies = engineT.world.bodies.filter((b) => b.isStatic !== true);
    Composite.remove(engineT.world, bodies, true);
    if (display !== "stats") setDisplay("stats");
    if (difficultyTimeout === null) {
      difficultyTimeout = setTimeout(stepDifficulty, 4000);
    }
  }
  let talkBubblePosition = {
    backgroundImage: talkCloud,
    bottom: 50,
    left: 50,
  };
  if (talking) {
    talkBubblePosition.bottom = targetBoxSize * 0.66 + canvasToScreenDifHeight / 2;
    talkBubblePosition.right = targetBoxSize + canvasToScreenDifWidth / 2;
    talkBubblePosition.left = 'auto';
  }
  // After 5 correct answers change the chosenItem and distractors.
  if (shortCounter >= 5 && reduxStateRef.current.stats.roundStats.score !== 20) {
    setTimeout(() => setRoundData(difficultyRef.current), 200);
  }
  // if (score >= 20 && routeLocation.state.endless !== true) {
  //   setDisplay('stats');
  // }
  const endGame = () => {
    clearInterval(items);
    clearInterval(currentTimerRef.current);
    let bodies = engineT.world.bodies.filter((b) => b.isStatic !== true);
    Composite.remove(engineT.world, bodies, true);
    if (display !== "stats") setDisplay("stats");
    if (difficultyTimeout === null) {
      difficultyTimeout = setTimeout(() => {
        clearInterval(items);
        shortCounter = 0;
        difficultyTimeout = null;
        setDisplay("game");
        let bodies = engineT.world.bodies.filter((b) => b.isStatic !== true);
        Composite.remove(engineT.world, bodies, true);
        props.checkWorld();
      }, 100);
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  return (
    <div className="itemFallGame-fullscreen">
      <canvas ref={(r) => (canvasRef.current = r)} />
      <div>
        {talking ? (
          <div className="noselect noClick IFG-greyBox">
            <div
              style={talkBubblePosition}
              className="noselect itemFallGame-keywordBox"
            >
              {reduxState.specificGame.positiveSalish} {chosenItem.salish}
            </div>
          </div>
        ) : (
          <div
            onClick={() => audioWL()}
            style={talkBubblePosition}
            className="noselect itemFallGame-keywordBox"
          >
            {chosenItem.salish}
          </div>
        )}
        <CloseEndless
          enabled={routeLocation.state.endless}
          onClick={() => setShowEndlessEnd(true)}
        />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={currentLevelRef.current}
          endGame={endGame}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={props.updateLevelData}
        />
        <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
        <InvisiBar
          enabled={routeLocation.state.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
      </div>
      {display === "game" || routeLocation.state.endless === true ? (
        <BottomStatBar>
          {routeLocation.state.endless === true ? (
            <LevelBar
              difficulty={difficultyRef.current}
              currentLevel={currentLevelRef.current}
              currentLevelProgress={currentLevelProgressRef.current}
            />
          ) : (
            <div className="IFG-BSB-statContainer">
              <div>
                {difficultyRef.current.charAt(0).toUpperCase() +
                  difficultyRef.current.slice(1)}
              </div>
              <div>
                {reduxState.uiWords[13].salish}:{" "}
                {reduxStateRef.current.stats.roundStats.score}
              </div>
            </div>
          )}
        </BottomStatBar>
      ) : null}
      {display === "stats" ? (
        <SwitchDisplay
          clearPopupTimeout={() => clearTimeout(popupTimeout)}
          stopAudio={stopAudio}
        />
      ) : null}
    </div>
  );
}

export default ItemFallGame;

ItemFallGame.propTypes = {
  backgroundRatio: PropTypes.number,
  backgroundRevRatio: PropTypes.number,
  checkWorld: PropTypes.func,
  items: PropTypes.array,
  target: PropTypes.object,
  targetH: PropTypes.number,
  targetW: PropTypes.number,
  updateLevelData: PropTypes.func,
};
SwitchDisplay.propTypes = {
  clearPopupTimeout: PropTypes.func,
  stopAudio: PropTypes.func,
};

{/* Checked PropTypes */}