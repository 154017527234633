import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import DisplayScreen from "../Components/displayScreen";
import HistoryGame from "../HistoryGame";
import { useDispatch } from 'react-redux';
import { getScreen, setSpecificGame } from '../../Store/store';

let specificGame = {
  category: "History",
  displayGameName: "Timeline",
};
let gameTime = 0;

const History = props => {
  const dispatch = useDispatch();
  const gameClockRef = useRef();
  const [gameClock, setGameClock] = useState(0);
  const scoreType = 'normal';
  gameClockRef.current = gameClock;
  useEffect(() => {
    let gameTimer = setInterval(
      () => setGameClock(gameClockRef.current + 1),
      1000
    );
    document.title = "KidsZone - Timeline Game";
    dispatch(getScreen('startScreen'));
    dispatch(setSpecificGame(specificGame));
    return () => {
      clearInterval(gameTimer);
    };
  }, []);
  const startGame = () => {
    setGameClock(0);
    dispatch(getScreen('gameScreen'));
  };
  const clearGameStats = () => {
    setGameClock(0);
    dispatch(getScreen('startScreen'));
  };
  const checkWorld = () => {
    gameTime = gameClock - 1;
    dispatch(getScreen('playAgainScreen'));
  };
  let gameComponent = (
    <HistoryGame checkWorld={checkWorld} time={gameClockRef.current} />
  );
  return (
    <div className="fullScreenColor">
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        historyGame={true}
        scoreType={scoreType}
        startGame={startGame}
        time={gameTime}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default History;

History.propTypes = {
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}