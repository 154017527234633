import './index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faForward,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import {fetchImage, fetchAudio} from '../nonUIFuncs';
import { useSelector } from 'react-redux';
import { selectReduxSlice } from '../../Store/store';

let sentenceAudio = null;
let vocabAudioArray = [];
let vocabAudio = null;
let background = null;

const StoryGame = props => {
  const reduxState = useSelector(selectReduxSlice);
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    sentenceAudio = new Audio(`${fetchAudio(reduxState.sortedData[0].audio)}`);
    sentenceAudio.play();
    return () => {
      if (sentenceAudio !== null) {
        sentenceAudio.pause();
        sentenceAudio.currentTime = 0;
        sentenceAudio = null;
      }
      if (vocabAudio !== null) {
        vocabAudio[1].pause();
        vocabAudio[1].currentTime = 0;
        vocabAudio = null;
      }
    };
  }, []);
  const nextPage = () => {
    sentenceAudio.pause();
    setPageNumber(pageNumber + 1);
    if (pageNumber > reduxState.sortedData.length - 2) {
      props.checkWorld();
    } else {
      sentenceAudio = new Audio(`${fetchAudio(reduxState.sortedData[pageNumber+1].audio)}`);
      background = `url(${fetchImage(
        reduxState.sortedData[pageNumber + 1].image
      )})`;
    }
    sentenceAudio.play()
  }
  const previousPage = () => {
    sentenceAudio.pause();
    setPageNumber(pageNumber - 1);
    if (pageNumber <= 0) {
      props.backToStart();
    } else {
      sentenceAudio = new Audio(`${fetchAudio(reduxState.sortedData[pageNumber-1].audio)}`);
      background = `url(${fetchImage(
        reduxState.sortedData[pageNumber - 1].image
      )})`;
    }
    sentenceAudio.play()
  }
  const _playVocabAudio = (audio) => {
    if (sentenceAudio !== null) {
      sentenceAudio.pause();
      sentenceAudio.currentTime = 0;
    }
    if (vocabAudio !== null) {
      vocabAudio[1].pause();
      vocabAudio[1].currentTime = 0;
      vocabAudio = null;
    }
    vocabAudio = vocabAudioArray.find(datum => datum[0] === audio);
    vocabAudio[1].play();
  }
  const _playSentenceAudio = () => {
    if (sentenceAudio !== null) {
      sentenceAudio.pause();
      sentenceAudio.currentTime = 0;
    }
    if (vocabAudio !== null) {
      vocabAudio[1].pause();
      vocabAudio[1].currentTime = 0;
      vocabAudio = null;
    }
    sentenceAudio.play();
  }
  const vocabDisplay = () => {
    vocabAudioArray = [];
    let pageVocab = props.storySpecificVocab.filter(datum => datum.page == pageNumber + 1);
    let i = 0
    let vocabDOM = pageVocab.map(datum => {
      i = i+1
      vocabAudioArray.push(
        [datum.audio1, new Audio(`${fetchAudio(datum.audio1)}`)]
      );
      return (
        <div
          className="storyGame-outerBox storyGame-headerText SG-vocab"
          onClick={_playVocabAudio.bind(null, datum.audio1)}
          key={i}>
          {datum.salish} &nbsp; <FontAwesomeIcon icon={faVolumeUp} />
        </div>
      );
    });
    return vocabDOM
  }
  if (
    background === null ||
    !background.includes(reduxState.sortedData[pageNumber].image)
  ) {
    background = `url(${
      fetchImage(reduxState.sortedData[pageNumber].image)
    })`;
  }
  let backgroundImg = {
    backgroundImage:background,
  }
  return (
    <div style={backgroundImg} className="SG-fullScreen">
      <div className="SG-topDisplay">
        <div className="storyGame-topBar">
          <div
            onClick={previousPage}
            className="storyGame-outerBox storyGame-topBarContent">
            <FontAwesomeIcon icon={faBackward} />
          </div>
          <div className="storyGame-pageNumber storyGame-topBarContent" >
            {pageNumber + 1}
          </div>
          <div
            onClick={nextPage}
            className="storyGame-outerBox storyGame-topBarContent">
            <FontAwesomeIcon icon={faForward} />
          </div>
        </div>
        <div>
          {vocabDisplay()}
        </div>
      </div>
      <div
        onClick={() => {_playSentenceAudio()}}
        className="storyGame-outerBox storyGame-sentenceContainer">
        <div className="storyGame-headerText SG-sentence">
          {reduxState.sortedData[pageNumber].fullSentence} <FontAwesomeIcon icon={faVolumeUp} />
        </div>
      </div>
    </div>
  );
}

export default StoryGame;

StoryGame.propTypes = {
  backToStart: PropTypes.func,
  checkWorld: PropTypes.func,
  storySpecificVocab: PropTypes.array,
};

{/* Checked PropTypes */}