import './index.css';
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import GameSelectButton from '../../GameSelectButton/index';
import { Link } from "react-router-dom";
import { fetchImage } from "../../nonUIFuncs";
import {
  selectReduxSlice,
  setDifficulty,
  getScreen,
} from "../../../Store/store";
import { useSelector, useDispatch } from "react-redux";

const CategoryDisplay = props => {
  return (
    <div className="EM-DS-categoryContainer">
      {props.categories.map((datum, index) => {
        if (props.categories.length === 1) {
          return (
            <div className="EM-DS-categoryText" key={datum}>
              {datum}
            </div>
          );
        }
        if (index + 1 !== props.categories.length) {
          return (
            <div className="EM-DS-categoryText" key={datum}>
              {datum},
            </div>
          );
        } else {
          return (
            <div className="EM-DS-categoryText" key={datum}>
              and {datum}
            </div>
          );
        }
      })}
    </div>
  );
};
const DifficultySelect = props => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDifficulty(undefined));
  }, []);
  const resetSelections = () => {
    dispatch(setDifficulty(undefined));
    props.resetSelections();
  }
  let chosenGame = '';
  if (props.game === 'Wordless') {
    chosenGame = 'Wordless';
  } else if (props.game === 'Word To Image') {
    chosenGame = 'WordToImage';
  } else if (props.game === 'Matching') {
    chosenGame = 'Matching';
  } else if (props.game === 'Whack-A-Mole') {
    chosenGame = 'WhackAMole';
  } else if (props.game === 'Item Fall') {
    chosenGame = 'ItemFall';
  } else if (props.game === 'Good Guess') {
    chosenGame = 'GoodGuess';
  } else if (props.game === 'Bubble Pop') {
    chosenGame = 'BubblePop';
  } else if (props.game === 'Counting') {
    chosenGame = 'Counting'
  }
  return (
    <div className="fullScreenColor EM-DS-fullScreen">
      <div className="EM-DS-topBanner">
        Select a Difficulty
        <div className="EM-DS-topBannerSub">
          {props.game} -{` `}
          <CategoryDisplay categories={props.categories} />
        </div>
      </div>
      <div className="EM-DS-buttonContainer">
        <div onClick={() => dispatch(setDifficulty("Easy"))}>
          <GameSelectButton
            buttonBackground={fetchImage("easy.png")}
            uniqueCategoryName={`${reduxState.uiWords[1].salish}`}
          />
        </div>
        <div onClick={() => dispatch(setDifficulty("Medium"))}>
          <GameSelectButton
            buttonBackground={fetchImage("medium.png")}
            uniqueCategoryName={`${reduxState.uiWords[2].salish}`}
          />
        </div>
        <div onClick={() => dispatch(setDifficulty("Hard"))}>
          <GameSelectButton
            buttonBackground={fetchImage("hard.png")}
            uniqueCategoryName={`${reduxState.uiWords[3].salish}`}
          />
        </div>
      </div>
      {reduxState.difficulty !== undefined ? (
        <div className="EM-DS-overlayScreen">
          <div className="EMRTS-box EM-DS-popupWindow">
            <div className="EM-DS-textContainer">
              <div>{props.game}</div>
              <div className="EM-DS-categoryContainerFull">
                {props.categories.map((datum) => (
                  <div key={datum}>{datum}</div>
                ))}
              </div>
              <div>{reduxState.difficulty}</div>
            </div>
            <div className="EM-DS-flex">
              <div className="EM-DS-menuButton" onClick={resetSelections}>
                Reselect
              </div>
              <Link
                to={`/KidsZone/EndlessMode/${chosenGame}/`}
                state = {{
                    categories: props.categories,
                    difficulty: reduxState.difficulty,
                    endless: true,
                  }}
                onClick={() => dispatch(getScreen('startScreen'))}>
                <div className="EM-DS-menuButton">Start Playing</div>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DifficultySelect;

DifficultySelect.propTypes = {
  categories: PropTypes.array,
  game: PropTypes.string,
  resetSelections: PropTypes.func,
};

CategoryDisplay.propTypes = {
  categories: PropTypes.array,
};

{/* Checked PropTypes */}