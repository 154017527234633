import "./index.css";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../../Store/store";

const DisplayScoreCompare = props => {
  const reduxState = useSelector(selectReduxSlice);
  if (props.game.scoreType === "normal") {
    return (
      <div>
        <div className="compareScores-outerBox margin-top: 50px">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[7].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">
              Old: {props.game.correct}
            </div>
            <div className="compareScores-innerBox">
              New: {reduxState.stats.hits}
            </div>
          </div>
        </div>
        <div className="compareScores-outerBox">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[8].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">
              Old: {props.game.incorrect}
            </div>
            <div className="compareScores-innerBox">
              New: {reduxState.stats.misses}
            </div>
          </div>
        </div>
        <div className="compareScores-outerBox">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[13].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">
              Old: {props.game.score}
            </div>
            <div className="compareScores-innerBox">
              New: {reduxState.stats.score}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.game.scoreType === "timeAndHits") {
    return (
      <div>
        <div className="compareScores-outerBox margin-top: 50px">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[15].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">Old: {props.game.time}</div>
            <div className="compareScores-innerBox">
              New: {props.time}
            </div>
          </div>
        </div>
        <div className="compareScores-outerBox">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[7].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">
              Old: {props.game.correct}
            </div>
            <div className="compareScores-innerBox">
              New: {reduxState.stats.hits}
            </div>
          </div>
        </div>
        <div className="compareScores-outerBox">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[8].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">
              Old: {props.game.incorrect}
            </div>
            <div className="compareScores-innerBox">
              New: {reduxState.stats.misses}
            </div>
          </div>
        </div>
        <div className="compareScores-outerBox">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[13].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">
              Old: {props.game.score}
            </div>
            <div className="compareScores-innerBox">
              New: {reduxState.stats.score}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.game.scoreType === "time") {
    return (
      <div>
        <div className="compareScores-outerBox margin-top: 50px">
          <div className="compareScores-outerBoxHeader">
            {reduxState.uiWords[15].salish}
          </div>
          <div className="compareScores-innerBoxContainer">
            <div className="compareScores-innerBox">Old: {props.game.time}</div>
            <div className="compareScores-innerBox">
              New: {props.time}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const  CompareScores = props => {
  const reduxState = useSelector(selectReduxSlice);
  const stats = reduxState.stats;
  let gameInfo = reduxState.goldStar.find(
    (datum) => datum.game === reduxState.specificGame.game
  );
  gameInfo = {
    game: gameInfo.game,
    id: gameInfo.id,
    notImplimented: gameInfo.notImplimented,
    scoreType: gameInfo.scoreType,
    ratings: [
      {
        type: gameInfo.type,
        fiveStar: Number(gameInfo.fiveStar),
        fourStar: Number(gameInfo.fourStar),
        threeStar: Number(gameInfo.threeStar),
        twoStar: Number(gameInfo.twoStar),
        oneStar: Number(gameInfo.oneStar),
        stars: 0,
        nextGoals: null,
      },
      {
        type: gameInfo.secondType,
        fiveStar: Number(gameInfo.fiveStarTwo),
        fourStar: Number(gameInfo.fourStarTwo),
        threeStar: Number(gameInfo.threeStarTwo),
        twoStar: Number(gameInfo.twoStarTwo),
        oneStar: Number(gameInfo.oneStarTwo),
        stars: 0,
        nextGoals: null,
      },
    ],
  };
  gameInfo.ratings = gameInfo.ratings.filter((d) => d.type !== 'none');
  const checkFuncObj = {
    correct: (val) => stats.score >= val,
    time: (val) => props.time <= val,
    incorrect: (val) => stats.misses <= val,
    accuracy: (val) => stats.hits / (stats.hits + stats.misses) >= val,
  };
  gameInfo.ratings.map((d) => {
    let checkFunc = checkFuncObj[d.type];
    if (checkFunc(d.fiveStar)) {
      d.stars = 5;
    } else if (checkFunc(d.fourStar)) {
      d.stars = 4;
      d.nextGoals = d.fiveStar;
    } else if (checkFunc(d.threeStar)) {
      d.stars = 3;
      d.nextGoals = d.fourStar;
    } else if (checkFunc(d.twoStar)) {
      d.stars = 2;
      d.nextGoals = d.threeStar;
    } else if (checkFunc(d.oneStar)) {
      d.stars = 1;
      d.nextGoals = d.twoStar;
    } else {
      d.nextGoals = d.oneStar;
    }
    if (d.type === "incorrect" && stats.hits <= 0) {
      d.stars = 0;
    }
  });
  let object = {
    score: stats.score,
    time: props.time,
    correct: stats.hits,
    incorrect: stats.misses,
    id: props.gameID,
    stars: gameInfo.ratings[0].stars,
    firstStars: gameInfo.ratings[0].stars,
    firstNextGoals: gameInfo.ratings[0].nextGoals,
  };
  if (gameInfo.ratings.length > 1) {
      object.stars = gameInfo.ratings[0].stars + gameInfo.ratings[1].stars;
      object.secondStars = gameInfo.ratings[1].stars;
      object.secondNextGoals = gameInfo.ratings[1].nextGoals;
  }
  let game = props.scoreData.find((datum) => datum.id === props.gameID);
  return (
    <div className="compareScores-fullscreen">
      <DisplayScoreCompare game={game} time={props.time} />
      {game.score === 0 && game.time === 0 ? (
        <div className="compareScores-dialogBox">
          <div className="compareScores-dialogText">
            You played a new game!
          </div>
          <div className="compareScores-dialogText">
            Your score has been recorded!
          </div>
          <div className="compareScores-buttonContainer">
            <div
              className="compareScores-button compareScores-buttonYes"
              onClick={() => {
                props.hideCompare();
                props.updateScoreData(object);
              }}>
              <div>Continue</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="compareScores-dialogBox">
          <div className="compareScores-dialogText">
            Do you want to overwrite your previous scores?
          </div>
          <div className="compareScores-buttonContainer">
            <div
              className="compareScores-button compareScores-buttonNo"
              onClick={() => {
                props.hideCompare();
              }}>
              <div>No</div>
            </div>
            <div
              className="compareScores-button compareScores-buttonYes"
              onClick={() => {
                props.hideCompare();
                props.updateScoreData(object);
              }}>
              <div>Yes</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompareScores;

CompareScores.propTypes = {
  gameID: PropTypes.number,
  hideCompare: PropTypes.func,
  scoreData: PropTypes.array,
  time: PropTypes.number,
  updateScoreData: PropTypes.func,
};

DisplayScoreCompare.propTypes = {
  game: PropTypes.object,
  time: PropTypes.number,
};

{/* Checked PropTypes */}