import './index.css';
import React from "react";
import PropTypes from "prop-types";
import GameSelectButton from "../../GameSelectButton";
import {fetchImage} from "../../nonUIFuncs";
import { selectReduxSlice } from "../../../Store/store";
import { useSelector } from "react-redux";

const GameSelect = props => {
  const reduxState = useSelector(selectReduxSlice);
  return (
    <div className="fullScreenColor">
      <div className="EM-topBanner">Choose A Game</div>
      <div className="EM-GS-container">
        {props.games.map((datum) => {
          let randomNumber = Math.floor(Math.random() * reduxState.data.length);
          let gameSpecLvlData = props.levelData.filter(game => game.spreadGameType === datum);
          let bestEasy = gameSpecLvlData[0];
          let bestMedium = gameSpecLvlData[0];
          let bestHard = gameSpecLvlData[0];
          if (gameSpecLvlData.length === 0) {
            return null;
          }
          gameSpecLvlData.map(game => {
            if (game.levelEasy > bestEasy.levelEasy) {
              bestEasy = game;
            }
            if (game.levelMedium > bestMedium.levelMedium) {
              bestMedium = game;
            }
            if (game.levelHard > bestHard.levelHard) {
              bestHard = game;
            }
          });
          return (
            <div
              id={datum}
              className="EM-GS-buttonContainer"
              onClick={() => props.findCategories(datum)}
              key={datum}>
              <GameSelectButton
                // If there are ever images for game types those can be added to the data
                // until then it will be chosen randomly from the given background images
                buttonBackground={fetchImage(
                  reduxState.data[randomNumber].backgroundImg
                )}
                uniqueCategoryName={datum}
              />
              {bestEasy.levelEasy > 0 ? (
                <div className="EM-GS-difficultyDisplay easyColor">
                  <div className="EM-GS-scoreText">Best Easy</div>
                  <div>
                    {bestEasy.category.length === 1
                      ? bestEasy.category[0]
                      : bestEasy.gameType}
                    : Lvl {bestEasy.levelEasy}
                  </div>
                </div>
              ) : (
                <div className="EM-GS-difficultyDisplay easyColor">
                  <div>No score for Easy difficulty</div>
                </div>
              )}
              {bestMedium.levelMedium > 0 ? (
                <div className="EM-GS-difficultyDisplay mediumColor">
                  <div className="EM-GS-scoreText">Best Medium</div>
                  <div>
                    {bestMedium.category.length === 1
                      ? bestMedium.category
                      : bestMedium.gameType}
                    : Lvl {bestMedium.levelMedium}
                  </div>
                </div>
              ) : (
                <div className="EM-GS-difficultyDisplay mediumColor">
                  <div>No score for Medium difficulty</div>
                </div>
              )}
              {bestHard.levelHard > 0 ? (
                <div className="EM-GS-difficultyDisplay hardColor">
                  <div className="EM-GS-scoreText">Best Hard</div>
                  <div>
                    {bestHard.category.length === 1
                      ? bestHard.category
                      : bestHard.gameType}
                    : Lvl {bestHard.levelHard}
                  </div>
                </div>
              ) : (
                <div className="EM-GS-difficultyDisplay hardColor">
                  <div>No score for Hard difficulty</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GameSelect;

GameSelect.propTypes = {
  findCategories: PropTypes.func,
  games: PropTypes.array,
  levelData: PropTypes.array,
};

{/* Checked PropTypes */}