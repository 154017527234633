import './index.css';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import LoadingScreen from "../LoadingScreen/index";
import BottomStatBar from "../BottomStatBar/index";
import {shuffle, fetchAudio, fetchImage} from "../nonUIFuncs";
import PopupWord from '../Components/popupWord';
import FloatingNumber from '../Components/floatingNumber';
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

import StatScreen from '../StatScreen';
import Card from './Card';

let difficulty = "easy";
let displayedClock = 0;
let shortTimer = 0;
let distractorNumber = 2;
let formattedData = {
	cards:{},
	cardLocations: {},
	cardLocationOrder: [],
};
let totalBlankCards = 2;
let originalFormattedData = {};
let storySentences = [];
let allPossibleVocab = [];
let wordBank = [];
let currentPage = "";
let splitSentence = null;
let pageNumber = 1;
let sentenceAudio = undefined;
let listening = false;
let switchDisplayVar = "loading";
let showPopup = false;
let background = '';

const SwitchDisplay = props => {
  const reduxState = useSelector(selectReduxSlice);
  if (switchDisplayVar === "loading") {
    return <LoadingScreen />;
  }
  if (switchDisplayVar === "stats") {
    if (shortTimer === 4) {
      props.stepDifficulty();
    }
    return (
      <StatScreen scoreType="correct" />
    );
  } else if (switchDisplayVar === "game") {
    return (
      <div className="centerContentContainer">
        <div className="FITB-plusMinusContainer">
          <FloatingNumber correctness={props.correctness} />
        </div>
        {currentPage ? <DisplayData /> : null}
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        <BottomStatBar>
          <div>{difficulty.charAt(0).toUpperCase() + difficulty.slice(1)}</div>
          <div className="FITB-BSB-container">
            {reduxState.uiWords[13].salish}: {props.roundStats.score}
          </div>
          <div className="FITB-BSB-container">
            {reduxState.uiWords[15].salish}: {props.time}
          </div>
        </BottomStatBar>
      </div>
    );
  } else {
    return null;
  }
};

const DisplayData = () => {
  let cardOptions = formattedData.cardLocationOrder.map(
    (cardLocationId, index) => {
      const cardLocation = formattedData.cardLocations[cardLocationId];
      const content = cardLocation.contentId.map(
        (cardId) => formattedData.cards[cardId]
      );
      return (
        <Card
          cardLocation={cardLocation}
          content={content}
          indexed={index}
          key={cardLocation.id}
        />
      );
    }
  );
  let solveSpots = cardOptions.slice(0, totalBlankCards);
  let i = 0;
  for (i = 0; i < totalBlankCards; i++) {
    cardOptions.shift();
  }
  let j = 0;
  let mergedArrays = splitSentence.map((string) => {
    if (string === "_") {
      let newString = solveSpots[j];
      j = j + 1;
      return newString;
    } else {
      return (
        <div
          className="fillInTheBlank-aboriginal FITB-plainText"
          key={string}
        >
          {string}
        </div>
      );
    }
  });
  return (
    <div className="FITB-container">
      <div className="FITB-placemat">{mergedArrays}</div>
      <div className="FITB-cardOptionsContainer">{cardOptions}</div>
    </div>
  );
};

const FillInTheBlankGame = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const reduxStatsRef = useRef();
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [correctness, setCorrectness] = useState(null);
  const [quicktime, setQuicktime] = useState(0);
  reduxStatsRef.current = reduxState.stats;
  useEffect(() => {
    let timer = setInterval(updateDisplayClock, 1000);
    listening = false;
    switchDisplayVar = "game";
    difficulty = props.difficulty;
    storySentences = reduxState.storybookSentence.filter(
      (datum) => datum.target === reduxState.specificGame.target
    );
    storySentences.map((datum) => {
      if (datum.phaseOneWord !== "") {
        allPossibleVocab = [...allPossibleVocab, datum.phaseOneWord];
        if (datum.phaseTwoWord !== "") {
          allPossibleVocab = [...allPossibleVocab, datum.phaseTwoWord];
          if (datum.phaseThreeWord !== "") {
            allPossibleVocab = [...allPossibleVocab, datum.phaseThreeWord];
          }
        }
      }
    });
    pageNumber = Number(storySentences[0].page);
    gatherData();
    return () => {
      listening = false;
      if (switchDisplayVar === "stats") {
        switchDisplayVar = "game";
      }
      if (sentenceAudio !== undefined) {
        sentenceAudio.pause();
        sentenceAudio = undefined;
      }
      clearInterval(timer);
      displayedClock = 0;
      resetData();
    };
  }, []);
  const updateDisplayClock = () => {
    displayedClock = displayedClock + 1;
    shortTimer = shortTimer + 1;
  };
  const gatherData = () => {
    resetData();
    currentPage = storySentences.find((datum) => datum.page == pageNumber);
    if (currentPage !== undefined) {
      sentenceAudio = new Audio(`${fetchAudio(currentPage.audio)}`);
    }
    let pageVocab = [];
    if (currentPage === undefined) {
      shortTimer = 0;
      switchDisplayVar = "stats";
    } else {
      if (difficulty === "easy") {
        distractorNumber = 3;
        totalBlankCards = 1;
        pageVocab.push(currentPage.phaseOneWord);
        splitSentence = currentPage.phaseOneSentence.split(/([_])\w+/);
      } else if (difficulty === "medium") {
        if (Number(currentPage.phaseNum) >= 2) {
          distractorNumber = 2;
          totalBlankCards = 2;
          pageVocab.push(currentPage.phaseOneWord, currentPage.phaseTwoWord);
          splitSentence = currentPage.phaseTwoSentence.split(/([_])\w+/);
        } else {
          distractorNumber = 3;
          totalBlankCards = 1;
          pageVocab.push(currentPage.phaseOneWord);
          splitSentence = currentPage.phaseOneSentence.split(/([_])\w+/);
        }
      } else {
        if (Number(currentPage.phaseNum) === 3) {
          distractorNumber = 1;
          totalBlankCards = 3;
          pageVocab.push(
            currentPage.phaseOneWord,
            currentPage.phaseTwoWord,
            currentPage.phaseThreeWord
          );
          splitSentence = currentPage.phaseThreeSentence.split(/([_])\w+/);
        } else if (Number(currentPage.phaseNum) === 2) {
          distractorNumber = 2;
          totalBlankCards = 2;
          pageVocab.push(currentPage.phaseOneWord, currentPage.phaseTwoWord);
          splitSentence = currentPage.phaseTwoSentence.split(/([_])\w+/);
        } else {
          distractorNumber = 3;
          totalBlankCards = 1;
          pageVocab.push(currentPage.phaseOneWord);
          splitSentence = currentPage.phaseOneSentence.split(/([_])\w+/);
        }
      }
      let possibleDistractors = [];
      possibleDistractors = allPossibleVocab.filter(
        (datum) => datum !== pageVocab[0]
      );
      if (pageVocab[1]) {
        possibleDistractors = allPossibleVocab.filter(
          (datum) => datum !== pageVocab[1]
        );
      }
      if (pageVocab[2]) {
        possibleDistractors = allPossibleVocab.filter(
          (datum) => datum !== pageVocab[2]
        );
      }
      shuffle(possibleDistractors);
      let distractors = possibleDistractors.slice(0, distractorNumber);
      wordBank = distractors.concat(pageVocab);
      shuffle(wordBank);
      switchDisplayVar = "game";
      pageNumber = pageNumber + 1;
      formatData();
    }
  };
  const formatData = () => {
    let blankSpots = 0;
    while (blankSpots < totalBlankCards) {
      blankSpots = blankSpots + 1;
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`solveSpot${blankSpots}`]: {
          id: `solveSpot${blankSpots}`,
          contentId: [],
        },
      };
      formattedData.cardLocationOrder.push(`solveSpot${blankSpots}`);
    }
    let i = 0;
    wordBank.map((datum) => {
      formattedData.cards = {
        ...formattedData.cards,
        [`cardDraggable${i}`]: {
          id: `cardDraggable${i}`,
          content: datum,
        },
      };
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`location${i}`]: {
          id: `location${i}`,
          contentId: [`cardDraggable${i}`],
        },
      };
      formattedData.cardLocationOrder.push(`location${i}`);
      i = i + 1;
    });
    originalFormattedData = formattedData;
  };
  const resetData = () => {
    wordBank = [];
    formattedData = {
      cards: {},
      cardLocations: {},
      cardLocationOrder: [],
    };
  };
  const hideDiv = () => {
    setCorrectness(null);
  };
  const stepDifficulty = () => {
    resetData();
    switchDisplayVar = "game";
    pageNumber = 1;
    displayedClock = 0;
    if (difficulty === "easy") {
      difficulty = "medium";
      gatherData();
    } else if (difficulty === "medium") {
      difficulty = "hard";
      gatherData();
    } else if (difficulty === "hard") {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let begin = formattedData.cardLocations[source.droppableId];
    let end = formattedData.cardLocations[destination.droppableId];
    let beginContent = begin.contentId;
    let endContent = end.contentId;
    const newBegin = {
      ...begin,
      contentId: endContent,
    };
    const newEnd = {
      ...end,
      contentId: beginContent,
    };

    const newFormattedData = {
      ...formattedData,
      cardLocations: {
        ...formattedData.cardLocations,
        [newBegin.id]: newBegin,
        [newEnd.id]: newEnd,
      },
    };
    formattedData = newFormattedData;
    setQuicktime(quicktime + 1);
    if (difficulty === "easy") {
      if (formattedData.cardLocations.solveSpot1.contentId.length !== 0) {
        listening = true;
        sentenceAudio.play();
        sentenceAudio.onended = checkSentence;
      }
    } else if (difficulty === "medium") {
      if (Number(currentPage.phaseNum) >= 2) {
        if (
          formattedData.cardLocations.solveSpot2.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot1.contentId.length !== 0
        ) {
          listening = true;
          sentenceAudio.play();
          sentenceAudio.onended = checkSentence;
        }
      } else if (
        formattedData.cardLocations.solveSpot1.contentId.length !== 0
      ) {
        listening = true;
        sentenceAudio.play();
        sentenceAudio.onended = checkSentence;
      }
    } else {
      if (Number(currentPage.phaseNum) === 3) {
        if (
          formattedData.cardLocations.solveSpot3.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot2.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot1.contentId.length !== 0
        ) {
          listening = true;
          sentenceAudio.play();
          sentenceAudio.onended = checkSentence;
        }
      } else if (Number(currentPage.phaseNum) === 2) {
        if (
          formattedData.cardLocations.solveSpot2.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot1.contentId.length !== 0
        ) {
          listening = true;
          sentenceAudio.play();
          sentenceAudio.onended = checkSentence;
        }
      } else if (
        formattedData.cardLocations.solveSpot1.contentId.length !== 0
      ) {
        listening = true;
        sentenceAudio.play();
        sentenceAudio.onended = checkSentence;
      }
    }
  };
  const checkSentence = () => {
    listening = false;
    let originalSentence = currentPage.fullSentence;
    let firstFilledId = formattedData.cardLocations.solveSpot1.contentId[0];
    let firstWord = formattedData.cards[firstFilledId].content;
    let secondFilledId = null;
    let secondWord = null;
    let thirdFilledId = null;
    let thirdWord = null;
    if (formattedData.cardLocations.solveSpot2) {
      secondFilledId = formattedData.cardLocations.solveSpot2.contentId[0];
      secondWord = formattedData.cards[secondFilledId].content;
      if (formattedData.cardLocations.solveSpot3) {
        thirdFilledId = formattedData.cardLocations.solveSpot3.contentId[0];
        thirdWord = formattedData.cards[thirdFilledId].content;
      }
    }
    let i = 0;
    let fixingSentence = splitSentence.map((string) => {
      if (string === "_") {
        let newString = null;
        if (i === 0) {
          newString = firstWord;
          i = i + 1;
        } else if (i === 1) {
          newString = secondWord;
          i = i + 1;
        } else if (i === 2) {
          newString = thirdWord;
          i = i + 1;
        }
        return newString;
      } else {
        return string;
      }
    });
    let fixedSentence = fixingSentence.join("");
    if (fixedSentence === originalSentence) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
      dispatch(
        setStats({
          hits: reduxStatsRef.current.hits + 1,
          score: reduxStatsRef.current.score + 1,
          roundStats: {
            hits: reduxStatsRef.current.roundStats.hits + 1,
            score: reduxStatsRef.current.roundStats.score + 1,
          },
        })
      );
      gatherData();
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('wrong');
      dispatch(
        setStats({
          misses: reduxStatsRef.current.misses + 1,
          score: reduxStatsRef.current.score - 1,
          roundStats: {
            misses: reduxStatsRef.current.roundStats.misses + 1,
            score: reduxStatsRef.current.roundStats.score - 1,
          },
        })
      );
      resetData();
      formattedData = originalFormattedData;
    }
    setTimeout(hideDiv, 1000);
  };
  let backgroundImage = reduxState.specificGame.backgroundImg;
  if (currentPage) {
    backgroundImage = currentPage.image;
    if (!background.includes(backgroundImage)) {
      background = `url(${fetchImage(backgroundImage)})`;
    }
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        id="fullScreen"
        style={{ backgroundImage: background }}
        className="fullScreen overflow-y: hidden;">
        <SwitchDisplay
          chosenPopupWord={chosenPopupWord}
          correctness={correctness}
          roundStats={reduxStatsRef.current.roundStats}
          stepDifficulty={stepDifficulty}
          time={props.time}
        />
      </div>
      {listening ? <div className="FITB-fullScreen" /> : null}
    </DragDropContext>
  );
}

export default FillInTheBlankGame;

FillInTheBlankGame.propTypes = {
  checkWorld: PropTypes.func,
  difficulty: PropTypes.string,
  storySentences: PropTypes.array,
  time: PropTypes.number,
  uiWords: PropTypes.array,
};

SwitchDisplay.propTypes = {
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  displayData: PropTypes.func,
  roundStats: PropTypes.object,
  stepDifficulty: PropTypes.func,
  time: PropTypes.number,
  uiWords: PropTypes.array,
};

{/* Checked PropTypes */}