import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { getImageSize, fetchImage } from "../nonUIFuncs";

import WhackAMoleGame from "../WhackAMoleGame";
import DisplayScreen from '../Components/displayScreen';
import BackButton from "../Components/backButton";
import { useDispatch, useSelector } from 'react-redux';
import {
  setInitialGameData,
  getScreen,
  selectReduxSlice,
} from "../../Store/store";

let backgroundHeight = 1.5;
let backgroundWidth = 1.5;

const WhackAMole = props => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const routeLocation = useLocation();
  const scoreType = 'normal';
  useEffect(() => {
    dispatch(
      setInitialGameData(
        routeLocation,
        reduxState.data,
        reduxState.gameData,
        undefined,
        "Whack A Mole"
      )
    );
  }, []);
  useEffect(() => {
    if (reduxState.specificGame !== undefined) {
      collectImageData();
    }
  }, [reduxState.specificGame]);
  const collectImageData = () => {
    let backgroundImg = new Image();
    backgroundImg.src = `${fetchImage(reduxState.specificGame.backgroundImg)}`;
    backgroundImg.onload = () => {
      backgroundHeight = getImageSize(backgroundImg).height;
      backgroundWidth = getImageSize(backgroundImg).width;
    };
  };
  let gameComponent = (
    <WhackAMoleGame
      backgroundHeight={backgroundHeight}
      backgroundWidth={backgroundWidth}
      checkWorld={() => dispatch(getScreen("playAgainScreen"))}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={() => dispatch(getScreen("startScreen"))}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={() => dispatch(getScreen("gameScreen"))}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default WhackAMole;

WhackAMole.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}