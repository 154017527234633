import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import BubblePopGame from "../BubblePopGame";
import DisplayScreen from '../Components/displayScreen';
import BackButton from "../Components/backButton";

import { useDispatch, useSelector } from "react-redux";
import { getScreen, setInitialGameData, selectReduxSlice } from "../../Store/store";

const BubblePop = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  let routeLocation = useLocation();
  const scoreType = 'normal';
  useEffect(() => {
    dispatch(setInitialGameData(
      routeLocation,
      reduxState.data,
      reduxState.gameData,
      undefined,
      "Bubble Pop",
    ));
  }, []);
  let gameComponent = (
    <BubblePopGame
      checkWorld={() => dispatch(getScreen('playAgainScreen'))}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={() => dispatch(getScreen('startScreen'))}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={() => dispatch(getScreen('gameScreen'))}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default BubblePop;

BubblePop.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}