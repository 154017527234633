import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import GoodGuessGame from "../GoodGuessGame";
import DisplayScreen from "../Components/displayScreen";
import BackButton from "../Components/backButton";

import { setInitialGameData, getScreen, selectReduxSlice } from "../../Store/store";
import { useDispatch, useSelector } from "react-redux";

const GoodGuess = props => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const routeLocation = useLocation();
  const [gameid, setGameid] = useState(0);
  const scoreType = 'normal';
  useEffect(() => {
    dispatch(setInitialGameData(
      routeLocation,
      reduxState.data,
      reduxState.gameData,
      undefined,
      'Good Guess',
    ));
  }, []);
  const startGame = () => {
    setGameid(gameid + 1);
    dispatch(getScreen('gameScreen'));
  };
  let gameComponent = (
    <GoodGuessGame
      checkWorld={() => dispatch(getScreen('playAgainScreen'))}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={() => dispatch(getScreen("startScreen"))}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={startGame}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
}

export default GoodGuess;

GoodGuess.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}