import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import CountingGame from "../CountingGame";
import DisplayScreen from "../Components/displayScreen";
import BackButton from "../Components/backButton";

import { useDispatch, useSelector } from "react-redux";
import { getScreen, setInitialGameData, selectReduxSlice } from "../../Store/store";

let gameTime = 0;

const Counting = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  let routeLocation = useLocation();
  const gameClockRef = useRef();
  const numberDataRef = useRef();
  const [gameClock, setGameClock] = useState(0);
  const [numberData, setNumberData] = useState([]);
  const scoreType = "timeAndHits";
  gameClockRef.current = gameClock;
  numberDataRef.current = numberData;
  useEffect(() => {
    setNumberData(
      reduxState.gameData.filter((datum) => datum.subCat === "Numbers and Age")
    );
    let gameTimer = setInterval(
      () => setGameClock(gameClockRef.current + 1),
      1000
    );
    dispatch(setInitialGameData(
      routeLocation,
      reduxState.data,
      reduxState.gameData,
      reduxState.storybookVocab,
      'Counting',
    ));
    return () => clearInterval(gameTimer);
  }, []);
  const startGame = () => {
    setGameClock(0);
    dispatch(getScreen('gameScreen'));
  };
  const clearGameStats = () => {
    setGameClock(0);
    dispatch(getScreen('startScreen'));
  };
  const checkWorld = () => {
    gameTime = gameClockRef.current - 1;
    dispatch(getScreen('playAgainScreen'));
  };
  let gameComponent = (
    <CountingGame
      checkWorld={checkWorld}
      numberData={numberDataRef.current}
      scoreType={scoreType}
      time={gameClock}
      updateLevelData={props.updateLevelData}
    />
  );
  return (
    <div>
      <BackButton />
      <DisplayScreen
        clearGameStats={clearGameStats}
        gameComponent={gameComponent}
        scoreData={props.scoreData}
        scoreType={scoreType}
        startGame={startGame}
        time={gameTime}
        updateScoreData={props.updateScoreData}
      />
    </div>
  );
};

export default Counting;

Counting.propTypes = {
  scoreData: PropTypes.array,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
};

{/* Checked PropTypes */}
