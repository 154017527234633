import "./index.css";
import React from "react";
import PropTypes from "prop-types";
import {fetchImage} from '../nonUIFuncs';
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";

const GameSelectButton = props => {
  const reduxState = useSelector(selectReduxSlice);
  let btnImage = {
    backgroundImage: `url(${props.buttonBackground})`,
    margin: props.margin || 0,
  };
  if (props.parent && props.parent === 'gameButtons') {
    let dataSource = props.allData || reduxState.data;
    let findCategory = dataSource.find(datum => datum.categorySalish === props.uniqueCategoryName);
    if (findCategory === undefined) {
      findCategory = dataSource.find(datum => datum.category === props.uniqueCategoryName);
    }
    let badge = findCategory.badge;
    return (
      <div style={btnImage} className="gameButton-outerBox">
        <div style={{position: "absolute"}} className="gameButton-headerText noselect GSB-dims">
          <div className='gameButton-badgeDisplay' style={{backgroundImage: `url(${fetchImage(badge)})`}}/>
          <div className="gameButton-textStyle noselect GSB-text">
            {props.uniqueCategoryName}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={btnImage} className="gameButton-outerBox">
        <div  className="gameButton-headerText noselect GSB-dims">
          <div className="gameButton-textStyle noselect GSB-text">
            {props.uniqueCategoryName}
          </div>
        </div>
      </div>
    );
  }
}

export default GameSelectButton;

GameSelectButton.propTypes = {
  allData: PropTypes.array,
  buttonBackground: PropTypes.string,
  parent: PropTypes.string,
  uniqueCategoryName: PropTypes.string.isRequired,
  margin: PropTypes.number || PropTypes.string,
};

{/* Checked PropTypes */}