import './index.css';
import '../../App.css';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StatScreen from '../StatScreen';
import Timer from '../Timer';
import BottomStatBar from '../BottomStatBar/index';
import InvisiBar from '../Invisibar/index';
import {shuffle, placementArray, fetchImage, fetchAudio} from '../nonUIFuncs';
import PopupWord from '../Components/popupWord';
import FloatingNumber from '../Components/floatingNumber';
import LevelBar from "../Components/endlessLevelBar";
import EndEndlessGame from '../Components/endEndlessMode';
import CloseEndless from "../Components/endlessCloseButton";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";
import { useLocation } from 'react-router-dom';

let displayArray = []
let displayArrayDenom = [3,3]
let currentTarget = {}
let imageArray = [];
let gameDOMArray = []
let targetAudio = undefined
let mouseClickX = 0
let mouseClickY = 0
let correctSoundEffect = null
let subCatAudio = []
let testNum = 0;
let showPopup = false;
let roundtime = 0;
let background = null;
let keywordImage = null;

const SwitchDisplay = props => {
  const [showEndlessEnd, setShowEndlessEnd] = useState();
  const reduxState = useSelector(selectReduxSlice);
  roundtime = 30;
  if (props.display === "stats") {
    if (targetAudio !== undefined) {
      targetAudio[0].playableAudio.pause();
      targetAudio[0].currentTime = 0;
      targetAudio = undefined;
    }
    if (props.displayedClock === roundtime + 3) {
      props.stepDifficulty();
    }
    return <StatScreen scoreType="hits" />;
  } else if (props.display === "game") {
    if (
      keywordImage === null ||
      !keywordImage.includes(reduxState.specificGame.keyWordImg)
    ) {
      keywordImage = `url(${fetchImage(reduxState.specificGame.keyWordImg)})`;
    }
    let keywordImgStyle = {
      backgroundImage: keywordImage,
      width: reduxState.width * 0.75,
      height: reduxState.width * (8.33 / 100),
    };
    if (reduxState.width * 0.75 > reduxState.height * 1.35) {
        keywordImgStyle.width = reduxState.height * 1.35;
        keywordImgStyle.height = reduxState.height * 0.2;
    }
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      props.gameDifficultyDisplay();
    }
    return (
      <div className="centerContentContainer">
        <div
          className="GGG-plusMinusContainer"
          style={{
            position: "absolute",
            top: mouseClickY - reduxState.height / 10,
            left: mouseClickX,
            zIndex: 99999,
          }}
        >
          <FloatingNumber correctness={props.correctness} />
        </div>
        <div
          className="goodGuessGame-keyWordBox"
          style={keywordImgStyle}
          onClick={props.playTargetAudio}
        >
          <div className="keyword noselect GGG-keyword">{props.keyword}</div>
        </div>
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        {gameDOMArray}
        <CloseEndless enabled={props.endless} onClick={setShowEndlessEnd} />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={props.currentLevel}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={props.updateLevelData}
        />
        <InvisiBar
          enabled={props.endless}
          showEndlessEnd={() => setShowEndlessEnd(true)}
        />
      </div>
    );
  } else {
    return null;
  }
};

const GoodGuessGame = props => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();

  const displayedClockRef = useRef();
  const difficultyRef = useRef();
  const currentLevelRef = useRef();
  const currentLevelProgressRef = useRef();
  const reduxStateRef = useRef();

  const [keyword, setKeyword] = useState('');
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [display, setDisplay] = useState('game');
  const [difficulty, setDifficulty] = useState('easy');
  const [correctness, setCorrectness] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [previousTarget, setPreviousTarget] = useState(undefined);
  const [displayedClock, setDisplayedClock] = useState(0);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);

  difficultyRef.current = difficulty;
  displayedClockRef.current = displayedClock;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  
  useEffect(() => {
    let timer = setInterval(updateDisplayClock, 1000);
    let shortTimer = setInterval(callNewDisplay, 3000);
    setCurrentLevelProgress(0);
    setDifficulty(reduxState.difficulty);
    setDisplay('game');
    reduxState.sortedData.map((datum) => {
      subCatAudio.push({
        playableAudio: new Audio(`${fetchAudio(datum.audio1)}`),
        image: datum.image1,
      });
    });
    changeDisplay(difficultyRef.current);
    correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
    return () => {
      setDisplay("game");
      setDifficulty('easy');
      clearInterval(timer);
      clearInterval(shortTimer);
      setDisplayedClock(0);
      gameDOMArray = [];
      if (targetAudio !== undefined) {
        targetAudio[0].playableAudio.pause();
        targetAudio[0].currentTime = 0;
        targetAudio = undefined;
      }
    };
  }, []);
  const callNewDisplay = () => {
    if (displayedClockRef.current < 30) {
      changeDisplay(difficultyRef.current);
    }
  };
  const updateDisplayClock = () => {
    if (routeLocation.state.endless !== true) {
      setDisplayedClock(displayedClockRef.current + 1);
    }
  };
  const changeDisplay = (dif) => {
    setPreviousTarget(currentTarget);
    imageArray = [];
    let shuffled = shuffle(reduxState.sortedData);
    currentTarget = shuffled[0];
    if (previousTarget !== undefined) {
      while (previousTarget.english === currentTarget.english) {
        shuffled = shuffle(reduxState.sortedData);
        currentTarget = shuffled[0];
      }
    }
    if (targetAudio !== undefined) {
      targetAudio[0].playableAudio.pause();
      targetAudio[0].currentTime = 0;
      targetAudio = undefined;
    }
    targetAudio = subCatAudio.filter(
      (datum) => datum.image === currentTarget.image1
    );
    targetAudio[0].playableAudio.play();
    setKeyword(currentTarget.salish);
    let correctAmount = 2;
    let i = 0;
    let distractorAmount = 1;
    if (dif === "easy") {
      correctAmount = Math.floor(Math.random() * 2) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter(
        (datum) => datum.english !== currentTarget.english
      );
      displayArray = placementArray(1, 2, 3, 4, 1, 1);
      displayArrayDenom = [3, 4];
      distractorAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(
        0,
        distractorAmount
      );
      let doubledDistractors = [];
      slicedDistractorArray.map((datum) => {
        doubledDistractors.push(datum);
        doubledDistractors.push(datum);
      });
      let shuffledDistractors = shuffle(doubledDistractors);
      let finalDistractors = shuffledDistractors.slice(0, distractorAmount);
      finalDistractors.map((datum) => imageArray.push(datum));
    } else if (dif === "medium") {
      correctAmount = Math.floor(Math.random() * 3) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter(
        (datum) => datum.english !== currentTarget.english
      );
      displayArray = placementArray(1, 2, 4, 4, 1, 1);
      displayArrayDenom = [4, 4];
      distractorAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(
        0,
        distractorAmount
      );
      let doubledDistractors = [];
      slicedDistractorArray.map((datum) => {
        doubledDistractors.push(datum);
        doubledDistractors.push(datum);
      });
      let shuffledDistractors = doubledDistractors.sort(
        () => 0.5 - Math.random()
      );
      let finalDistractors = shuffledDistractors.slice(0, distractorAmount);
      finalDistractors.map((datum) => imageArray.push(datum));
    } else if (dif === "hard") {
      correctAmount = Math.floor(Math.random() * 4) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter(
        (datum) => datum.english !== currentTarget.english
      );
      displayArray = placementArray(2, 1.75, 5, 4, 1, 1);
      displayArrayDenom = [6, 5];
      if (reduxState.height > reduxState.width) {
        displayArray = placementArray(1, 1.75, 4, 4, 1, 1);
        displayArrayDenom = [4, 5];
      }
      distractorAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(
        0,
        distractorAmount
      );
      let doubledDistractors = [];
      slicedDistractorArray.map((datum) => {
        doubledDistractors.push(datum);
        doubledDistractors.push(datum);
      });
      let shuffledDistractors = doubledDistractors.sort(
        () => 0.5 - Math.random()
      );
      let finalDistractors = shuffledDistractors.slice(0, distractorAmount);
      finalDistractors.map((datum) => imageArray.push(datum));
    }
    imageArray = shuffle(imageArray);
    gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number.classList.add("levelNumber");
    setTimeout(
      () => number.classList.remove("levelNumber"),
      1200
    );
  };
  const checkCorrectness = (e) => {
    let clickedObject = gameDOMArray.filter(
      (DOMElement) => DOMElement.props.id === Number(e.target.id)
    );
    mouseClickX = e.pageX;
    mouseClickY = e.pageY;
    let clickedObjectImg =
      clickedObject[0].props.children.props.style.backgroundImage;
    let targetImgPath = `url(${fetchImage(currentTarget.image1)})`;
    if (targetImgPath === clickedObjectImg) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (currentLevelRef.current + 2 === progress && routeLocation.state.endless === true) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness("wrong");
    }
    setTimeout(() => setCorrectness(null), 1000);
    gameDOMArray = gameDOMArray.filter(
      (DOMElement) => DOMElement.props.id !== Number(e.target.id)
    );
  };
  const stepDifficulty = () => {
    setDisplay("game");
    setDisplayedClock(0);
    if (difficultyRef.current === "easy") {
      setDifficulty("medium");
      changeDisplay("medium");
    } else if (difficultyRef.current === "medium") {
      setDifficulty("hard");
      changeDisplay("hard");
    } else if (difficultyRef.current === "hard") {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    imageArray.map((datum) => {
      let imgDimm = reduxStateRef.current.width / 6;
      let buffer = 15;
      if (reduxStateRef.current.width > reduxStateRef.current.height) {
        imgDimm = reduxStateRef.current.height / 6;
      }
      if (imgDimm < 50) {
        imgDimm = 50;
      } else if (imgDimm > 375) {
        imgDimm = 375;
      }
      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let vocabContainer = {
          position: "absolute",
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
        };
        let vocabImg = {
          backgroundImage: `url(${fetchImage(datum.image1)})`,
        };
        gameDOMArray.push(
          <div
            key={key}
            id={key}
            style={vocabContainer}
            className="GGG-vocabContainer">
            <div
              id={key}
              style={vocabImg}
              className="GGG-vocab"
              onClick={checkCorrectness}
            />
          </div>
        );
      }
    });
  };
  const playTargetAudio = () => {
    if (targetAudio !== undefined) {
      targetAudio[0].playableAudio.pause();
      targetAudio[0].currentTime = 0;
      targetAudio = undefined;
    }
    targetAudio = subCatAudio.filter(
      (datum) => datum.image === currentTarget.image1
    );
    targetAudio[0].playableAudio.play();
  };
  if (
    background === null ||
    !background.includes(reduxState.specificGame.backgroundImg)
  ) {
    background = `url(${fetchImage(reduxState.specificGame.backgroundImg)})`;
  }
  let fullScreen = {
    backgroundImage: background,
  };
  return (
    <div style={fullScreen} className="fullScreen">
      <SwitchDisplay
        chosenPopupWord={chosenPopupWord}
        correctness={correctness}
        currentLevel={currentLevelRef.current}
        displayedClock={displayedClockRef.current}
        endless={routeLocation.state.endless}
        gameDifficultyDisplay={gameDifficultyDisplay}
        keyword={keyword}
        playTargetAudio={playTargetAudio}
        stepDifficulty={stepDifficulty}
        display={display}
        updateLevelData={props.updateLevelData}
      />
      {display === "game" || routeLocation.state.endless === true ? (
        <BottomStatBar>
          {routeLocation.state.endless === true ? (
            <LevelBar
              difficulty={difficultyRef.current}
              currentLevel={currentLevelRef.current}
              currentLevelProgress={currentLevelProgressRef.current}
            />
          ) : (
            <div className="BottomStatBar-container">
              <div>
                {difficultyRef.current.charAt(0).toUpperCase() +
                  difficultyRef.current.slice(1)}
              </div>
              <div className="endlessBarLevel">
                {reduxState.uiWords[13].salish}:{" "}
                {reduxStateRef.current.stats.roundStats.score}
              </div>
              <Timer
                currentTime={displayedClockRef.current}
                displayStats={() => setDisplay("stats")}
                fullTime={roundtime}
              />
            </div>
          )}
        </BottomStatBar>
      ) : null}
    </div>
  );
}

export default GoodGuessGame;

GoodGuessGame.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
};

SwitchDisplay.propTypes = {
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  currentLevel: PropTypes.number,
  display: PropTypes.string,
  displayedClock: PropTypes.number,
  endless: PropTypes.bool,
  gameDifficultyDisplay: PropTypes.func,
  keyword: PropTypes.string,
  playTargetAudio: PropTypes.func,
  stepDifficulty: PropTypes.func,
  updateLevelData: PropTypes.func,
};

{/* Checked PropTypes */}