import "./index.css";
import React from "react";
import PropTypes from "prop-types";

const ScoreDisplay = props => {
  let gameInfo = props.goldStar.find(
    (game) => game.game === props.score.gameType
  );
  if (gameInfo.scoreType === 'timeAndHits') {
    return (
      <div className="ScD-container">
        <div className="ScD-innerContainerSB">
          <div>
            {props.uiWords[7].salish}: {props.score.correct}
          </div>
          <div>
            {props.uiWords[8].salish}: {props.score.incorrect}
          </div>
        </div>
        <div className="ScD-innerContainerSB">
          <div>{props.uiWords[13].salish}: {props.score.score}</div>
          <div>{props.uiWords[15].salish}: {props.score.time}</div>
        </div>
      </div>
    );
  } else if (gameInfo.scoreType === 'normal') {
    return (
      <div className="ScD-container">
        <div className="ScD-innerContainerSB">
          <div>
            {props.uiWords[7].salish}: {props.score.correct}
          </div>
          <div>
            {props.uiWords[8].salish}: {props.score.incorrect}
          </div>
        </div>
        <div className="ScD-innerContainerC">
          <div>{props.uiWords[13].salish}: {props.score.score}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="ScD-container">
        <div className="ScD-innerContainerC">
          <div>{props.uiWords[15].salish}: {props.score.time}</div>
        </div>
      </div>
    );
  }
}

export default ScoreDisplay;

ScoreDisplay.propTypes = {
  goldStar: PropTypes.array,
  score: PropTypes.object,
  uiWords: PropTypes.array,
};

{/* Checked PropTypes */}