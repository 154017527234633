import "./index.css";
import React from "react";
import PropTypes from "prop-types";

const InvisiBar = props => {
  if (props.enabled) {
    return <div onClick={props.showEndlessEnd} className="invisibar" />;
  }
}

export default InvisiBar;

InvisiBar.propTypes = {
  enabled: PropTypes.bool,
  showEndlessEnd: PropTypes.func,
};

{/* Checked PropTypes */}