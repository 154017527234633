import './index.css';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';

import Table from './Table';
import BottomStatBar from '../BottomStatBar/index';
import {shuffle, fetchImage} from '../nonUIFuncs';
import PopupWord from '../Components/popupWord';
import FloatingNumber from '../Components/floatingNumber';
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

// let formattedData = {
// 	cards:{},
// 	cardLocations: {},
// 	cardLocationOrder: [],
// };
let switchDisplayVar = "loading";
let ready = false;
let remainingCards = [];
let removedCards = [];
let usableData = [];
let newCardID = 0;
let correct = false;
let draggedId = undefined;
let showPopup = false;
let background = `url(${fetchImage("celebrationTalkBackground.png")})`;

const DisplayDroppables = props => {
  let cardOptions = props.formattedData.cardLocationOrder.map((cardLocationId) => {
    let cardLocation = props.formattedData.cardLocations[cardLocationId];
    let content = cardLocation.contentId.map(
      (cardId) => props.formattedData.cards[cardId]
    );
    content = content.filter((datum) => datum !== undefined);
    return (
      <Table
        content={content}
        cardLocation={cardLocation}
        drag={props.drag}
        key={cardLocation.id}
      />
    );
  });
  return cardOptions;
};

const SwitchDisplay = props => {
  if (switchDisplayVar === "loading") {
    return (
      <div className="HG-loadingScreen">
        <div className="HG-loadingText">Loading...</div>
      </div>
    );
  }
  if (switchDisplayVar === "game") {
    return (
      <div className="centerContentContainer">
        <div className="HG-plusMinusContainer">
          <FloatingNumber correctness={props.correctness} />
        </div>
        {ready ? (
          <DisplayDroppables
            drag={props.drag}
            formattedData={props.formattedData}
            setFormattedData={props.setFormattedData}
          />
        ) : null}
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        <BottomStatBar>
          <div className="HG-BSB-stat">
            {props.uiWords[13].salish}: {props.roundStats.score}
          </div>
          <div className="HG-BSB-stat">
            {props.uiWords[15].salish}: {props.time}
          </div>
        </BottomStatBar>
      </div>
    );
  } else {
    return null;
  }
};

// const HistoryGame = props => {
//   return (
//     <div>History Game</div>
//   );
// };
const HistoryGame = props => {
  let setDiv;
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const formattedDataRef = useRef();
  const reduxStatsRef = useRef();
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [correctness, setCorrectness] = useState(null);
  const [drag, setDrag] = useState(false);
  const [formattedData, setFormattedData] = useState({
    cards: {},
    cardLocations: {},
    cardLocationOrder: [],
  });
  formattedDataRef.current = formattedData;
  reduxStatsRef.current = reduxState.stats;
  useEffect(() => {
    gatherData();
    return () => {
      clearTimeout(setDiv);
      setCorrectness(null);
    };
  }, []);

  const gatherData = () => {
    removedCards = [];
    switchDisplayVar = "game";
    usableData = reduxState.historyData.filter(
      (datum) => Number(datum.App) === 1
    );
    usableData = usableData.filter((datum) => datum.data !== "");
    usableData = usableData.map((datum) => {
      if (isNaN(datum.startDate)) {
        if (datum.startDate === "Creation") {
          return {
            ...datum,
            startData: -9999,
            endDate: -9999,
          };
          // datum.startDate = -9999;
          // datum.endDate = -9999;
        } else if (datum.startDate === "Time Immemorial") {
          // datum.startDate = -9999;
          // datum.endDate = 9999;
          return {
            ...datum,
            startData: -9999,
            endDate: 9999,
          };
        } else if (datum.startDate === "July 1855") {
          // datum.startDate = 1855;
          // datum.endDate = 1855;
          return {
            ...datum,
            startData: 1855,
            endDate: 1855,
          };
        } else if (datum.startDate === "October 1855") {
          // datum.startDate = 1855;
          // datum.endDate = 1855;
          return {
            ...datum,
            startData: 1855,
            endDate: 1855,
          };
        } else {
          return datum;
        }
      } else {
        return datum;
      }
    });
    let shuffledData = shuffle(usableData);
    let cardsToRemove = 5;
    let i;
    for (i = 0; i < cardsToRemove; i++) {
      let newDatum = shuffledData[0];
      shuffledData = shuffledData.filter((datum) => datum.ID !== newDatum.ID);
      removedCards.push(newDatum);
    }
    remainingCards = shuffledData;
    formatData();
  };

  const formatData = () => {
    formattedDataRef.current.cardLocations = {
      table: {
        id: "table",
        title: "Table",
        contentId: [],
      },
      hand: {
        id: "hand",
        title: "Your Hand",
        contentId: [],
      },
    };
    formattedDataRef.current.cardLocationOrder = ["table", "hand"];
    let i = 0;
    removedCards.map((datum) => {
      formattedDataRef.current.cards = {
        ...formattedDataRef.current.cards,
        [`cardDraggable${i}`]: {
          id: `cardDraggable${i}`,
          content: datum.data,
          date: datum.Date,
          startDate: datum.startDate,
          endDate: datum.endDate,
        },
      };
      formattedDataRef.current.cardLocations.hand.contentId.push(`cardDraggable${i}`);
      i = i + 1;
    });
    ready = true;
  };
  const onDragEnd = (result) => {
    setDrag(false);
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let begin = formattedDataRef.current.cardLocations[source.droppableId];
    let end = formattedDataRef.current.cardLocations[destination.droppableId];
    if (begin === end) {
      let beginContent = Array.from(begin.contentId);
      beginContent.splice(source.index, 1);
      beginContent.splice(destination.index, 0, draggableId);
      const newBegin = {
        ...begin,
        contentId: beginContent,
      };
      const newFormattedData = {
        ...formattedDataRef.current,
        cardLocations: {
          ...formattedDataRef.current.cardLocations,
          [newBegin.id]: newBegin,
        },
      };
      setFormattedData(newFormattedData);
      return;
    }
    let beginContent = Array.from(begin.contentId);
    beginContent.splice(source.index, 1);
    const newBegin = {
      ...begin,
      contentId: beginContent,
    };
    let endContent = Array.from(end.contentId);
    endContent.splice(destination.index, 0, draggableId);
    draggedId = draggableId;
    const newEnd = {
      ...end,
      contentId: endContent,
    };
    const newFormattedData = {
      ...formattedDataRef.current,
      cardLocations: {
        ...formattedDataRef.current.cardLocations,
        [newBegin.id]: newBegin,
        [newEnd.id]: newEnd,
      },
    };
    setFormattedData(newFormattedData);
    setTimeout(() => {
      checkDate(newEnd, draggableId);
    }, 750);
  };
  const selectNewCard = () => {
    delete formattedDataRef.current.cards[draggedId];
    let tableContent = formattedDataRef.current.cardLocations.table.contentId;
    tableContent.splice(tableContent.indexOf(draggedId), 1);
    formattedDataRef.current.cardLocations.table.contentId = tableContent;
    newCardID = newCardID + 1;
    let newCardData = remainingCards[0];
    remainingCards = remainingCards.filter(
      (datum) => datum.ID !== newCardData.ID
    );
    formattedDataRef.current.cards = {
      ...formattedDataRef.current.cards,
      [`newCard${newCardID}`]: {
        id: `newCard${newCardID}`,
        content: newCardData.data,
        date: newCardData.Date,
        startDate: newCardData.startDate,
        endDate: newCardData.endDate,
      },
    };
    formattedDataRef.current.cardLocations.hand.contentId.push(`newCard${newCardID}`);
  };
  const checkDate = (newEnd, draggableId) => {
    let draggedCardDates = {};
    let leftCardDates = {};
    let rightCardDates = {};
    let draggedCardIndex = newEnd.contentId.indexOf(draggableId);
    let existingCards = {
      ...newEnd,
      contentId: newEnd.contentId.filter(
        (datum) => formattedDataRef.current.cards[datum] !== undefined
      ),
    };
    draggedCardDates = {
      start: Number(formattedDataRef.current.cards[draggableId].startDate),
      end: Number(formattedDataRef.current.cards[draggableId].endDate),
    };
    let leftCard =
      formattedDataRef.current.cards[existingCards.contentId[draggedCardIndex - 1]];
    if (leftCard !== undefined) {
      leftCardDates = {
        start: Number(leftCard.startDate),
        end: Number(leftCard.endDate),
      };
    }
    let rightCard =
      formattedDataRef.current.cards[existingCards.contentId[draggedCardIndex + 1]];
    if (rightCard !== undefined) {
      rightCardDates = {
        start: Number(rightCard.startDate),
        end: Number(rightCard.endDate),
      };
    }
    //Create Arrays for start dates on all cards to left and end dates on all cards to the right
    let startDates = [];
    let i = 0;
    for (i = 0; i < draggedCardIndex; i++) {
      startDates.push(
        Number(formattedDataRef.current.cards[existingCards.contentId[i]].startDate)
      );
    }
    let endDates = [];
    let j = 0;
    for (j = draggedCardIndex + 1; j < existingCards.contentId.length; j++) {
      endDates.push(
        Number(formattedDataRef.current.cards[existingCards.contentId[j]].endDate)
      );
    }
    // Loop through each dates array to determine correctness
    let incorrect = false;
    startDates.map((year) => {
      if (year > draggedCardDates.end) {
        incorrect = true;
      }
    });
    endDates.map((year) => {
      if (year < draggedCardDates.start) {
        incorrect = true;
      }
    });
    // By adding in the startDates and endDates arrays above some of the below logic is redundant, this should be simplified
    if (incorrect === false) {
      // The first card dropped will always be correct because there are no cards to compare it to.
      if (existingCards.contentId.length === 1) {
        correct = true;
        // Do check on cards in past here
      } else if (existingCards.contentId.length === draggedCardIndex + 1) {
        if (
          draggedCardDates.end >= leftCardDates.start ||
          draggedCardDates.end === 9999
        ) {
          correct = true;
        } else {
          correct = false;
        }
        //Do check on cards in future here
      } else if (draggedCardIndex === 0) {
        if (
          draggedCardDates.start <= rightCardDates.end ||
          draggedCardDates.start === -9999
        ) {
          correct = true;
        } else {
          correct = false;
        }
        // do both checks here
      } else {
        if (
          (draggedCardDates.end >= leftCardDates.start &&
            draggedCardDates.start <= rightCardDates.end) ||
          (draggedCardDates.start === -9999 && draggedCardDates.end === 9999)
        ) {
          correct = true;
        } else {
          correct = false;
        }
      }
    } else {
      correct = false;
    }
    if (correct) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('correct');
      dispatch(
        setStats({
          hits: reduxStatsRef.current.hits + 1,
          score: reduxStatsRef.current.score + 1,
          roundStats: {
            hits: reduxStatsRef.current.roundStats.hits + 1,
            score: reduxStatsRef.current.roundStats.score + 1,
          },
        })
      );
      if (formattedDataRef.current.cardLocations.hand.contentId.length === 0) {
        clearTimeout(setDiv);
        props.checkWorld();
      }
    } else {
      selectNewCard();
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].salish);
      setCorrectness('wrong');
      dispatch(
        setStats({
          misses: reduxStatsRef.current.misses + 1,
          score: reduxStatsRef.current.score - 1,
          roundStats: {
            misses: reduxStatsRef.current.roundStats.misses + 1,
            score: reduxStatsRef.current.roundStats.score - 1,
          },
        })
      );
    }
    setDiv = setTimeout(() => setCorrectness(null), 1000);
  };
  return (
    <DragDropContext onDragStart={() => setDrag(true)} onDragEnd={onDragEnd}>
      <div
        id="fullScreen"
        style={{ backgroundImage: background }}
        className="fullScreen HG-fullScreen overflow-y: hidden;">
        <SwitchDisplay
          chosenPopupWord={chosenPopupWord}
          correctness={correctness}
          drag={drag}
          formattedData={formattedDataRef.current}
          roundStats={reduxStatsRef.current.roundStats}
          setFormattedData={setFormattedData}
          time={props.time}
          uiWords={reduxState.uiWords}
        />
      </div>
    </DragDropContext>
  );
};

export default HistoryGame;

HistoryGame.propTypes = {
  checkWorld: PropTypes.func,
  time: PropTypes.number,
};
SwitchDisplay.propTypes = {
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  drag: PropTypes.bool,
  formattedData: PropTypes.object,
  roundStats: PropTypes.object,
  setFormattedData: PropTypes.func,
  time: PropTypes.number,
  uiWords: PropTypes.array,
};
DisplayDroppables.propTypes = {
  drag: PropTypes.bool,
  formattedData: PropTypes.object,
  setFormattedData: PropTypes.func,
};

{/* Checked PropTypes */}