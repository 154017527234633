import "./index.css";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import GameSelectButton from "../GameSelectButton";
import {fetchImage} from '../nonUIFuncs';
import { selectReduxSlice } from "../../Store/store"; 
import { useSelector } from 'react-redux';

let catButtonDOM = [];
let displaySwitch = 'home';

const FilteredData = props => {
  let locationData = [];
  let buttonNames = [];
  props.reduxState.gameData.map((datum) => {
    if (datum.location === props.display) {
      locationData.push(datum);
      if (
        !buttonNames.includes(datum.homeName) &&
        datum.homeName !== "Locations" &&
        datum.homeName !== "Past Actions" &&
        datum.homeName !== "Family"
      ) {
        buttonNames.push(datum.homeName);
      }
    }
  });
  if (displaySwitch === "stories") {
    props.reduxState.data.map((datum) => {
      if (
        datum.backgroundImg.includes("stories") &&
        !buttonNames.includes(datum.category)
      ) {
        buttonNames.push(datum.category);
      }
    });
  }
  catButtonDOM = buttonNames.map((catButton) => {
    let gameDataFiltered = props.reduxState.gameData.filter(
      (dataByCat) => dataByCat.homeName === catButton
    );
    let currentData = props.reduxState.data.filter(
      (cat) => cat.category === catButton
    );
    let languageCategory = props.reduxState.data.find(
      (datum) => datum.category === catButton
    ).categorySalish;
    if (languageCategory === "") {
      languageCategory = props.reduxState.data.find(
        (datum) => datum.category === catButton
      ).category;
    }
    let starsFound = 0;
    let allStars = 0;
    let filteredScoreData = props.scoreData.filter(
      (datum) => datum.category === catButton
    );
    filteredScoreData.map((datum) => {
      starsFound = starsFound + datum.stars;
      allStars = allStars + 5;
      let test = props.reduxState.goldStar.find(
        (game) => game.game === datum.gameType
      );
      if (test.secondType !== "none") {
        allStars = allStars + 5;
      }
    });
    if (catButton !== "Stories") {
      return (
        <div className="home-gameButtonContainer" key={catButton}>
          <div>
            <Link
              to={{
                pathname: `/KidsZone/Home/${catButton}/`,
                state: {
                  currentData: currentData,
                  gameDataFiltered: gameDataFiltered,
                },
              }}>
              <GameSelectButton
                buttonBackground={fetchImage(currentData[0].backgroundImg)}
                parent="gameButtons"
                uniqueCategoryName={languageCategory}
              />
            </Link>
          </div>
          <div className="home-starContainer">
            <img src={`${fetchImage("goldStar.png")}`} className="home-stars" />
            <div className="home-starCount">
              {" "}
              {starsFound}/{allStars}
            </div>
          </div>
        </div>
      );
    }
  });
  return catButtonDOM;
};

const FindLocations = props => {
  let locations = [];
  props.reduxState.gameData.map((datum) => {
    if (!locations.includes(datum.location)) {
      locations.push(datum.location);
    }
  });
  catButtonDOM = locations.map((catButton) => {
    let currentLocationData = props.reduxState.gameData.filter(
      (cat) => cat.location === catButton
    );
    return (
      <div
        onClick={() => props.setDisplay(catButton)}
        className="home-gameButtonContainer"
        key={catButton}>
        <GameSelectButton
          buttonBackground={fetchImage(currentLocationData[0].locationImage)}
          uniqueCategoryName={catButton}
        />
      </div>
    );
  });
  return catButtonDOM;
};

const Home = props => {
  const reduxState = useSelector(selectReduxSlice);
  let routeLocation = useLocation();
  const [display, setDisplay] = useState('location');
  useEffect(() => {
    document.title = "Kids Zone - Home"
    if (routeLocation.state !== undefined && routeLocation.state !== null) {
      displaySwitch = routeLocation.state.display;
    }
  }, []);
  return (
    <div className="home-fullscreen">
      {display === "location" ? (
        <div className="home-centerContent">
          <FindLocations
            reduxState={reduxState}
            setDisplay={setDisplay}
          />
        </div>
      ) : (
        <div className="home-locationButtonContainer">
          <div
            onClick={() => setDisplay("location")}
            className="home-backToLocations">
            Back to Locations
          </div>
          <div className="home-centerContentMarginAdjust">
            <FilteredData
              reduxState={reduxState}
              display={display}
              scoreData={props.scoreData}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;

Home.propTypes = {
  scoreData: PropTypes.array,
};

FilteredData.propTypes = {
  reduxState: PropTypes.object,
  display: PropTypes.string,
  scoreData: PropTypes.array,
};

FindLocations.propTypes = {
  reduxState: PropTypes.object,
  setDisplay: PropTypes.func,
};

{/* Checked PropTypes */}